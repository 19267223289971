import {
    Text,
    View,
    StyleSheet,
  } from "@react-pdf/renderer";
import { PicklistValues } from "../../objects/models/PicklistValues";
import { getPicklistKeys, LookupPicklistValue } from "../project/projectUtils";
import { ProjectWithReports } from "./CouncilReportPDFLink";
import StatusReport from "../../objects/models/StatusReport";
var dateFormat = require("dateformat");
  
interface CouncilStatusReportProjectProps {
    project: ProjectWithReports;
    picklists: PicklistValues[];
}

// Create styles
const styles = StyleSheet.create({
  projectHeader: {        
    fontWeight: "bold",
    fontSize: 20,        
  },
  boldThis: {
    fontFamily: "MontserratSemiBold",
  },
  bottomMargin: {
    marginBottom: 10
  },
  separator: {
    height: "100%",
    marginHorizontal: 30,
    borderLeft: "1px solid #111921",
  },
  statusPill: {
    display: "flex",
    alignContent: "center",
    margin: 3,
    paddingVertical: 5,
    paddingHorizontal: 8,
    color: "white",
    borderRadius: "50%",
    fontSize: 7,
  },
  statusRow: {
    display: "flex",
    flexDirection: "row",  
  },
  statusSectionLeft: {
    display: "flex",
    flexDirection: "row",
    width: "36%",
    justifyContent: "space-between",
  },
  statusSectionRight: {
    display: "flex",
    flexDirection: "row",
    width: "63%",
    justifyContent: "space-between",
  },
  Red: {
    backgroundColor: "#891d1d",
  },
  Yellow: {
    backgroundColor: "#fc600e",
  },
  Green: {
    backgroundColor: "#006550",
  },
  none: {
    backgroundColor: "#17A2B8",
  },
  addSpace: {
    padding: 10,
  },
});

type HealthType = "none" | "Red" | "Yellow" | "Green";
  
export const CouncilStatusReportProject = ({ project, picklists }: CouncilStatusReportProjectProps) => {
  const mostRecentStatus = project.statusReports?.reduce((mostRecent: undefined | StatusReport, current) => {
    if (mostRecent === undefined || current.endDate > mostRecent.endDate) {
        return current;
    }
    return mostRecent;
  }, undefined);

  const overall = (printPicklistValue(picklists[1], "crd5f_overallhealthstatus", mostRecentStatus?.overallHealthStatus) || "none") as HealthType;
  const budget = (printPicklistValue(picklists[1], "crd5f_budgetstatus", mostRecentStatus?.budgetStatus) || "none") as HealthType;
  const milestone = (printPicklistValue(picklists[1], "crd5f_milestonestatus", mostRecentStatus?.milestoneStatus) || "none") as HealthType;

  return (
    <View key={project.projectName} wrap={false}>
      <Text style={[styles.projectHeader, styles.boldThis, styles.bottomMargin]}>{project.projectName}</Text>
      <Text style={styles.bottomMargin}>{project.valueStatement}</Text>
      <Text style={styles.bottomMargin}>
        <View style={styles.boldThis}>Council Summary: </View>
        {mostRecentStatus?.councilSummary}
      </Text>
      <View style={[styles.statusRow, styles.boldThis]}>
        <View style={styles.statusSectionLeft}>
          <Text style={[styles.statusPill, styles[overall]]}>
            Overall
          </Text>
          <Text style={[styles.statusPill, styles[budget]]}>
            Budget
          </Text>
          <Text style={[styles.statusPill, styles[milestone]]}>
            Milestone
          </Text>
        </View>          
        <View style={styles.separator}></View>
        <View style={styles.statusSectionRight}>
          <Text style={[styles.statusPill, styles.Green]}>
            Start Date: {dateFormat(project.startDate, "yyyy-mm-dd")}
          </Text>
          <Text style={[styles.statusPill, styles.Green]}>
            End Date: {dateFormat(project.endDate, "yyyy-mm-dd")}
          </Text>
          <Text style={[styles.statusPill, styles.Green]}>
            Status: {printPicklistValue(picklists[0], "crd5f_projectstatus", project.projectStatus)}
          </Text>
        </View>
      </View>
      <View style={styles.addSpace}></View>
    </View>
  );
};

function printPicklistValue(
  picklists: PicklistValues,
  field: string,
  key?: number
) {
  if (key) {
    var keys = getPicklistKeys(picklists, field);
    var displayValue = LookupPicklistValue(keys, key);
    return displayValue;
  } else {
    return " ";
  }
}
  