import React, { useContext, useState } from "react";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";

import {
  Button,
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import {
  TextChangeCase16Regular,
  Person16Regular,
  List16Regular,
} from "@fluentui/react-icons";

import {
  VerifyPersonInDataverse,
  SaveDecision,
} from "../../../helpers/ProjectService";

import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
  validDate,
} from "../projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";

import Decision from "../../../objects/models/Decision";
import "../../../CarnaStyles.css";
import { PeoplePicker } from "@microsoft/mgt-react";
import AttachmentsView from "../Attachments";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  decision?: Decision;
  closeAction: () => void;
}

const ProjectDecision: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [decision, setDecision] = useState<Decision>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [assignedToError, setAssignedToError] = useState<string>();
  const [decisionByError, setDecisionByError] = useState<string>();
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && decision) {
      const key = ddl.id;
      const value = ddl.value.key;
      setDecision({ ...decision, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && decision) {
      const key = e.target.id;
      setDecision({ ...decision, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeRequiredBy = (d: any, e: any) => {
    if (e.value && decision) {
      const newValue = e.value as Date;
      setDecision({
        ...decision,
        requiredBy: newValue,
      });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeDecisionDate = (d: any, e: any) => {
    if (e.value && decision) {
      const newValue = e.value as Date;
      setDecision({
        ...decision,
        decisionDate: newValue,
      });
    }
  };

  //type doesn't work
  const handlePersonChange = async (e: any) => {
    //guard against "undefined" decision. The decision must be loaded.
    if (decision) {
      const target = e.target.id;
      //have we selected a new user?
      if (e.detail.length > 0) {
        //we first need to verify that the person chosen from Graph IS someone in our databse.
        //some selections are not in our systemuser table, so they're invalid.
        const newPerson = e.detail[0] as MicrosoftGraph.User;
        const dataverseId = await VerifyPersonInDataverse(newPerson.id!);
        //If they were found in our database, then they are valid. Update our decision object.
        if (dataverseId) {
          switch (target) {
            case "assignedTo":
              setDecision({
                ...decision,
                assignedToSysID: dataverseId,
                assignedToName: newPerson.displayName as string,
                assignedToDomainName: undefined,
                assignedToAzureID: newPerson.id,
              });
              setAssignedToError(undefined);
              break;
            case "decisionBy":
              setDecision({
                ...decision,
                decisionMadeBySysID: dataverseId,
                decisionMadeByName: newPerson.displayName as string,
                decisionMadeByDomainName: undefined,
                decisionMadeByAzureID: newPerson.id,
              });
              setDecisionByError(undefined);
          }
        }
        //graph user not found in the DV. Blank the systemuser field and diplay an error.
        else {
          const cannedMessage =
            " is an external user. Please select someone else.";
          const personError = newPerson.displayName + cannedMessage;
          switch (target) {
            case "assignedTo":
              setDecision({ ...decision, assignedToSysID: undefined });
              setAssignedToError(personError);
              break;
            case "decisionBy":
              setDecision({ ...decision, decisionMadeBySysID: undefined });
              setDecisionByError(personError);
              break;
          }
        }
      } else {
        //we removed a person
        switch (target) {
          case "assignedTo":
            setDecision({
              ...decision,
              assignedToSysID: undefined,
              assignedToAzureID: undefined,
              assignedToName: undefined,
            });
            setAssignedToError(undefined);
            break;
          case "decisionBy":
            setDecision({
              ...decision,
              decisionMadeBySysID: undefined,
              decisionMadeByAzureID: undefined,
              decisionMadeByName: undefined,
            });
            setDecisionByError(undefined);
        }
      }
    }
  };

  //no decision loaded yet. Get it from the props, or create a fresh one.
  if (decision === undefined) {
    //we have a decision, load it!
    if (props.decision !== undefined) {
      setDecision(props.decision);
      return <div>Loading form...</div>;
    }
    //no decision passed in. Must be a new one.
    else {
      if (newForm) {
        var newDecision = new Decision();
        newDecision.projectId = projectID;
        newDecision.name = "[New Decision]";
        newDecision.requiredBy = new Date();
        newDecision.decisionDate = new Date();
        setDecision(newDecision);
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No decision supplied, but it's not a new form? Did we forget a prop?
          </div>
        );
      }
    }
  } else {
    const saveAndClose = async () => {
      if (!validDecision(decision)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveDecision(decision, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    return (
      <div className="carnaForm" data-testid="decision-form">
        <Form>
          <h1>
            <TextArea
              id="name"
              key="name"
              value={decision.name}
              onChange={onChangeTextArea}
              className="titleField"
              autoFocus={newForm}
              onFocus={onFocusSelectAll}
              required
              fluid
              rows={1}
              maxLength={250}
              readOnly={!userRole.canEdit || isClosed}
            />
            <CharactersRemaining value={decision.name} maxLength={250} />
          </h1>
          <div className="errorText">
            {showErrors
              ? !decision.name
                ? "Name is required"
                : undefined
              : undefined}
          </div>
          <span onClick={closePopup} className="close-button topright">
            X
          </span>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Description{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="description"
                key="description"
                value={decision.description}
                onChange={onChangeTextArea}
                autoFocus={!newForm}
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={decision.description}
                maxLength={2000}
              />
              <div className="errorText">
                {showErrors
                  ? !decision.description
                    ? "Description is required"
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <Person16Regular /> Assigned To <Required theme={themeCss} />
              </div>
              <PeoplePicker
                id="assignedTo"
                key="assignedTo"
                defaultSelectedUserIds={
                  decision.assignedToAzureID
                    ? [decision.assignedToAzureID]
                    : undefined
                }
                selectionMode="single"
                selectionChanged={handlePersonChange}
                className={themeCss}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {assignedToError
                  ? assignedToError
                  : showErrors
                  ? !decision.assignedToAzureID
                    ? "You must assign this decision to someone"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Resolution Required By{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="requiredBy"
                key="requiredBy"
                selectedDate={
                  decision.requiredBy
                    ? new Date(decision.requiredBy)
                    : undefined
                }
                onDateChange={onChangeRequiredBy}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(decision.requiredBy)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Decision Made
              </div>
              <TextArea
                id="decisionMade"
                key="decisionMade"
                value={decision.decisionMade}
                onChange={onChangeTextArea}
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={decision.decisionMade}
                maxLength={2000}
              />
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <Person16Regular /> Decision Made by
              </div>
              <PeoplePicker
                id="decisionBy"
                key="decisionBy"
                defaultSelectedUserIds={
                  decision.decisionMadeByAzureID
                    ? [decision.decisionMadeByAzureID]
                    : undefined
                }
                selectionMode="single"
                selectionChanged={handlePersonChange}
                className={themeCss}
                disabled={!userRole.canEdit || isClosed}
              />
              {decisionByError ? decisionByError : undefined}
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Decision Date
              </div>
              <Datepicker
                id="decisionDate"
                key="decisionDate"
                selectedDate={
                  decision.decisionDate
                    ? new Date(decision.decisionDate)
                    : undefined
                }
                onDateChange={onChangeDecisionDate}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Status <Required theme={themeCss} />
              </div>
              <Dropdown
                id="status"
                key="status"
                items={getPicklistKeys(picklists, "crd5f_decisionstatus")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_decisionstatus",
                  decision.status
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !decision.status
                    ? "Status is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          {decision ? (
            <div>
              <AttachmentsView
                project={projectID}
                entity="decision"
                entityId={decision.decisionId}
                disabled={newForm}
              />
            </div>
          ) : undefined}
          {userRole.canEdit && !isClosed ? (
            <div style={{ float: "left" }}>
              <Button content="Save Decision" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validDecision(decision: Decision): boolean {
  var valid: boolean = false;
  if (
    [
      decision.name,
      decision.description,
      decision.assignedToSysID,
      validDate(decision.requiredBy),
      decision.status,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectDecision;
