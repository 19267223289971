import React from "react";
import * as MicrosoftTeams from "@microsoft/teams-js";
import {
  Provider,
  ThemePrepared,
  teamsTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
} from "@fluentui/react-northstar";
import { PageLayout } from "./component/navigation/PageLayout";
import { Route, Switch } from "react-router-dom";
import ProjectList from "./component/project/ProjectList";
import Dashboard from "./component/dashboard/Dashboard";
import ProjectDetails from "./component/project/ProjectDetails";

interface IAppState {
  theme: ThemePrepared;
}

const TeamsThemes = {
  contrast: "contrast",
  dark: "dark",
  default: "default",
};

class TeamsThemeHelper {
  public static getTheme(themeStr: string | undefined): ThemePrepared {
    themeStr = themeStr || "";

    switch (themeStr) {
      case TeamsThemes.dark:
        return teamsDarkTheme;
      case TeamsThemes.contrast:
        return teamsHighContrastTheme;
      case TeamsThemes.default:
      default:
        return teamsTheme;
    }
  }
}

export default class ThemeWrapper extends React.Component<{}, IAppState> {
  private mgtTheme: string = "mgt-light";

  constructor(props: any) {
    super(props);

    this.state = {
      theme: TeamsThemeHelper.getTheme("default"),
    };
  }

  public componentDidMount() {
    MicrosoftTeams.registerOnThemeChangeHandler(this.updateTheme.bind(this));
    MicrosoftTeams.getContext((context) => {
      this.updateTheme(context.theme);
    });
  }

  private updateTheme(themeString: string | undefined): void {
    switch (themeString) {
      case "dark":
        this.mgtTheme = "mgt-dark";
        break;
      case "contrast":
        this.mgtTheme = "mgt-contrast";
        break;
      default:
        this.mgtTheme = "mgt-light";
        break;
    }
    this.setState({
      theme: TeamsThemeHelper.getTheme(themeString),
    });
  }

  render() {
    return (
      <Provider theme={this.state.theme}>
          <PageLayout>
            <Switch>
              <Route path="/details/:projectID">
                <div className={"mainTab " + this.mgtTheme}>
                  <ProjectDetails />
                </div>
              </Route>
              <Route path="/dashboard">
                <div className={"dashboard " + this.mgtTheme}>
                  <Dashboard />
                </div>
              </Route>
              <Route path="/">
                <div data-testid="project-list" className={"mainTab " + this.mgtTheme}>
                  <ProjectList />
                </div>
              </Route>
            </Switch>
          </PageLayout>
      </Provider>
    );
  }
}
