import { Grid, Segment } from "@fluentui/react-northstar";
import NavBar from "./NavBar";
import LeftNav from "./LeftNav";
import "../../CarnaStyles.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProjectNavBar from "./ProjectNavBar";
import { useState } from "react";

export const PageLayout: React.FC<{}> = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const location = useLocation();

  const [leftNavOpen, setLeftNavOpen] = useState<boolean>(true);

  const handleLeftNavCollapse = () => {
    if (leftNavOpen) {
      if (location.pathname.split("/")[1] === "details") {
        setLeftNavOpen(false);
      }
    } else {
      if (location.pathname.split("/")[1] === "details") {
        setLeftNavOpen(true);
      }
    }
  };

  return (
    <>
      {!isMobile ? (
        <Segment
          content={<NavBar handleLeftNavCollapse={handleLeftNavCollapse} />}
          styles={{
            gridColumn: "span 12",
            position: "sticky",
            top: 0,
            zIndex: 500,
          }}
        />
      ) : (
        <Switch>
          <Route exact path="/dashboard">
            <Segment
              content={<NavBar handleLeftNavCollapse={handleLeftNavCollapse} />}
              styles={{
                gridColumn: "span 12",
                position: "sticky",
                top: 0,
                zIndex: 500,
              }}
            />
          </Route>
          <Route exact path="/">
            <Segment
              content={<NavBar handleLeftNavCollapse={handleLeftNavCollapse} />}
              styles={{
                gridColumn: "span 12",
                position: "sticky",
                top: 0,
                zIndex: 500,
              }}
            />
          </Route>
        </Switch>
      )}
      <Grid columns="repeat(12, 1fr)">
        <Switch>
          <Route exact path="/dashboard">
            {!isMobile ? (
              <>
                <Segment content={<LeftNav />} styles={{ width: "200px" }} />
                <Segment
                  content={props.children}
                  styles={{ gridColumn: "span 11" }}
                />
              </>
            ) : (
              <Segment
                content={props.children}
                styles={{ gridColumn: "span 12" }}
              />
            )}
          </Route>
          <Route exact path="/">
            <Segment
              content={props.children}
              styles={{ gridColumn: "span 12" }}
            />
          </Route>
          <Route>
            {!isMobile ? (
              <>
                {leftNavOpen && (
                  <Segment content={<LeftNav />} styles={{ width: "168px" }} />
                )}
                <Segment
                  content={
                    <>
                      <ProjectNavBar /> {props.children}
                    </>
                  }
                  styles={{ gridColumn: leftNavOpen ? "span 11" : "span 12" }}
                />
              </>
            ) : (
              <Segment
                content={
                  <>
                    <ProjectNavBar /> {props.children}
                  </>
                }
                styles={{ gridColumn: "span 12" }}
              />
            )}
          </Route>
        </Switch>
      </Grid>
    </>
  );
};
