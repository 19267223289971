import React from "react";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";

interface TaskStackedVBarChartProps {
  decisonCount: {
    [x: string]: number;
  };
  milestoneCount: {
    [x: string]: number;
  };
  changeRequestCount: {
    [x: string]: number;
  };
}

const TaskStackedVBarChart = (props: TaskStackedVBarChartProps) => {
  const data = [
    {
      name: "Change Request",
      Rejected: props.changeRequestCount.Rejected,
      Pending: props.changeRequestCount.Pending,
      Approved: props.changeRequestCount.Approved,
    },
    {
      name: "Project Milestone",
      Planning: props.milestoneCount.Planning,
      Delivery: props.milestoneCount.Delivery,
      Completed: props.milestoneCount.Completed,
    },
    {
      name: "Project Decisions",
      Cancelled: props.decisonCount.Cancelled,
      Pending: props.decisonCount.Pending,
      "Decision Made": props.decisonCount["Decision Made"],
    },
  ];

  const animationDuration = 1000;

  return (
    <BarChart
      width={240}
      height={140}
      data={data}
      layout="vertical"
      margin={{
        top: 20,
        right: 0,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="name" hide />
      <Tooltip 
        position={{x:50}}
        labelStyle={{ color: "#000", fontWeight: 600 }} 
      />
      <Bar
        dataKey="Pending"
        stackId="a"
        fill="#FFBB28"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Decision Made"
        stackId="a"
        fill="#00C49F"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Cancelled"
        stackId="a"
        fill="#FF8042"
        animationDuration={animationDuration}
      />

      <Bar
        dataKey="Approved"
        stackId="a"
        fill="#00C49F"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Rejected"
        stackId="a"
        fill="#FF8042"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Planning"
        stackId="a"
        fill="#FF8042"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Delivery"
        stackId="a"
        fill="#FFBB28"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Completed"
        stackId="a"
        fill="#00C49F"
        animationDuration={animationDuration}
      />
    </BarChart>
  );
};

export default TaskStackedVBarChart;
