import React from "react";

interface Props {
  theme?: string;
}

export const Required: React.FC<Props> = (props: Props) => {
    const theme = props.theme;
    var color = "lightRequired";
    if (theme === "nv")
    {
        color = "darkRequired";
    }
    return (
        <span className={color}>
            *
        </span>
    )
};
