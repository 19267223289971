import { useMemo } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Circle,
  Svg,
} from "@react-pdf/renderer";
import { getPicklistKeys, LookupPicklistValue } from "../projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import { FullProject } from "./ManagerStatusReport";

import montserratSemiBold from "../../../fonts/Montserrat-SemiBold.ttf";
import montserratLight from "../../../fonts/Montserrat-Light.ttf";
import header from "../../../resources/header.png";
import footerswirl from "../../../resources/footerswirl.jpg";
import { formatCurrency } from "../../../helpers/CurrencyFormatter";

Font.register({ family: "MontserratSemiBold", src: montserratSemiBold });
Font.register({ family: "MontserratLight", src: montserratLight });
var dateFormat = require("dateformat");

interface DocProps {
  project: FullProject;
  picklists: PicklistValues[];
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 0,
    margin: 0,
    fontFamily: "MontserratSemiBold",
  },
  pageView: {
    paddingHorizontal: 35,
    paddingTop: 105,
  },
  headerImage: {
    width: "100%",
    position: "absolute",
    paddingHorizontal: 0,
    paddingTop: 0,
    left: 0,
    top: 0,
  },
  footerswirl: {
    position: "absolute",
    bottom: 0,
    right: 0,
    paddingBottom: 0,
    paddingRight: 0,
    width: "150px",
    zIndex: -1,
  },
  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 0,
    paddingBottom: 0,
    color: "grey",
    width: "100%",
  },
  pageNumber: {
    position: "absolute",
    width: "5%",
    bottom: 30,
    left: 40,
    zIndex: 10,
  },
  address: {
    position: "absolute",
    width: "80%",
    bottom: 30,
    right: 10,
    zIndex: 10,
  },
  titleText: {
    color: "#345D9D",
  },
  reportingPeriod: {
    paddingVertical: 20,
  },
  section: {
    marginVertical: "10px",
  },
  sectionHeader: {
    marginBottom: "5px",
  },
  tableHeader: {
    backgroundColor: "#111921",
    color: "white",
    borderTop: "1px solid #BFBFBF",
    fontFamily: "MontserratSemiBold",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #BFBFBF",
    borderRight: "1px solid #BFBFBF",
    marginLeft: 8,
    marginRight: 8,
    fontFamily: "MontserratLight",
  },
  cell: {
    fontSize: "10px",
    padding: "5px",
  },
  col1: {
    width: "100%",
    borderLeft: "1px solid #BFBFBF",
  },
  col2: {
    width: "50%",
    borderLeft: "1px solid #BFBFBF",
  },
  col3: {
    width: "33%",
    borderLeft: "1px solid #BFBFBF",
  },
  col4: {
    width: "25%",
    borderLeft: "1px solid #BFBFBF",
  },
  col5: {
    width: "20%",
    borderLeft: "1px solid #BFBFBF",
  },
  boldThis: {
    fontFamily: "MontserratSemiBold",
  },
  redHealth: {
    backgroundColor: "red",
    color: "white",
  },
  yellowHealth: {
    backgroundColor: "yellow",
    color: "white",
  },
  greenHealth: {
    backgroundColor: "green",
    color: "white",
  },
  notStartedHealth: {
    backgroundColor: "#17A2B8",
    color: "white",
  },
  closedHealth: {
    backgroundColor: "black",
    color: "white",
  },
});

export const DocContent = ({ project, picklists }: DocProps) => {
  const { milestones = [] } = project;
  const { changeRequests = [] } = project;
  const { risks = [] } = project;

  const milestonesComponent = useMemo(
    () =>
      milestones.length === 0 ? (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.col1, styles.cell]}>There are no milestones.</Text>
        </View>
      ) : (
        milestones.map((x) => (
          <View style={styles.row} wrap={false}>
            <Text style={[styles.col2, styles.cell]}>{x.name}</Text>
            <Text style={[styles.col4, styles.cell]}>
              {printPicklistValue(
                picklists[1],
                "crd5f_milestonestatus",
                x.status
              )}
            </Text>
            <Text style={[styles.col4, styles.cell]}>
              {dateFormat(x.deliverableDate, "mmmm dS, yyyy")}
            </Text>
          </View>
        ))
      ),
    [milestones]
  );

  const changeRequestsComponent = useMemo(
    () =>
      changeRequests.length === 0 ? (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.col1, styles.cell]}>There are no change requests.</Text>
        </View>
      ) : (
        changeRequests.map((x) => (
          <View style={styles.row} wrap={false}>
            <Text style={[styles.col4, styles.cell]}>{x.changeRequestNum}</Text>
            <Text style={[styles.col2, styles.cell]}>
              {x.changeRequestName}
            </Text>
            <Text style={[styles.col4, styles.cell]}>
              {printPicklistValue(picklists[2], "crd5f_crstatus", x.status)}
            </Text>
          </View>
        ))
      ),
    [changeRequests]
  );

  const risksComponent = useMemo(
    () =>
      risks.length === 0 ? (
        <View style={styles.row} wrap={false}>
          <Text style={[styles.col1, styles.cell]}>There are no risks listed.</Text>
        </View>
      ) : (
        risks.map((x) => (
          <View style={styles.row} wrap={false}>
            <Text style={[styles.col2, styles.cell]}>{x.description}</Text>
            <Text style={[styles.col4, styles.cell]}>
              {printPicklistValue(picklists[3], "crd5f_impact", x.impact)}
            </Text>
            <Text style={[styles.col4, styles.cell]}>
              {printPicklistValue(
                picklists[3],
                "crd5f_probability",
                x.probability
              )}
            </Text>
          </View>
        ))
      ),
    [risks]
  );

  const formatDeltaDisplay = (
    approvedBudget: number | undefined,
    forecastAt: number | undefined
  ) => {
    if (
      approvedBudget !== null &&
      approvedBudget !== undefined &&
      forecastAt !== null &&
      forecastAt !== undefined
    ) {
      let delta = forecastAt - approvedBudget;
      let deltaCurrency = formatCurrency(Math.abs(delta));
      if (delta < 0) {
        return `(${deltaCurrency})`;
      } else {
        return deltaCurrency;
      }
    } else {
      return "";
    }
  };

  const overallHealth = printPicklistValue(
    picklists[0],
    "crd5f_overallhealthstatus",
    project.statusReport?.overallHealthStatus
  );
  const scheduleStatus = printPicklistValue(
    picklists[0],
    "crd5f_schedulestatus",
    project.statusReport?.scheduleStatus
  );
  const budgetStatus = printPicklistValue(
    picklists[0],
    "crd5f_budgetstatus",
    project.statusReport?.budgetStatus
  );
  const resourceStatus = printPicklistValue(
    picklists[0],
    "crd5f_resourcestatus",
    project.statusReport?.resourceStatus
  );
  const milestoneStatus = printPicklistValue(
    picklists[0],
    "crd5f_milestonestatus",
    project.statusReport?.milestoneStatus
  );
  const cmStatus = printPicklistValue(
    picklists[0],
    "crd5f_changemanagementstatus",
    project.statusReport?.changeMgmtStatus
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image source={header} style={styles.headerImage} />
        {/*need header image up top of page 1*/}
        <View style={styles.pageView}>
          <Text style={styles.titleText}>
            {project.projectName} Status Report
          </Text>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={[styles.reportingPeriod, { width: "50%" }]}>
              Reporting Period
            </Text>
            <Text style={[styles.reportingPeriod, { width: "50%" }]}>
              {project.statusReport?.reportingPeriod}
            </Text>
          </View>
          <View>
            <Text style={styles.sectionHeader}>Corporate Summary</Text>
            <View
              style={[styles.row, styles.cell, { border: "1px solid #BFBFBF" }]}
            >
              <Text>{project.statusReport?.corporateSummary}</Text>
            </View>
          </View>
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionHeader}>Health</Text>
            <View style={[styles.row, { border: "none" }]}>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                Overall Health:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={overallHealth?.toLowerCase()}
                  />
                </Svg>
                <Text>{overallHealth}</Text>
              </View>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                Schedule Status:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={scheduleStatus?.toLowerCase()}
                  />
                </Svg>
                <Text>{scheduleStatus}</Text>
              </View>
            </View>
            <View style={[styles.row, { border: "none" }]}>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                Budget Status:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={budgetStatus?.toLowerCase()}
                  />
                </Svg>
                <Text>{budgetStatus}</Text>
              </View>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                Resource Status:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={resourceStatus?.toLowerCase()}
                  />
                </Svg>
                <Text>{resourceStatus}</Text>
              </View>
            </View>
            <View style={[styles.row, { border: "none" }]}>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                Milestone Status:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={milestoneStatus?.toLowerCase()}
                  />
                </Svg>
                <Text>{milestoneStatus}</Text>
              </View>
              <Text
                style={[
                  styles.col4,
                  styles.cell,
                  styles.boldThis,
                  { border: "none" },
                ]}
              >
                CM Status:
              </Text>
              <View
                style={[
                  styles.col4,
                  styles.cell,
                  { display: "flex", flexDirection: "row", border: "none" },
                ]}
              >
                <Svg height={10} width={15}>
                  <Circle
                    cx={5}
                    cy={5}
                    r={5}
                    stroke="black"
                    strokeWidth={1}
                    fill={cmStatus?.toLowerCase()}
                  />
                </Svg>
                <Text>{cmStatus}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionHeader}>Financial Overview</Text>
            <View style={styles.row}>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Approved Budget
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Spent To Date
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Forecast At
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Delta
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                OPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                OPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                OPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                OPEX
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.o_ApprovedBudget)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.o_spendToDate)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.o_forecastAtCompletion)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatDeltaDisplay(
                  project.o_ApprovedBudget,
                  project.o_forecastAtCompletion
                )}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                CAPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                CAPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                CAPEX
              </Text>
              <Text style={[styles.col4, styles.cell, styles.boldThis]}>
                CAPEX
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.c_ApprovedBudget)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.c_spendToDate)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatCurrency(project.c_forecastAtCompletion)}
              </Text>
              <Text style={[styles.col4, styles.cell]}>
                {formatDeltaDisplay(
                  project.c_ApprovedBudget,
                  project.c_forecastAtCompletion
                )}
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Milestones</Text>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.col2, styles.cell, styles.tableHeader]}>
                Milestone
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Status
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Due Date
              </Text>
            </View>
            {milestonesComponent}
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Change Requests</Text>
            <View style={styles.row} wrap={false} >
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Number
              </Text>
              <Text style={[styles.col2, styles.cell, styles.tableHeader]}>
                Name
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Status
              </Text>
            </View>
            {changeRequestsComponent}
          </View>

          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionHeader}>Items for Awareness</Text>
            <View style={styles.row}>
              <Text style={[styles.col1, styles.cell, styles.tableHeader]}>
                Item
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col1, styles.cell]}>
                {project.statusReport?.itemsForAwareness
                  ? project.statusReport?.itemsForAwareness
                  : " "}
              </Text>
            </View>
          </View>

          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionHeader}>Change Readiness</Text>
            <View style={styles.row}>
              <Text style={[styles.col1, styles.cell, styles.tableHeader]}>
                Item
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={[styles.col1, styles.cell]}>
                {project.statusReport?.changeReadiness
                  ? project.statusReport?.changeReadiness
                  : " "}
              </Text>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionHeader}>Open Risks</Text>
            <View style={styles.row} wrap={false}>
              <Text style={[styles.col2, styles.cell, styles.tableHeader]}>
                Risk
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Probability
              </Text>
              <Text style={[styles.col4, styles.cell, styles.tableHeader]}>
                Impact
              </Text>
            </View>
            {risksComponent}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Image source={footerswirl} style={styles.footerswirl} />
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
          <Text style={styles.address}>
            11120 178 Street, Edmonton, AB, T5S 1P2 1 (800) 252-9392
            crna@nurses.ab.ca
          </Text>
        </View>
      </Page>
    </Document>
  );
};

function printPicklistValue(
  picklists: PicklistValues,
  field: string,
  key?: number
) {
  if (key) {
    var keys = getPicklistKeys(picklists, field);
    var displayValue = LookupPicklistValue(keys, key);
    return displayValue;
  } else {
    return " ";
  }
}
