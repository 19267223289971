export default class StatusReport {
  statusReportId!: string;
  project!: string;
  reportingPeriod!: string;
  startDate!: Date;
  endDate!: Date;
  overallHealthStatus!: number;
  scheduleStatus!: number;
  budgetStatus!: number;
  resourceStatus!: number;
  milestoneStatus!: number;
  changeMgmtStatus!: number;
  projectStandardsMet!: number;
  approvedCRs?: number;
  risksHigh?: number;
  itemsForAwareness?: string;
  changeReadiness?: string;
  corporateSummary?: string;
  councilSummary?: string;
  owner!: string;
}
 