import { useState, useEffect } from "react";
import { crd5f_overallhealthEnum as ProjectHealth } from "../../objects/entities/crd5f_project";
import Project from "../../objects/models/Project";
import * as Dataverse from "../../helpers/ProjectService";
import {
  Person,
  PersonViewType,
  PersonCardInteraction,
} from "@microsoft/mgt-react";

import { NavLink } from "react-router-dom";

import "../../CarnaStyles.css";
import { Table } from "@fluentui/react-northstar";
import { HeaderClick } from "./projectUtils";
import PrintQuarterlyReport from "../councilReport/PrintQuarterlyReport";

const ProjectList: React.FC<{}> = () => {
  const [projectList, setProjectList] = useState<Project[]>();
  const [projectListLoading, setProjectListLoading] = useState<boolean>(false);
  const [projectListLoaded, setProjectListLoaded] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);

  useEffect(() => {
    (async function loadData() {
      if (!projectListLoaded && !projectListLoading) {
        setProjectListLoading(true);
        const response = await Dataverse.GetProjectList();
        //sort the project list so that all the closed projects are at the end.
        const activeProjects = response.filter(x => x.overallHealth !== ProjectHealth.Closed);
        const closedProjects = response.filter(x => x.overallHealth === ProjectHealth.Closed);
        activeProjects.push(...closedProjects);
        setProjectList(activeProjects);
        if (projectList !== undefined) setProjectListLoaded(true);
        setProjectListLoading(false);
      }
    })();
  }, [projectListLoading, projectListLoaded, projectList]);

  const headers = {
    key: "ProjectListHeader",
    items: [
      {
        content: <h2>Project</h2>,
        key: "projectName",
        onClick: () =>
          HeaderClick(
            "projectName",
            false,
            projectList,
            setProjectList,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: <h2>Acronym</h2>,
        key: "projectAcronym",
        onClick: () =>
          HeaderClick(
            "acronym",
            false,
            projectList,
            setProjectList,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: <h2>Start Date</h2>,
        key: "projectStartDate",
        onClick: () =>
          HeaderClick(
            "startDate",
            false,
            projectList,
            setProjectList,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: <h2>Project Manager</h2>,
        key: "projectManagerName",
        onClick: () =>
          HeaderClick(
            "projectManagerName",
            false,
            projectList,
            setProjectList,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: <h2>Overall Health</h2>,
        key: "projectOverallHealth",
        onClick: () =>
          HeaderClick(
            "overallHealth",
            true,
            projectList,
            setProjectList,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  if (projectList === undefined) {
    return <div>Loading projects...</div>;
  } else {
    const rowItems = projectList.map((project, index) => {
      return {
        key: project.projectId + "-row",
        items: [
          {
            key: project.projectId + "-1",
            content: renderGridrow(project, "projectName"),
          },
          {
            key: project.projectId + "-2",
            content: renderGridrow(project, "acronym"),
          },
          {
            key: project.projectId + "-3",
            content: renderGridrow(project, "startDate"),
          },
          {
            key: project.projectId + "-4",
            content: renderGridrow(project, "projectManagerName"),
          },
          {
            key: project.projectId + "-5",
            content: renderGridrow(project, "overallHealth"),
          },
        ],
      };
    });

    //populated project list
    return (
      <div
        data-testid="project-list"
        style={{ position: "relative", zIndex: 0 }}
      >
        <Table header={headers} rows={rowItems} />
        <br />
        <PrintQuarterlyReport />
      </div>
    );
  }
};

function renderGridrow(item: Project, key: string) {
  if (item) {
    switch (key) {
      case "projectName":
        return (
          <NavLink to={"/details/" + item.projectId}>
            <div>{item.projectName}</div>
          </NavLink>
        );
      case "acronym":
        return <div>{item.acronym}</div>;
      case "startDate":
        if (item.startDate !== undefined) {
          var startDate = new Date(item.startDate);
          return <span>{startDate.toLocaleDateString()}</span>;
        } else {
          return <span></span>;
        }
      case "projectManagerName":
        return (
          <>
            <Person
              userId={item.projectManagerAzureId}
              view={PersonViewType.twolines}
              fetchImage
              personCardInteraction={PersonCardInteraction.click}
              line2Property="jobTitle"
              fallbackDetails={{ displayName: item.projectManagerName }} //in case graph doesn't load
            />
          </>
        );
      case "overallHealth":
        let healthStyle: string;
        let health: string;
        if (item.overallHealth !== undefined) {
          switch (ProjectHealth[item.overallHealth]) {
            case "Red":
              healthStyle = "redHealth";
              health = "Red";
              break;
            case "Yellow":
              healthStyle = "yellowHealth";
              health = "Yellow";
              break;
            case "Green":
              healthStyle = "greenHealth";
              health = "Green";
              break;
            case "NotStarted":
              healthStyle = "notStartedHealth";
              health = "Not Started";
              break;
            case "Closed":
              healthStyle = "closedHealth";
              health = "Closed";
              break;
            default:
              healthStyle = "notStartedHealth";
              health = "Not Started";
              break;
          }
          return <span className={healthStyle}>{health}</span>;
        } else {
          healthStyle = "noHealth";
          health = "N/A";
          return <span className={healthStyle}>{health}</span>;
        }
    }
  }
}

export default ProjectList;
