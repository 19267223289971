import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

export default class ProjectNote {

    noteId!: string;
    projectId!: string;
    createdDate!: Date;
    createdByName?: string;
    createdByDomainName?: string;
    createdByAzureID?: string;
    noteName!: string;
    description?: string;

    createdBy?: MicrosoftGraph.User;
}