import { useState, useEffect, useContext } from "react";

import {
  Person,
  PersonCardInteraction,
  PersonViewType,
} from "@microsoft/mgt-react";

import Risk from "../../../objects/models/Risk";
import {
  GetRisks,
  DeleteRisk,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";

import "../../../CarnaStyles.css";

import ProjectRisk from "./projectRiskSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

const RiskList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [risks, setRisks] = useState<Risk[]>();
  const [risksLoaded, setRisksLoaded] = useState<boolean>(false);
  const [risksLoading, setRisksLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedRisks, setSelectedRisks] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeRisk, setActiveRisk] = useState<Risk>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!risksLoaded && !risksLoading) {
        setRisksLoading(true);
        const response = await GetRisks(myProject);
        setRisks(response);
        if (risks !== undefined) setRisksLoaded(true);
        setRisksLoading(false);
      }
    })();
  }, [risks, risksLoaded, risksLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues("crd5f_projectrisk");
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "riskHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      },
      {
        content: (
          <h3>
            Name
            {sortedColumn === "name" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "riskName",
        onClick: () =>
          HeaderClick(
            "name",
            false,
            risks,
            setRisks,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Impact
            {sortedColumn === "impact" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "impact",
        onClick: () =>
          HeaderClick(
            "impact",
            true,
            risks,
            setRisks,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Probability
            {sortedColumn === "probability" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "probability",
        onClick: () =>
          HeaderClick(
            "probability",
            true,
            risks,
            setRisks,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Assigned To
            {sortedColumn === "assignedToName" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "assignedToName",
        onClick: () =>
          HeaderClick(
            "assignedToName",
            false,
            risks,
            setRisks,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Status
            {sortedColumn === "status" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "status",
        onClick: () =>
          HeaderClick(
            "status",
            false,
            risks,
            setRisks,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  const renderGridrow = (item: Risk, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "name":
          return <div>{item.name}</div>;
        case "impact":
          if (item.impact) {
            var keys = getPicklistKeys(picklists, "crd5f_impact");
            var displayValue = LookupPicklistValue(keys, item.impact);
            var style = "noHealth";
            if (displayValue) {
              switch (displayValue) {
                case "Low":
                  style = "greenHealth";
                  break;
                case "Medium":
                  style = "yellowHealth";
                  break;
                case "High":
                  style = "redHealth";
                  break;
              }
            }
            return <div className={style}>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "probability":
          if (item.probability) {
            var keys = getPicklistKeys(picklists, "crd5f_probability");
            var displayValue = LookupPicklistValue(keys, item.probability);
            var style = "noHealth";
            if (displayValue) {
              switch (displayValue) {
                case "Low":
                  style = "greenHealth";
                  break;
                case "Medium":
                  style = "yellowHealth";
                  break;
                case "High":
                  style = "redHealth";
                  break;
              }
            }
            return <div className={style}>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "assignedToName":
          if (item.assignedToName) {
            return (
              <Person
                userId={item.assignedToAzureID}
                view={PersonViewType.oneline}
                fetchImage
                personCardInteraction={PersonCardInteraction.none}
                fallbackDetails={{ displayName: item.assignedToName }} //in case graph doesn't load
              />
            );
          } else {
            return <div>N/A</div>;
          }
          case "status":
            if (item.status) {
              var keys = getPicklistKeys(picklists, "crd5f_riskstatus");
              var displayValue = LookupPicklistValue(keys, item.status);             
              return <div>{displayValue}</div>;
            } else {
              return <div>N/A</div>;
            }
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedRisks];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedRisks(selected);
  }

  function refreshRisks(): void {
    setSelectedRisks([]);
    setRisksLoaded(false);
  }

  const onItemInvoked = (item: Risk) => {
    setNewForm(false);
    setActiveRisk(item);
    setIsModalOpen(true);
  };
  const newRisk = () => {
    setNewForm(true);
    setActiveRisk(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshRisks();
  };

  const deleteAction = async () => {
    for (const risk of selectedRisks) {
      await DeleteRisk(risk);
    }
    setDeleteDialogOpen(false);
    refreshRisks();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedRisks.length > 0) {
      const risk = risks?.find(({ riskId }) => riskId === selectedRisks[0]);
      onItemInvoked(risk!);
    }
  };

  if (risks === undefined || picklists === undefined) {
    return <div>Loading risks...</div>;
  } else {
    const rowItems = risks.map((risk) => {
      return {
        key: risk.riskId + "-row",
        items: [
          {
            key: risk.riskId + "-0",
            content: selectedRisks.includes(risk.riskId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(risk.riskId),
            className: "rowSelectColumn",
          },
          {
            key: risk.riskId + "-1",
            content: renderGridrow(risk, "name"),
            onClick: () => toggleSelected(risk.riskId),
          },
          {
            key: risk.riskId + "-2",
            content: renderGridrow(risk, "impact"),
            onClick: () => toggleSelected(risk.riskId),
          },
          {
            key: risk.riskId + "-3",
            content: renderGridrow(risk, "probability"),
            onClick: () => toggleSelected(risk.riskId),
          },
          {
            key: risk.riskId + "-4",
            content: renderGridrow(risk, "assignedToName"),
            onClick: () => toggleSelected(risk.riskId),
          },
          {
            key: risk.riskId + "-5",
            content: renderGridrow(risk, "status"),
            onClick: () => toggleSelected(risk.riskId),
          },
        ],
      };
    });

    const display: boolean = risks.length === 0 ? false : true;
    return (
      <>
        {/* Top buttons */}
        {selectedRisks.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Risk" onClick={newRisk} />
            </div>
          ) : undefined
        ) : selectedRisks.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Risk" onClick={editButtonClick} />
              <Button
                content="Delete Risk"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <Button content="View Risk" onClick={editButtonClick} />
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Risks"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no risks" screen, or the risk list */}
        {risks.length === 0 ? (
          <div>There are no risks.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active risk */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectRisk
              newForm={newForm}
              project={myProject}
              projectClosed={projectClosed}
              risk={activeRisk}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected risk(s) */}
        <Dialog
          header={
            selectedRisks.length > 1
              ? "Delete these risks?"
              : "Delete this risk?"
          }
          content="The selected risks will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default RiskList;
