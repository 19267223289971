import { ArrowCircleDown16Regular } from "@fluentui/react-icons";

export function ProjectDetailsNav() {
  return (
    <div data-testid="project-details-nav">
      <a href="#top">
        <div>
          <h2>Project Details</h2>
        </div>
      </a>
      <br />
      <a href="#finances">
        <div>
          <ArrowCircleDown16Regular /> Finances
        </div>
      </a>
      <br />
      <a href="#invoices">
        <div>
          <ArrowCircleDown16Regular /> Invoices
        </div>
      </a>
      <br />
      <a href="#benefits">
        <div>
          <ArrowCircleDown16Regular /> Benefits
        </div>
      </a>
      <br />
      <a href="#milestones">
        <div>
          <ArrowCircleDown16Regular /> Milestones
        </div>
      </a>
      <br />
      <a href="#decisions">
        <div>
          <ArrowCircleDown16Regular /> Decisions
        </div>
      </a>
      <br />
      <a href="#changerequests">
        <div>
          <ArrowCircleDown16Regular /> Change Requests
        </div>
      </a>
      <br />
      <a href="#statusreports">
        <div>
          <ArrowCircleDown16Regular /> Status Reports
        </div>
      </a>
      <br />
      <a href="#risks">
        <div>
          <ArrowCircleDown16Regular /> Risks
        </div>
      </a>
    </div>
  );
}
