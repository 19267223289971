import React, { useCallback, useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Project from "../../objects/models/Project";
import StatusReport from "../../objects/models/StatusReport";
import {
  GetActiveProjectPrograms,
  GetActiveProjects,
  getPicklistValues,
  GetStatusReportsInRange,
} from "../../helpers/ProjectService";
import { PicklistValues } from "../../objects/models/PicklistValues";
import { CouncilStatusReportContent } from "./CouncilStatusReportContent";
import ProjectProgram from "../../objects/models/ProjectProgram";
import CouncilPieChart from "./charts/CouncilPieChart";
import PrintQuarterlyReport, { IQuarter } from "./PrintQuarterlyReport";
var dateFormat = require("dateformat");

export interface ICouncilReportPDFLinkProps {
  quarter: IQuarter;
}

export interface ProjectWithReports extends Project {
  statusReports?: StatusReport[];
}

export interface ProgramWithProjects extends ProjectProgram {
  Projects: ProjectWithReports[];
}

export interface IChart {
  programId: string;
  chart: string;
}

const projectStatusDelivery: number = 922660001;
const projectStatusNotStarted: number = 922660004;
const projectStatusOnHold: number = 922660003;
const projectStatusPlanning: number = 922660000;
const projectStatusClosed: number = 922660002;

function getSortOrderByStatus(status?: number) {
  switch (status) {
    case projectStatusDelivery:
      return 0;
    case projectStatusOnHold:
      return 1;
    case projectStatusNotStarted:
      return 2;
    case projectStatusPlanning:
      return 3;
    case projectStatusClosed:
      return 4;
    default:
      return 99;
  }
}

function sortProgramsForReport(first: Project, second: Project) {
  const firstStatus = getSortOrderByStatus(first.projectStatus);
  const secondStatus = getSortOrderByStatus(second.projectStatus);
  if (firstStatus < secondStatus) {
    return -1;
  }
  if (firstStatus > secondStatus) {
    return 1;
  }

  // Program Status sort order is the same, so let's sort by name.
  if (first.projectName < second.projectName) {
    return -1;
  }
  if (first.projectName > second.projectName) {
    return 1;
  }
  return 0;
}

async function getActivePrograms(
  startDate: Date,
  endDate: Date,
  quarter: IQuarter
) {
  const programsPromise = GetActiveProjectPrograms(startDate, endDate);
  const projectsPromise = GetActiveProjects(startDate, endDate);
  const statusReportPromise = GetStatusReportsInRange(startDate, endDate);
  const [programs, projects, statuses] = await Promise.all([
    programsPromise,
    projectsPromise,
    statusReportPromise,
  ]);

  // Adjust statuses...if a Project is closed, and the end date is after the end of the quarter, continue to report it as Delivered
  for (let i = 0; i < projects.length; i++) {
    const project = projects[i];
    if (
      project.projectStatus === projectStatusClosed &&
      project.endDate > quarter.quarterEndDate
    ) {
      project.projectStatus = projectStatusDelivery;
    }
  }

  // Sort projects based on custom sort order for the report.
  projects.sort(sortProgramsForReport);

  for (let i = 0; i < projects.length; i++) {
    (projects[i] as ProjectWithReports).statusReports = statuses.filter(
      (x) => x.project === projects[i].projectId
    );
  }

  for (let i = 0; i < programs.length; i++) {
    (programs[i] as ProgramWithProjects).Projects = projects.filter(
      (x) => x.program === programs[i].programId
    );
  }

  return programs as ProgramWithProjects[];
}

export const CouncilReportPDFLink: React.FC<ICouncilReportPDFLinkProps> = ({
  quarter,
}) => {
  const [programsData, setProgramsData] = useState<ProgramWithProjects[]>();
  const [chartsNeededCount, setChartsNeededCount] = useState<number>();
  const [picklistValues, setPicklistValues] = useState<PicklistValues[]>();
  const [investmentCharts, setInvestmentCharts] = useState<IChart[]>([]);
  const [strategicCharts, setStrategicCharts] = useState<IChart[]>([]);

  useEffect(() => {
    (async function loadData() {
      const response = await getActivePrograms(
        quarter.fiscalYearStartDate,
        quarter.fiscalYearEndDate,
        quarter
      );
      const chartsNeeded = response.reduce((accum, program) => {
        if (program.Projects !== undefined && program.Projects.length > 0) {
          return accum + 1;
        }
        return accum;
      }, 0);
      setChartsNeededCount(chartsNeeded);
      setProgramsData(response);
    })();
  }, [quarter.quarterEndDate, quarter.quarterStartDate]);

  //load the picklist values
  useEffect(() => {
    (async function () {
      const [projectPicklists, statusPicklists] = await Promise.all([
        getPicklistValues("crd5f_project"),
        getPicklistValues("crd5f_projectstatusreport"),
      ]);
      const allPicklistValues = [projectPicklists, statusPicklists];
      setPicklistValues(allPicklistValues);
    })();
  }, []);

  const investmentChartCallback = useCallback(
    (programId: string, chart: string) => {
      setInvestmentCharts((currentCharts) => [
        ...currentCharts,
        { programId, chart },
      ]);
    },
    []
  );

  const strategicChartCallback = useCallback(
    (programId: string, chart: string) => {
      setStrategicCharts((currentCharts) => [
        ...currentCharts,
        { programId, chart },
      ]);
    },
    []
  );

  if (programsData === undefined || picklistValues === undefined) {
    return <div>Loading document...</div>;
  }

  if (
    investmentCharts.length !== chartsNeededCount ||
    strategicCharts.length !== chartsNeededCount
  ) {
    return (
      <div>
        Loading document...
        {/*react-pdf does not support direct svg, instead it has its own svg components*/}
        {/*to avoid pulling in a separate charting library we render the charts off screen and then retrieve a png data uri using recharts-to-png*/}
        {programsData === undefined
          ? undefined
          : programsData.map((program) => {
              return (
                <div key={program.name}>
                  <div style={{ position: "absolute", left: "5000%" }}>
                    <CouncilPieChart
                      chartCallback={investmentChartCallback}
                      program={program}
                      picklists={picklistValues[0]}
                      field="crd5f_investmenttype"
                    />
                  </div>
                  <div style={{ position: "absolute", left: "5000%" }}>
                    <CouncilPieChart
                      chartCallback={strategicChartCallback}
                      program={program}
                      picklists={picklistValues[0]}
                      field="crd5f_strategicalignment"
                    />
                  </div>
                </div>
              );
            })}
      </div>
    );
  }

  const reportDate = dateFormat(new Date(), "yyyy-mm-dd");
  const fileName = `${quarter.header.replace(
    "/",
    "-"
  )} Council Report - ${reportDate}`;

  return (
    <div>
      <PDFDownloadLink
        document={
          <CouncilStatusReportContent
            programs={programsData}
            picklists={picklistValues}
            investmentCharts={investmentCharts}
            strategicCharts={strategicCharts}
            quarter={quarter}
          />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
};
