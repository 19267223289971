import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import { Providers } from "@microsoft/mgt-element";
import {
  HttpMethod,
  TeamsMsal2Provider,
} from "@microsoft/mgt-teams-msal2-provider";
import * as MicrosoftTeams from "@microsoft/teams-js";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { msalConfiguration } from "./component/project/projectUtils";

const clientId = process.env.REACT_APP_CLIENT_ID!;

MicrosoftTeams.initialize();
TeamsMsal2Provider.microsoftTeamsLib = MicrosoftTeams;

if (window.parent !== window.self) {
  Providers.globalProvider = new TeamsMsal2Provider({
    clientId: clientId,
    authPopupUrl: "/tabauth",
    scopes: ["openid", "email", "User.Read", "People.Read", "offline_access"],
    ssoUrl: `${process.env.REACT_APP_SINGLE_SIGN_ON_URL}/api/SingleSignOn`,
    httpMethod: HttpMethod.POST,
  });
} else {
  Providers.globalProvider = new Msal2Provider({
    clientId: clientId,
    scopes: ["User.Read", "People.Read"],
    options: msalConfiguration,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
