import React from "react";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";

interface CardbuilderProps {
  Planned: number | undefined;
  Actual: number | undefined;
  Forecast: number | undefined;
}

const BudgetHBarChart = (props: CardbuilderProps) => {
  const data = [
    {
      name: "Budget",
      Planned: props.Planned,
      Actual: props.Actual,
      Forecast: props.Forecast,
    },
  ];

  const getStatus = (current: number | undefined) => {
    if (props.Planned && current) {
      if (current <= props.Planned) {
        return "#00C49F";
      } else {
        const increase = ((current - props.Planned) / props.Planned) * 100;
        if (increase <= 10) {
          return "#FFBB28";
        } else {
          return "#FF8042";
        }
      }
    }
    return "#00C49F";
  };

  const animationDuration = 1000;

  return (
    <BarChart
      width={260}
      height={200}
      data={data}
      margin={{
        right: 20,
        left: 20,
        bottom: 20,
        top: 0,
      }}
    >
      <Tooltip
        position={{x:50}}
        labelStyle={{ color: "#000", fontWeight: 600 }}
        formatter={(value: any) => "$" + value}
      />
      <Legend />
      <XAxis dataKey="name" hide />
      <YAxis hide />
      <Bar
        dataKey="Planned"
        fill="#428cff"
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Actual"
        fill={getStatus(props.Actual)}
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Forecast"
        fill={getStatus(props.Forecast)}
        animationDuration={animationDuration}
      />
    </BarChart>
  );
};

export default BudgetHBarChart;
