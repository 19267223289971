import {
  ContentViewGalleryIcon,
  MenuIcon,
  ToDoListIcon,
} from "@fluentui/react-icons-northstar";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation } from "react-router-dom";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import "./NavBar.css";

interface NavBarProps {
  handleLeftNavCollapse(): void;
}

const NavBar = (props: NavBarProps) => {
  const location = useLocation();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const checkPathname = (pathname: string) => {
    if (location.pathname === pathname) {
      return true;
    }
    return false;
  };

  const [theme, setTheme] = useState<string | undefined>("");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("-dark");
      } else {
        setTheme("");
      }
    }
  });

  return (
    <>
      {isMobile ? (
        <div className="mobile-nav-bar-wrapper" data-testid="mobile-nav">
          <NavLink
            to={"/"}
            className={`mobile-nav-bar-link mobile${theme} ${
              checkPathname("/") ? `mobile-current${theme}` : ""
            }`}
          >
            Projects
          </NavLink>
          <NavLink
            to={"/dashboard"}
            className={`mobile-nav-bar-link mobile${theme} ${
              checkPathname("/dashboard") ? `mobile-current${theme}` : ""
            }`}
          >
            Dashboard
          </NavLink>
        </div>
      ) : (
        <div className="nav-bar-wrapper" data-testid="non-mobile-nav">
          <MenuIcon onClick={() => props.handleLeftNavCollapse()} />
          <span className="nav-btn-wrapper">
            <NavLink
              to={"/"}
              className={`nav-bar-link ${
                checkPathname("/") ? `current${theme}` : ""
              }`}
            >
              <ToDoListIcon size="large" className="nav-bar-icons" /> Project
              List
            </NavLink>
            <NavLink
              to={"/dashboard"}
              className={`nav-bar-link ${
                checkPathname("/dashboard") ? `current${theme}` : ""
              }`}
            >
              <ContentViewGalleryIcon size="large" className="nav-bar-icons" />{" "}
              Dashboard
            </NavLink>
          </span>
        </div>
      )}
    </>
  );
};

export default NavBar;
