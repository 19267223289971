import { getMyGroupsFromGraph } from "./MsGraphApiCall";

interface memberOfResponse {
  id: string;
}

export async function getMyGroups(): Promise<string[]>
{
    const reply = await getMyGroupsFromGraph();
    const result = reply.value as memberOfResponse[];
    var returnArray:string[] = [];

    result.forEach((item) => {
        returnArray.push(item.id);
      });
    return returnArray;
}