import { useState, useEffect, useContext } from "react";

import Benefit from "../../../objects/models/Benefit";
import {
  GetBenefits,
  DeleteBenefit,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";

import "../../../CarnaStyles.css";
import ProjectBenefit from "./projectBenefitSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

var dateFormat = require("dateformat");

const BenefitList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [benefits, setBenefits] = useState<Benefit[]>();
  const [benefitsLoaded, setBenefitsLoaded] = useState<boolean>(false);
  const [benefitsLoading, setBenefitsLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedBenefits, setSelectedBenefits] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeBenefit, setActiveBenefit] = useState<Benefit>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!benefitsLoaded && !benefitsLoading) {
        setBenefitsLoading(true);
        const response = await GetBenefits(myProject);
        setBenefits(response);
        if (benefits) setBenefitsLoaded(true);
        setBenefitsLoading(false);
      }
    })();
  }, [benefits, benefitsLoaded, benefitsLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues("crd5f_projectbenefit");
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "benefitHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      }, //select column
      {
        content: (
          <div>
            <h3>
              Benefit
              {sortedColumn === "name" ? (
                sortedDescending ? (
                  <ArrowSortDown16Regular />
                ) : (
                  <ArrowSortUp16Regular />
                )
              ) : undefined}
            </h3>
          </div>
        ),
        key: "benefitName",
        onClick: () =>
          HeaderClick(
            "name",
            false,
            benefits,
            setBenefits,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Initiation Date
            {sortedColumn === "initiationDate" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "benefitInitiationDate",
        onClick: () =>
          HeaderClick(
            "initiationDate",
            false,
            benefits,
            setBenefits,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Review Cycle
            {sortedColumn === "reviewCycle" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "benefitReviewCycle",
        onClick: () =>
          HeaderClick(
            "reviewCycle",
            false,
            benefits,
            setBenefits,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Performance Measure
            {sortedColumn === "performanceMeasure" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "benefitPerformanceMeasure",
        onClick: () =>
          HeaderClick(
            "performanceMeasure",
            false,
            benefits,
            setBenefits,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  const renderGridrow = (item: Benefit, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "name":
          return <div>{item.name}</div>;
        case "initiationDate":
          var dateString = dateFormat(item.initiationDate, "yyyy-mm-dd");
          return <div>{dateString}</div>;
        case "reviewCycle":
          if (item.reviewCycle) {
            var keys = getPicklistKeys(picklists, "crd5f_reviewcycle");
            var displayValue = LookupPicklistValue(keys, item.reviewCycle);
            return <div>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "performanceMeasure":
          return <div>{item.performanceMeasure}</div>;
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedBenefits];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedBenefits(selected);
  }

  function refreshBenefits(): void {
    setSelectedBenefits([]);
    setBenefitsLoaded(false);
  }

  const onItemInvoked = (item: Benefit) => {
    setNewForm(false);
    setActiveBenefit(item);
    setIsModalOpen(true);
  };
  const newBenefit = () => {
    setNewForm(true);
    setActiveBenefit(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshBenefits();
  };
  const deleteAction = async () => {
    for (const benefit of selectedBenefits) {
      await DeleteBenefit(benefit);
    }
    setDeleteDialogOpen(false);
    refreshBenefits();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedBenefits.length > 0) {
      const benefit = benefits?.find(
        ({ benefitId }) => benefitId === selectedBenefits[0]
      );
      onItemInvoked(benefit!);
    }
  };

  if (benefits === undefined || picklists === undefined) {
    return <div>Loading benefits...</div>;
  } else {
    const rowItems = benefits.map((benefit) => {
      return {
        key: benefit.benefitId + "-row",
        items: [
          {
            key: benefit.benefitId + "-0",
            content: selectedBenefits.includes(benefit.benefitId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(benefit.benefitId),
            className: "rowSelectColumn",
          },
          {
            key: benefit.benefitId + "-1",
            content: renderGridrow(benefit, "name"),
            onClick: () => toggleSelected(benefit.benefitId),
          },
          {
            key: benefit.benefitId + "-2",
            content: renderGridrow(benefit, "initiationDate"),
            onClick: () => toggleSelected(benefit.benefitId),
          },
          {
            key: benefit.benefitId + "-3",
            content: renderGridrow(benefit, "reviewCycle"),
            onClick: () => toggleSelected(benefit.benefitId),
          },
          {
            key: benefit.benefitId + "-4",
            content: renderGridrow(benefit, "performanceMeasure"),
            onClick: () => toggleSelected(benefit.benefitId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedBenefits.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Benefit" onClick={newBenefit} />
            </div>
          ) : undefined
        ) : selectedBenefits.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Benefit" onClick={editButtonClick} />
              <Button
                content="Delete Benefit"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <div>
              <Button content="View Benefit" onClick={editButtonClick} />
            </div>
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Benefits"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no items" screen, or the Benefit list */}
        {benefits.length === 0 ? (
          <div>There are no benefits.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active benefit */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectBenefit
              project={myProject}
              projectClosed={projectClosed}
              picklists={picklists}
              newForm={newForm}
              benefit={activeBenefit}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected benefit(s) */}
        <Dialog
          header={
            selectedBenefits.length > 1
              ? "Delete these benefits?"
              : "Delete this benefit?"
          }
          content="The selected benefits will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default BenefitList;
