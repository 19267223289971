export default class UserRole {
    canView:boolean;
    canEdit:boolean;
    portfolioOwner:boolean;
    dashboardDefault:boolean;

    constructor() {
        this.portfolioOwner = false;
        this.canEdit = false;
        this.canView = false;
        this.dashboardDefault = false;
    }
}