import {
  DirectionalHint,
  ITooltipHostStyles,
  ITooltipProps,
  TooltipDelay,
  TooltipHost,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { InfoIcon } from "@fluentui/react-icons-northstar";
import "./ProjectDashboardNav.css";
export default function ProjectDashboardNav() {
  const tooltipId = useId("tooltip");
  const hostStyles: Partial<ITooltipHostStyles> = {
    root: { display: "inline-block" },
  };
  const tooltipProps: ITooltipProps = {
    onRenderContent: () => (
      <div style={{ padding: "0 12px" }}>
        <p>
          <span className="status-green"></span>Good
        </p>
        <p>
          <span className="status-orange"></span>Some Risk
        </p>
        <p>
          <span className="status-red"></span>High Risk
        </p>
        <p>
          <span className="status-blue"></span>Not Started
        </p>
        <p>
          <span className="status-black"></span>Closed
        </p>
      </div>
    ),
  };
  return (
    <div className="nav-wrapper" data-testid="project-dashboard-nav">
      <div>
        <h2>
          <span className="title">Project Name </span>
          <TooltipHost
            tooltipProps={tooltipProps}
            delay={TooltipDelay.zero}
            id={tooltipId}
            directionalHint={DirectionalHint.bottomCenter}
            styles={hostStyles}
          >
            <InfoIcon size="large" style={{ marginBottom: 2 }} />
          </TooltipHost>
        </h2>
      </div>
      <div className="summary-wrapper">
        <h2>
          <span className="section-black-indicator"></span> Summary
        </h2>
      </div>
      <div className="budget-wrapper">
        <h2>
          <span className="section-blue-indicator"></span> Budget
        </h2>
      </div>
      <div className="risk-wrapper">
        <h2>
          <span className="section-black-indicator"></span> Risks
        </h2>
      </div>
      <div className="task-wrapper">
        <h2>
          <span className="section-blue-indicator"></span> Decisions and
          Requests
        </h2>
        <p>Change Requests</p>
        <p>Project Milestones</p>
        <p>Project Decisions</p>
      </div>
      <div className="timeline-wrapper">
        <h2>
          <span className="section-black-indicator"></span> Timeline
        </h2>
        <p>Estimated</p>
        <p>Progress</p>
      </div>
    </div>
  );
}
