import { useState, useEffect, useContext } from "react";

import {
  Person,
  PersonCardInteraction,
  PersonViewType,
} from "@microsoft/mgt-react";

import ChangeRequest from "../../../objects/models/ChangeRequest";

import {
  GetChangeRequests,
  DeleteChangeRequest,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";

import "../../../CarnaStyles.css";
import ProjectChangeRequest from "./projectChangeRequestSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

var dateFormat = require("dateformat");

const ChangeRequestList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [changeRequests, setChangeRequests] = useState<ChangeRequest[]>();
  const [changesLoaded, setChangesLoaded] = useState<boolean>(false);
  const [changesLoading, setChangesLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedChanges, setSelectedChanges] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeChangeRequest, setActiveChangeRequest] =
    useState<ChangeRequest>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!changesLoaded && !changesLoading) {
        setChangesLoading(true);
        const response = await GetChangeRequests(myProject);
        setChangeRequests(response);
        if (changeRequests) setChangesLoaded(true);
        setChangesLoading(false);
      }
    })();
  }, [changeRequests, changesLoaded, changesLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues(
          "crd5f_projectchangerequest"
        );
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "changeRequestHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      },
      {
        content: (
          <h3>
            Name
            {sortedColumn === "changeRequestName" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "changeRequestName",
        onClick: () =>
          HeaderClick(
            "changeRequestName",
            false,
            changeRequests,
            setChangeRequests,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            CR Type
            {sortedColumn === "changeRequestType" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "changeRequestType",
        onClick: () =>
          HeaderClick(
            "changeRequestType",
            false,
            changeRequests,
            setChangeRequests,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Approver
            {sortedColumn === "approverName" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "changeRequestApproverName",
        onClick: () =>
          HeaderClick(
            "approverName",
            false,
            changeRequests,
            setChangeRequests,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Required By
            {sortedColumn === "requiredBy" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "changeRequestRequiredBy",
        onClick: () =>
          HeaderClick(
            "requiredBy",
            false,
            changeRequests,
            setChangeRequests,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Status
            {sortedColumn === "status" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "changeRequestStatus",
        onClick: () =>
          HeaderClick(
            "status",
            true,
            changeRequests,
            setChangeRequests,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  const renderGridrow = (item: ChangeRequest, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "changeRequestName":
          return <div>{item.changeRequestName}</div>;
        case "changeRequestType":
          if (item.changeRequestType) {
            var keys = getPicklistKeys(picklists, "crd5f_crtype");
            var displayValue = LookupPicklistValue(
              keys,
              item.changeRequestType
            );
            return <div>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "approverName":
          if (item.approverName) {
            return (
              <Person
                userId={item.approverAzureID}
                view={PersonViewType.oneline}
                fetchImage
                personCardInteraction={PersonCardInteraction.none}
                fallbackDetails={{ displayName: item.approverName }}
              />
            );
          } else {
            return <div>N/A</div>;
          }
        case "requiredBy":
          if (item.requiredBy) {
            var dateString = dateFormat(item.requiredBy, "yyyy-mm-dd");
            return <div>{dateString}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "status":
          if (item.status) {
            var keys = getPicklistKeys(picklists, "crd5f_crstatus");
            var displayValue = LookupPicklistValue(keys, item.status);
            return <div>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedChanges];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedChanges(selected);
  }

  function refreshChangeRequests(): void {
    setSelectedChanges([]);
    setChangesLoaded(false);
  }

  const onItemInvoked = (item: ChangeRequest) => {
    setNewForm(false);
    setActiveChangeRequest(item);
    setIsModalOpen(true);
  };

  const newChangeRequest = () => {
    setNewForm(true);
    setActiveChangeRequest(undefined);
    setIsModalOpen(true);
  };

  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshChangeRequests();
  };

  const deleteAction = async () => {
    for (const change of selectedChanges) {
      await DeleteChangeRequest(change);
    }
    setDeleteDialogOpen(false);
    refreshChangeRequests();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedChanges.length > 0) {
      const changeRequest = changeRequests?.find(
        ({ changeRequestId }) => changeRequestId === selectedChanges[0]
      );
      onItemInvoked(changeRequest!);
    }
  };

  if (changeRequests === undefined || picklists === undefined) {
    return <div>Loading change requests...</div>;
  } else {
    const rowItems = changeRequests.map((request) => {
      return {
        key: request.changeRequestId + "-row",
        items: [
          {
            key: request.changeRequestId + "-0",
            content: selectedChanges.includes(request.changeRequestId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(request.changeRequestId),
            className: "rowSelectColumn",
          },
          {
            key: request.changeRequestId + "-1",
            content: renderGridrow(request, "changeRequestName"),
            onClick: () => toggleSelected(request.changeRequestId),
          },
          {
            key: request.changeRequestId + "-2",
            content: renderGridrow(request, "changeRequestType"),
            onClick: () => toggleSelected(request.changeRequestId),
          },
          {
            key: request.changeRequestId + "-3",
            content: renderGridrow(request, "approverName"),
            onClick: () => toggleSelected(request.changeRequestId),
          },
          {
            key: request.changeRequestId + "-4",
            content: renderGridrow(request, "requiredBy"),
            onClick: () => toggleSelected(request.changeRequestId),
          },
          {
            key: request.changeRequestId + "-5",
            content: renderGridrow(request, "status"),
            onClick: () => toggleSelected(request.changeRequestId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedChanges.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Change Request" onClick={newChangeRequest} />
            </div>
          ) : undefined
        ) : selectedChanges.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Change Request" onClick={editButtonClick} />
              <Button
                content="Delete Change Request"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <Button content="View Change Request" onClick={editButtonClick} />
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Change Requests"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no change requests" screen, or the CR list */}
        {changeRequests.length === 0 ? (
          <div>There are no change requests.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active change request */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectChangeRequest
              project={myProject}
              projectClosed={projectClosed}
              newForm={newForm}
              change={activeChangeRequest}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected change request(s) */}
        <Dialog
          header={
            selectedChanges.length > 1
              ? "Delete these change requests?"
              : "Delete this change request?"
          }
          content="The selected change requests will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default ChangeRequestList;
