import React, { useState, useContext } from "react";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import {
  Form,
  Button,
  Dropdown,
  TextArea,
  Datepicker,
} from "@fluentui/react-northstar";
import { CalendarLtr16Regular, List16Regular } from "@fluentui/react-icons";
import { SaveStatusReport } from "../../../helpers/ProjectService";

import {
  defaultPicklistKey,
  getPicklistKeys,
  validDate,
} from "../projectUtils";

import StatusReport from "../../../objects/models/StatusReport";

import "../../../CarnaStyles.css";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import AttachmentsView from "../Attachments";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  report?: StatusReport;
  closeAction: () => void;
  
}

const ProjectStatusReport: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [statusReport, setStatusReport] = useState<StatusReport>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && statusReport) {
      const key = ddl.id;
      const value = ddl.value.key;
      setStatusReport({ ...statusReport, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && statusReport) {
      const key = e.target.id;
      setStatusReport({ ...statusReport, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeStartDate = (d: any, e: any) => {
    if (e.value && statusReport) {
      const startDate = e.value as Date;
      const reportingStart = startDate.toISOString().slice(0, 10);
      var endDate = new Date(statusReport.endDate).toISOString().slice(0, 10);
      var reportingPeriod = reportingStart + " to " + endDate;
      setStatusReport({
        ...statusReport,
        startDate: startDate,
        reportingPeriod: reportingPeriod,
      });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeEndDate = (d: any, e: any) => {
    if (e.value && statusReport) {
      const endDate = e.value as Date;
      const reportingEnd = endDate.toISOString().slice(0, 10);
      var startDate = new Date(statusReport.startDate)
        .toISOString()
        .slice(0, 10);
      var reportingPeriod = startDate + " to " + reportingEnd;
      setStatusReport({
        ...statusReport,
        endDate: endDate,
        reportingPeriod: reportingPeriod,
      });
    }
  };

  //no report loaded on the page yet. Load it from props, or create a new one.
  if (statusReport === undefined) {
    //we were passed a report. Load it in and let's go!
    if (props.report !== undefined) {
      setStatusReport(props.report);
      return <div>Loading status report.</div>;
    }
    //no report passed in. We must need to create a new one.
    else {
      //yep, create a new one
      if (newForm) {
        //we have the project ID. Do the work.
        if (projectID !== undefined) {
          var newReport = new StatusReport();
          newReport.project = projectID;
          newReport.startDate = new Date();
          newReport.endDate = new Date();
          setStatusReport(newReport);
          return <div>Creating new form.</div>;
        } else {
          return (
            <div>
              This is a new form, but was not supplied the owning Project ID.
              Did you forget a prop?
            </div>
          );
        }
      } else {
        return (
          <div>
            No status report was supplied, but this isn't a new form either. Did
            you forget a prop?
          </div>
        );
      }
    }
  } else {
    const saveAndClose = async () => {
      if (!validReport(statusReport)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveStatusReport(statusReport, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    var heading = newForm
      ? "Add a new status report"
      : "Status report for " + statusReport.reportingPeriod;
    return (
      <div className="carnaForm" data-testid="status-form">
        <h1>{heading}</h1>
        <span onClick={closePopup} className="close-button topright">
          X
        </span>
        <Form>
          <div className="carnaRow">
            <h2>Reporting Period</h2>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <CalendarLtr16Regular /> Start Date{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="startDate"
                key="startDate"
                selectedDate={new Date(statusReport.startDate)}
                onDateChange={onChangeStartDate}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(statusReport.startDate)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <CalendarLtr16Regular /> End Date <Required theme={themeCss} />
              </div>
              <Datepicker
                id="endDate"
                key="endDate"
                selectedDate={new Date(statusReport.endDate)}
                onDateChange={onChangeEndDate}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(statusReport.endDate)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <h2>Status</h2>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Overall Health <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="overallHealthStatus"
                  key="overallHealthStatus"
                  items={getPicklistKeys(
                    picklists,
                    "crd5f_overallhealthstatus"
                  )}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_overallhealthstatus",
                    statusReport.overallHealthStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.overallHealthStatus
                      ? "Overall Health must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Schedule Status <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="scheduleStatus"
                  key="scheduleStatus"
                  aira-label="Schedule Status"
                  items={getPicklistKeys(picklists, "crd5f_schedulestatus")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_schedulestatus",
                    statusReport.scheduleStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.scheduleStatus
                      ? "Schedule status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Budget Status <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="budgetStatus"
                  key="budgetStatus"
                  aria-label="Budget Status"
                  items={getPicklistKeys(picklists, "crd5f_budgetstatus")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_budgetstatus",
                    statusReport.budgetStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.budgetStatus
                      ? "Budget Status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Resource Status <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="resourceStatus"
                  key="resourceStatus"
                  aria-label="Resource Status"
                  items={getPicklistKeys(picklists, "crd5f_resourcestatus")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_resourcestatus",
                    statusReport.resourceStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.resourceStatus
                      ? "Resource Status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Milestone Status <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="milestoneStatus"
                  key="milestoneStatus"
                  aria-label="Milestone Status"
                  items={getPicklistKeys(picklists, "crd5f_milestonestatus")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_milestonestatus",
                    statusReport.milestoneStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.milestoneStatus
                      ? "Milestone Status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Change MGMT Status{" "}
                <Required theme={themeCss} />
              </div>
              <div>
                <Dropdown
                  id="changeMgmtStatus"
                  key="changeMgmtStatus"
                  aria-label="Change MGMT Status"
                  items={getPicklistKeys(
                    picklists,
                    "crd5f_changemanagementstatus"
                  )}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_changemanagementstatus",
                    statusReport.changeMgmtStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !statusReport.changeMgmtStatus
                      ? "Change MGMT Status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Project Standards Met?
              </div>
              <div>
                <Dropdown
                  id="projectStandardsMet"
                  key="projectStandardsMet"
                  aria-label="Project Standards Met?"
                  items={getPicklistKeys(
                    picklists,
                    "crd5f_projectstandardsmet"
                  )}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_projectstandardsmet",
                    statusReport.projectStandardsMet
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  aria-required
                  disabled={!userRole.canEdit || isClosed}
                />
              </div>
            </div>
          </div>
          <h2>Items for Awareness</h2>
          <div className="carnaColFull">
            <TextArea
              id="itemsForAwareness"
              key="itemsForAwareness"
              value={statusReport.itemsForAwareness}
              onChange={onChangeTextArea}
              fluid
              resize="vertical"
              readOnly={!userRole.canEdit || isClosed}
              maxLength={2000}
            />
            <CharactersRemaining
              value={statusReport.itemsForAwareness}
              maxLength={2000}
            />
          </div>
          <h2>Change Readiness Activities</h2>
          <div className="carnaColFull">
            <TextArea
              id="changeReadiness"
              key="changeReadiness"
              value={statusReport.changeReadiness}
              onChange={onChangeTextArea}
              fluid
              resize="vertical"
              readOnly={!userRole.canEdit || isClosed}
              maxLength={2000}
            />
            <CharactersRemaining
              value={statusReport.changeReadiness}
              maxLength={2000}
            />
          </div>
          <h2>
            Corporate Summary <Required theme={themeCss} />
          </h2>
          <div className="carnaColFull">
            <TextArea
              id="corporateSummary"
              key="corporateSummary"
              value={statusReport.corporateSummary}
              onChange={onChangeTextArea}
              fluid
              required
              resize="vertical"
              disabled={!userRole.canEdit || isClosed}
              maxLength={2000}
            />
            <CharactersRemaining
              value={statusReport.corporateSummary}
              maxLength={2000}
            />
            <div className="errorText">
              {showErrors
                ? !statusReport.corporateSummary
                  ? "Corporate Summary is required"
                  : undefined
                : undefined}
            </div>
          </div>
          <h2>
            Council Summary <Required theme={themeCss} />
          </h2>
          <div className="carnaColFull">
            <TextArea
              id="councilSummary"
              key="councilSummary"
              value={statusReport.councilSummary}
              onChange={onChangeTextArea}
              fluid
              required
              resize="vertical"
              disabled={!userRole.canEdit || isClosed}
              maxLength={2000}
            />
            <CharactersRemaining
              value={statusReport.councilSummary}
              maxLength={2000}
            />
            <div className="errorText">
              {showErrors
                ? !statusReport.councilSummary
                  ? "Council Summary is required"
                  : undefined
                : undefined}
            </div>

            {statusReport ? (
              <div>
                <AttachmentsView
                  project={projectID}
                  entity="statusreport"
                  entityId={statusReport.statusReportId}
                  disabled={newForm}
                />
              </div>
            ) : undefined}
          </div>
          {userRole.canEdit && !isClosed ? (
            <div style={{ float: "left" }}>
              <Button content="Save Status Report" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validReport(report: StatusReport): boolean {
  var valid: boolean = false;
  if (
    [
      validDate(report.startDate),
      validDate(report.endDate),
      report.overallHealthStatus,
      report.scheduleStatus,
      report.budgetStatus,
      report.resourceStatus,
      report.milestoneStatus,
      report.changeMgmtStatus,
      report.corporateSummary,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectStatusReport;
