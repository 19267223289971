import { Login } from "@microsoft/mgt-react";
import useIsSignedIn from "./Hooks/useIsSignedIn";
import RoleWrapper from "./RoleWrapper";

export default function Tab() {
  const isSignedIn = useIsSignedIn();
  return (
    <>
      {isSignedIn ? (
        <>
          <RoleWrapper />
        </>
      ) : (
        <div>
          <Login />
          <h2>Please wait while we sign you in, or click Login.</h2>
        </div>
      )}
    </>
  )
}
 