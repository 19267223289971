import {
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { PicklistValues } from "../../objects/models/PicklistValues";
import { IChart, ProgramWithProjects } from "./CouncilReportPDFLink";
import { CouncilStatusReportProject } from "./CouncilStatusReportProject";

interface CouncilStatusReportProgramProps {
    index: number;
    program: ProgramWithProjects;
    picklists: PicklistValues[];
    investmentChart: IChart | undefined;
    strategicChart: IChart | undefined;
}

// Create styles
const styles = StyleSheet.create({
  teal: {
    color: "#1d988c",
  },
  col1: {
    width: "100%",
  },
  col2: {
    width: "50%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  boldThis: {
    fontFamily: "MontserratSemiBold",
  },
  programIndex: {        
    fontWeight: "bold",
    fontSize: 80,
    color: "lightgrey",
    marginTop: -15
  },
  programHeader: {
      fontSize: 18,
      fontWeight: "bold",
      position: "absolute",
      top: "30%",
      left: 0,
  },
  separator: {
    marginVertical: 20,
    borderTop: "1px solid #111921"
  },
  chartRow: {
    justifyContent: "space-around",
  },
  chartView: {
    height: 200,
    width: 200,
  },
  chartText: {
    fontSize: 10,
    display: "flex",
    justifyContent: "space-around",
  }
});

export const CouncilStatusReportProgram = ({ program, index, picklists, investmentChart, strategicChart }: CouncilStatusReportProgramProps) => {
  return (
    <View key={program.name}>
      <View wrap={false}>
        <View style={[styles.programIndex, styles.col1]}>
            <Text>{index + 1}</Text>
            <Text style={[styles.programHeader, styles.boldThis, styles.teal]}>{program.name}</Text>
        </View>      
        <Text>
          <View style={styles.boldThis}>Program Summary: </View>
          {program.description}
        </Text>
        <View style={styles.separator}></View>
        {investmentChart === undefined || strategicChart === undefined ? null : 
          <View style={[styles.row, styles.chartRow]}>
            <View>
              <Text style={[styles.boldThis, styles.chartText, styles.col2]}>Program Investment Summary</Text>
              <View style={styles.chartView}>
                {investmentChart === undefined ? null : <Image src={investmentChart.chart} />}
              </View>
            </View>
            <View>
              <Text style={[styles.boldThis, styles.chartText, styles.col2]}>Program Strategic Alignment Summary</Text>
              <View style={styles.chartView}>
                {strategicChart === undefined ? null : <Image src={strategicChart.chart} />}
              </View>
            </View>
          </View>
        }
      </View>
      <View style={styles.separator}></View>
      {program.Projects.map((project) => <CouncilStatusReportProject key={project.projectName} project={project} picklists={picklists} />)}
    </View>
  );
};
