import React, { useState, useContext } from "react";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import {
  Button,
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import { TextChangeCase16Regular, List16Regular } from "@fluentui/react-icons";

import { SaveMilestone } from "../../../helpers/ProjectService";

import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
  validDate,
} from "../projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";

import Milestone from "../../../objects/models/Milestone";
import "../../../CarnaStyles.css";
import AttachmentsView from "../Attachments";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  milestone?: Milestone;
  closeAction: () => void;
}

const ProjectMilestone: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [milestone, setMilestone] = useState<Milestone>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && milestone) {
      const key = ddl.id;
      const value = ddl.value.key;
      setMilestone({ ...milestone, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && milestone) {
      const key = e.target.id;
      setMilestone({ ...milestone, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeDate = (d: any, e: any) => {
    if (e.value && milestone) {
      const newValue = e.value as Date;
      setMilestone({
        ...milestone,
        deliverableDate: newValue,
      });
    }
  };

  //no milestone loaded yet. Get it from props, or create a fresh one.
  if (milestone === undefined) {
    //we've been passed a milestone. Load it, let's rock and roll.
    if (props.milestone !== undefined) {
      setMilestone(props.milestone);
      return <div>Loading form...</div>;
    }
    //no report passed in. Must be a new one.
    else {
      //yep, new form
      if (newForm) {
        var newMilestone = new Milestone();
        newMilestone.projectId = projectID;
        newMilestone.name = "[New Milestone]";
        newMilestone.deliverableDate = new Date();
        setMilestone(newMilestone);
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No milestone was supplied, but this isn't a new form either. Did you
            forget a prop?
          </div>
        );
      }
    }
  }
  //milestone loaded, open the form
  else {
    const saveAndClose = async () => {
      if (!validReport(milestone)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveMilestone(milestone, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    return (
      <div className="carnaForm" data-testid="milestone-form">
        <Form>
          <h1>
            <TextArea
              id="name"
              key="name"
              value={milestone.name}
              onChange={onChangeTextArea}
              className="titleField"
              autoFocus={newForm}
              onFocus={onFocusSelectAll}
              fluid
              rows={1}
              readOnly={!userRole.canEdit || isClosed}
              maxLength={250}
            />
            <CharactersRemaining value={milestone.name} maxLength={250} />
          </h1>
          <div className="errorText">
            {showErrors
              ? !milestone.name
                ? "Name is required"
                : undefined
              : undefined}
          </div>
          <span onClick={closePopup} className="close-button topright">
            X
          </span>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Type <Required theme={themeCss} />
              </div>
              <Dropdown
                id="type"
                key="type"
                items={getPicklistKeys(picklists, "crd5f_milestonetype")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_milestonetype",
                  milestone.type
                )}
                onChange={onChangeDDL}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !milestone.type
                    ? "Milestone Type must have a value."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Deliverable Date{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="deliverableDate"
                key="deliverableDate"
                selectedDate={new Date(milestone.deliverableDate)}
                onDateChange={onChangeDate}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(milestone.deliverableDate)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Status <Required theme={themeCss} />
              </div>
              <Dropdown
                id="status"
                key="status"
                items={getPicklistKeys(picklists, "crd5f_milestonestatus")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_milestonestatus",
                  milestone.status
                )}
                onChange={onChangeDDL}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !milestone.status
                    ? "Milestone Status must have a value."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Description{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="description"
                key="description"
                value={milestone.description}
                onChange={onChangeTextArea}
                fluid
                rows={10}
                autoFocus={!newForm}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={10000}
              />
              <CharactersRemaining
                value={milestone.description}
                maxLength={10000}
              />
              <div className="errorText">
                {showErrors
                  ? !milestone.description
                    ? "Description is required"
                    : undefined
                  : undefined}
              </div>
            </div>
            {milestone ? (
              <div>
                <AttachmentsView
                  project={projectID}
                  entity="milestone"
                  entityId={milestone.milestoneId}
                  disabled={newForm}
                />
              </div>
            ) : undefined}
          </div>
          {userRole.canEdit && !isClosed ? (
            <div style={{ float: "left" }}>
              <Button content="Save Milestone" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validReport(milestone: Milestone): boolean {
  var valid: boolean = false;
  if (
    [
      milestone.name,
      milestone.type,
      milestone.status,
      validDate(milestone.deliverableDate),
      milestone.description,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectMilestone;
