import { useState, useEffect, useContext } from "react";

import Invoice from "../../../objects/models/Invoice";
import {
  GetInvoices,
  DeleteInvoice,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";

import "../../../CarnaStyles.css";
import ProjectInvoice from "./projectInvoiceSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";
import { formatCurrency } from "../../../helpers/CurrencyFormatter";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

var dateFormat = require("dateformat");

const InvoiceList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [invoicesLoaded, setInvoicesLoaded] = useState<boolean>(false);
  const [invoicesLoading, setInvoicesLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeInvoice, setActiveInvoice] = useState<Invoice>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!invoicesLoaded && !invoicesLoading) {
        setInvoicesLoading(true);
        const response = await GetInvoices(myProject);
        setInvoices(response);
        if (invoices) setInvoicesLoaded(true);
        setInvoicesLoading(false);
      }
    })();
  }, [invoices, invoicesLoaded, invoicesLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues("crd5f_projectinvoice");
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "invoiceHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      }, //select column
      {
        content: (
          <h3>
            Vendor
            {sortedColumn === "invoiceNumber" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "invoiceNumber",
        onClick: () =>
          HeaderClick(
            "invoiceNumber",
            false,
            invoices,
            setInvoices,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Invoice Date
            {sortedColumn === "invoiceDate" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "invoiceDate",
        onClick: () =>
          HeaderClick(
            "invoiceDate",
            false,
            invoices,
            setInvoices,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Invoice Amount
            {sortedColumn === "invoiceAmount" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "invoiceAmount",
        onClick: () =>
          HeaderClick(
            "invoiceAmount",
            true,
            invoices,
            setInvoices,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Invoice Type
            {sortedColumn === "invoiceType" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "invoiceType",
        onClick: () =>
          HeaderClick(
            "invoiceType",
            true,
            invoices,
            setInvoices,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Account Code
            {sortedColumn === "accountCode" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "invoiceAccountCode",
        onClick: () =>
          HeaderClick(
            "accountCode",
            false,
            invoices,
            setInvoices,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  const renderGridrow = (item: Invoice, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "invoiceNumber":
          return <div>{item.invoiceNumber}</div>;
        case "invoiceDate":
          var dateString = dateFormat(item.invoiceDate, "yyyy-mm-dd");
          return <div>{dateString}</div>;
        case "invoiceAmount":
          return <div>{formatCurrency(item.invoiceAmount)}</div>;
        case "invoiceType":
          if (item.invoiceType) {
            var keys = getPicklistKeys(picklists, "crd5f_invoicetype");
            var displayValue = LookupPicklistValue(keys, item.invoiceType);
            return <div>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "accountCode":
          return <div>{item.accountCode}</div>;
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedInvoices];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedInvoices(selected);
  }

  function refreshInvoices(): void {
    setSelectedInvoices([]);
    setInvoicesLoaded(false);
  }

  const onItemInvoked = (item: Invoice) => {
    setNewForm(false);
    setActiveInvoice(item);
    setIsModalOpen(true);
  };
  const newInvoice = () => {
    setNewForm(true);
    setActiveInvoice(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshInvoices();
  };
  const deleteAction = async () => {
    for (const invoice of selectedInvoices) {
      await DeleteInvoice(invoice);
    }
    setDeleteDialogOpen(false);
    refreshInvoices();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedInvoices.length > 0) {
      const invoice = invoices?.find(
        ({ invoiceId }) => invoiceId === selectedInvoices[0]
      );
      onItemInvoked(invoice!);
    }
  };

  if (invoices === undefined || picklists === undefined) {
    return <div>Loading invoices...</div>;
  } else {
    const rowItems = invoices.map((invoice) => {
      return {
        key: invoice.invoiceId + "-row",
        items: [
          {
            key: invoice.invoiceId + "-0",
            content: selectedInvoices.includes(invoice.invoiceId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(invoice.invoiceId),
            className: "rowSelectColumn",
          },
          {
            key: invoice.invoiceId + "-1",
            content: renderGridrow(invoice, "invoiceNumber"),
            onClick: () => toggleSelected(invoice.invoiceId),
            //onClick: () => onItemInvoked(invoice), //in case you want clicking to open the form
          },
          {
            key: invoice.invoiceId + "-2",
            content: renderGridrow(invoice, "invoiceDate"),
            onClick: () => toggleSelected(invoice.invoiceId),
          },
          {
            key: invoice.invoiceId + "-3",
            content: renderGridrow(invoice, "invoiceAmount"),
            onClick: () => toggleSelected(invoice.invoiceId),
          },
          {
            key: invoice.invoiceId + "-5",
            content: renderGridrow(invoice, "invoiceType"),
            onClick: () => toggleSelected(invoice.invoiceId),
          },
          {
            key: invoice.invoiceId + "-6",
            content: renderGridrow(invoice, "accountCode"),
            onClick: () => toggleSelected(invoice.invoiceId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedInvoices.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Invoice" onClick={newInvoice} />
            </div>
          ) : undefined
        ) : selectedInvoices.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Invoice" onClick={editButtonClick} />
              <Button
                content="Delete Invoice"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <Button content="View Invoice" onClick={editButtonClick} />
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Invoices"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no invoices" screen, or the invoice list */}
        {invoices.length === 0 ? (
          <div>There are no invoices.</div>
        ) : (
          <div>
            <Table header={headers} rows={rowItems} />
          </div>
        )}
        {/* All the dialogs are below. Use the "open" prop to toggle. */}
        {/* The active invoice */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectInvoice
              project={myProject}
              projectClosed={projectClosed}
              newForm={newForm}
              invoice={activeInvoice}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete selected invoices */}
        <Dialog
          header={
            selectedInvoices.length > 1
              ? "Delete these invoices?"
              : "Delete this invoice?"
          }
          content="The selected invoices will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default InvoiceList;
