import "./App.css";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import TeamsAuthPopup from "./TeamsAuthPopup";
import Tab from "./Tab";
import {MgtPersonCard} from '@microsoft/mgt-react'

function App() {
  MgtPersonCard.config.sections.files = false;
  MgtPersonCard.config.sections.mailMessages = false;
  MgtPersonCard.config.sections.profile = false;
  
  if (window.parent === window.self) {
    return (
      <Switch>
        <Route exact path="/tabauth" component={TeamsAuthPopup} />
        <Route component={Tab} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route component={Tab} />
    </Switch>
  );
}

export default App;
