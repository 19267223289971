import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

export default class Benefit {
    benefitId!:string;
    projectId!:string;
    name!:string;
    declaration?:string;
    performanceMeasure?:string;
    currentBaseline?: string;
    initiationDate!:Date;
    reviewCycle?:number;

    businessOwnerName?: string;
    businessOwnerDomainName?: string;
    businessOwnerSysID?:string;
    businessOwnerAzureID?: string;

    businessOwner?:MicrosoftGraph.User;
}