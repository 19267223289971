import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Dashboard.css";
import Project from "../../objects/models/Project";
import * as Dataverse from "../../helpers/ProjectService";
import CardBuilder from "./helper/CardBuilder";
import { crd5f_overallhealthEnum as ProjectHealth } from "../../objects/entities/crd5f_project";
import { useMediaQuery } from "react-responsive";
import {
  ArrowLeft16Filled,
  ArrowRight16Filled,
} from "@fluentui/react-icons";

const Dashboard = () => {
  const [projectList, setProjectList] = useState<Project[]>();
  const [projectListLoading, setProjectListLoading] = useState<boolean>(false);
  const [projectListLoaded, setProjectListLoaded] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  useEffect(() => {
    (async function loadData() {
      if (!projectListLoaded && !projectListLoading) {
        setProjectListLoading(true);
        const response = await Dataverse.GetProjectList();
        //sort the project list so that all the closed projects are at the end.
        const activeProjects = response.filter(x => x.overallHealth !== ProjectHealth.Closed);
        const closedProjects = response.filter(x => x.overallHealth === ProjectHealth.Closed);
        activeProjects.push(...closedProjects);
        setProjectList(activeProjects);
        if (projectList !== undefined) setProjectListLoaded(true);
        setProjectListLoading(false);
      }
    })();
  }, [projectListLoading, projectListLoaded, projectList]);

  const responsive = {
    0: { items: 1 },
    700: { items: 2 },
    1100: { items: 3 },
    1500: { items: 4 },
    1800: { items: 5 },
  };

  const cards: Array<JSX.Element> = [];

  for (const index in projectList) {
    const projectTitle: string = projectList[Number(index)]["projectName"];
    const projectID: string = projectList[Number(index)]["projectId"];
    const projectHealth: number | undefined =
      projectList[Number(index)]["overallHealth"];

    cards.push(
      <CardBuilder
        projectID={projectID}
        index={index}
        projectTitle={projectTitle}
        projectHealth={projectHealth}
      />
    );
  }

  const renderNextButton = () => {
    return (
      <div style={{padding:10}}>
        Next <ArrowRight16Filled />
    </div>
    );
  };
  const renderPrevButton = () => {
    return (
        <div style={{padding:10}}>
          <ArrowLeft16Filled /> Previous
        </div>
    );
  };

  return (
    <>
      {!projectListLoaded ? (
        <div> Loading dashboard...</div>
      ) : isMobile ? (
        <div>{cards}</div>
      ) : (
        <AliceCarousel
          mouseTracking
          keyboardNavigation
          items={cards}
          responsive={responsive}
          controlsStrategy="responsive"
          disableDotsControls
          renderNextButton={renderNextButton}
          renderPrevButton={renderPrevButton}
          animationType="fadeout"
          animationDuration={800}
        />
      )}
    </>
  );
};

export default Dashboard;
