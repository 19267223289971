export default class Decision {
    decisionId!: string;
    projectId!: string;
    name!: string;
    description?: string;
    requiredBy?: Date;
    decisionMade?: string;
    decisionDate?: Date;
    status!: number;

    assignedToName?: string;
    assignedToDomainName?: string;
    assignedToSysID?: string;
    assignedToAzureID?: string;

    decisionMadeByName?: string;
    decisionMadeByDomainName?: string;
    decisionMadeBySysID?: string;
    decisionMadeByAzureID?: string;
}