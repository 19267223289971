import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { PicklistValues } from "../../objects/models/PicklistValues";
import { IChart, ProgramWithProjects } from "./CouncilReportPDFLink";
import montserratSemiBold from "../../fonts/Montserrat-SemiBold.ttf";
import montserratLight from "../../fonts/Montserrat-Light.ttf";
import footerswirl from "../../resources/footerswirl.jpg";
import { CouncilStatusReportProgram } from "./CouncilStatusReportProgram";
import { CouncilStatusReportHeader } from "./CouncilReportHeader";
import { IQuarter } from "./PrintQuarterlyReport";

Font.register({ family: "MontserratSemiBold", src: montserratSemiBold });
Font.register({ family: "MontserratLight", src: montserratLight });

interface CouncilStatusReportContentProps {
  programs: ProgramWithProjects[];
  picklists: PicklistValues[];
  investmentCharts: IChart[];
  strategicCharts: IChart[];
  quarter: IQuarter;
}

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 85,
    paddingHorizontal: 0,
    margin: 0,
    fontFamily: "MontserratLight",
    fontSize: 10,
  },
  pageView: {
    paddingHorizontal: 35,
  },
  footerswirl: {
    position: "absolute",
    bottom: 0,
    right: 0,
    paddingBottom: 0,
    paddingRight: 0,
    width: "150px",
    zIndex: -1,
  },
  footer: {
    position: "absolute",
    fontSize: 8,
    bottom: 0,
    paddingBottom: 0,
    color: "grey",
    width: "100%",
  },
  pageNumber: {
    position: "absolute",
    width: "5%",
    bottom: 30,
    left: 40,
    zIndex: 10,
  },
  address: {
    position: "absolute",
    width: "80%",
    bottom: 30,
    right: 10,
    zIndex: 10,
  },
  teal: {
    color: "#1d988c",
  },
  boldThis: {
    fontFamily: "MontserratSemiBold",
  },
});

export const CouncilStatusReportContent = ({ programs, picklists, investmentCharts, strategicCharts, quarter }: CouncilStatusReportContentProps) => {
  return (
    <Document>
      {programs.filter(x => x.Projects !== undefined && x.Projects.length !== 0).map((program, index) => {
        const investmentChart = investmentCharts.find(x => x.programId === program.programId);
        const strategicChart = strategicCharts.find(x => x.programId === program.programId);
        return (<Page size="A4" style={styles.page}>
              <CouncilStatusReportHeader quarter={quarter} />
              <View style={styles.pageView}>
                <CouncilStatusReportProgram key={program.name} program={program} index={index} picklists={picklists} investmentChart={investmentChart} strategicChart={strategicChart} />
              </View>              
              <View style={styles.footer} fixed>
                <Image source={footerswirl} style={styles.footerswirl} />
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                />
                <Text style={styles.address}>
                  11120 178 Street, Edmonton, AB, T5S 1P2 1 (800) 252-9392
                  crna@nurses.ab.ca
                </Text>
              </View>
          </Page>);
      })}
    </Document>
  );
};
