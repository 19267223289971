import { useState, useEffect, useContext } from "react";

import Milestone from "../../../objects/models/Milestone";
import {
  GetMilestones,
  DeleteMilestone,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import "../../../CarnaStyles.css";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";
import ProjectMilestone from "./projectMilestoneSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

var dateFormat = require("dateformat");

const MilestoneList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [milestones, setMilestones] = useState<Milestone[]>();
  const [milestonesLoaded, setMilestonesLoaded] = useState<boolean>(false);
  const [milestonesLoading, setMilestonesLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>("deliverableDate");
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedMilestones, setSelectedMilestones] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeMilestone, setActiveMilestone] = useState<Milestone>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!milestonesLoaded && !milestonesLoading) {
        setMilestonesLoading(true);
        const response = await GetMilestones(myProject);
        setMilestones(response);
        if (milestones !== undefined) setMilestonesLoaded(true);
        setMilestonesLoading(false);
      }
    })();
  }, [milestones, milestonesLoaded, milestonesLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues("crd5f_projectmilestone");
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "milestoneHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      },
      {
        content: (
          <h3>
            Milestone
            {sortedColumn === "name" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "milestoneName",
        onClick: () =>
          HeaderClick(
            "name",
            false,
            milestones,
            setMilestones,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Type
            {sortedColumn === "type" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "milestoneType",
        onClick: () =>
          HeaderClick(
            "type",
            true,
            milestones,
            setMilestones,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Status
            {sortedColumn === "status" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "milestoneStatus",
        onClick: () =>
          HeaderClick(
            "status",
            true,
            milestones,
            setMilestones,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Deliverable Date
            {sortedColumn === "deliverableDate" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "milestoneDeliverableDate",
        onClick: () =>
          HeaderClick(
            "deliverableDate",
            false,
            milestones,
            setMilestones,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  const renderGridrow = (item: Milestone, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "name":
          return (
            <>
              {" "}
              <div>{item.name}</div>
            </>
          );
        case "type":
          var keys = getPicklistKeys(picklists, "crd5f_milestonetype");
          var displayValue = LookupPicklistValue(keys, item.type);
          return <div>{displayValue}</div>;
        case "status":
          var keys = getPicklistKeys(picklists, "crd5f_milestonestatus");
          var displayValue = LookupPicklistValue(keys, item.status);
          return <div>{displayValue}</div>;
        case "deliverableDate":
          var dateString = dateFormat(item.deliverableDate, "yyyy-mm-dd");
          return <div>{dateString}</div>;
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedMilestones];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedMilestones(selected);
  }

  function refreshMilestones(): void {
    setSelectedMilestones([]);
    setMilestonesLoaded(false);
  }

  const onItemInvoked = (item: Milestone) => {
    setNewForm(false);
    setActiveMilestone(item);
    setIsModalOpen(true);
  };
  const newMilestone = () => {
    setNewForm(true);
    setActiveMilestone(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshMilestones();
  };

  const deleteAction = async () => {
    for (const milestone of selectedMilestones) {
      await DeleteMilestone(milestone);
    }
    setDeleteDialogOpen(false);
    refreshMilestones();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedMilestones.length > 0) {
      const milestone = milestones?.find(
        ({ milestoneId }) => milestoneId === selectedMilestones[0]
      );
      onItemInvoked(milestone!);
    }
  };

  if (milestones === undefined || picklists === undefined) {
    return <div>Loading milestones...</div>;
  } else {
    const rowItems = milestones.map((milestone) => {
      return {
        key: milestone.milestoneId + "-row",
        items: [
          {
            key: milestone.milestoneId + "-0",
            content: selectedMilestones.includes(milestone.milestoneId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(milestone.milestoneId),
            className: "rowSelectColumn",
          },
          {
            key: milestone.milestoneId + "-1",
            content: renderGridrow(milestone, "name"),
            onClick: () => toggleSelected(milestone.milestoneId),
          },
          {
            key: milestone.milestoneId + "-2",
            content: renderGridrow(milestone, "type"),
            onClick: () => toggleSelected(milestone.milestoneId),
          },
          {
            key: milestone.milestoneId + "-3",
            content: renderGridrow(milestone, "status"),
            onClick: () => toggleSelected(milestone.milestoneId),
          },
          {
            key: milestone.milestoneId + "-4",
            content: renderGridrow(milestone, "deliverableDate"),
            onClick: () => toggleSelected(milestone.milestoneId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedMilestones.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Milestone" onClick={newMilestone} />
            </div>
          ) : undefined
        ) : selectedMilestones.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Milestone" onClick={editButtonClick} />
              <Button
                content="Delete Milestone"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <Button content="View Milestone" onClick={editButtonClick} />
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Milestones"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no milestones" screen, or the milestone list */}
        {milestones.length === 0 ? (
          <div>There are no milestones.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active milestone */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectMilestone
              newForm={newForm}
              project={myProject}
              projectClosed={projectClosed}
              milestone={activeMilestone}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected milestone(s) */}
        <Dialog
          header={
            selectedMilestones.length > 1
              ? "Delete these milestones?"
              : "Delete this milestone?"
          }
          content="The selected milestones will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default MilestoneList;
