import { NavLink } from "react-router-dom";

export function ProjectListNav() {
  return (
    <div data-testid="project-list-nav">
      <div>
        <h1>My Projects</h1>
      </div>
      <div>Active</div>
      <div>Inactive</div>
      <div>Show All</div>
      <NavLink to="/dashboard">
        <div>
          <h1>Project Dashboard</h1>
        </div>
      </NavLink>
    </div>
  );
}
