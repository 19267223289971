import React from "react";

interface Props {
  value: string | undefined;
  maxLength: number;
}

export const CharactersRemaining: React.FC<Props> = (props: Props) => {
    const { value, maxLength } = props;
    let className = "goodText";
    let charsRemaining = maxLength;
    if (value !== null && value !== undefined) {
        charsRemaining = maxLength - value.length;
        if (charsRemaining <= 0) {
            className = "errorText";            
        }
    }

    return (
        <div className={`characters-remaining ${className}`}>{charsRemaining} of {maxLength} characters remaining</div>    
    )
};