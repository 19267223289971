import React from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import { getPicklistKeys, LookupPicklistValue } from "../../project/projectUtils";
import { ProgramWithProjects } from "../CouncilReportPDFLink";
import { colors } from "./colors";

export interface IInvestmentSummaryChartProps {
    chartCallback: (id: string, uri: string) => void;
    program: ProgramWithProjects;
    picklists: PicklistValues;
    field: string;
}

const CouncilPieChart = ({ chartCallback, program, picklists, field } : IInvestmentSummaryChartProps) => {
  const [getPng, { ref }] = useCurrentPng();  

  const returnChartPng = async () => {
    const png = await getPng();
    chartCallback(program.programId, png || "");
  };

  const data = program.Projects.reduce((accum, current) => {
    const pickText = printPicklistValue(picklists, field, current.investmentType) as string;
    if (accum[pickText] === undefined) {
      accum[pickText] = { name: pickText, value: 1};
    } else {
      accum[pickText].value += 1;
    }
    return accum;
  }, {} as any)

  const dataArray = Object.values(data);

  return (
    <div>
      <PieChart width={400} height={400} ref={ref}>
        <Pie
          onAnimationEnd={returnChartPng}
          data={dataArray}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
        >
          {dataArray.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colors[(entry as any).name]}
              stroke={colors[(entry as any).name]}
            />
          ))}
        </Pie>
        <Legend />
      </PieChart>      
    </div>
  );
};

export default CouncilPieChart;

function printPicklistValue(
  picklists: PicklistValues,
  field: string,
  key?: number
) {
  if (key) {
    var keys = getPicklistKeys(picklists, field);
    var displayValue = LookupPicklistValue(keys, key);
    return displayValue;
  } else {
    return " ";
  }
}
