import { PicklistValues } from "../../objects/models/PicklistValues";
import { BrowserCacheLocation } from "@azure/msal-browser";

interface keyPair {
  key: number;
  header: string;
}

export function getPicklistKeys(allKeys: PicklistValues | undefined, field: string) {
  const arrayObjects: { key: number; header: string }[] = [];

  if (allKeys !== undefined) {
    const found = allKeys.Lists.find((match) => match.LogicalName === field);
    found?.Keys.forEach((key) => {
      arrayObjects.push({
        key: key.key,
        header: key.text,
      });
    });
  } else {
    arrayObjects.push({
      key: 0,
      header: "Loading values",
    });
  }
  return arrayObjects;
}

export function defaultPicklistKey(
  allKeys: PicklistValues,
  entity: string,
  value?: number
) {
  const arrayObjects: { key: number; header: string }[] = [];

  const found = allKeys.Lists.find((match) => match.LogicalName === entity);
  found?.Keys.forEach((key) => {
    arrayObjects.push({
      key: key.key,
      header: key.text,
    });
  });

  const defaultKey = arrayObjects.find((match) => match.key === value);
  return defaultKey;
}

//This function is called when the user clicks on a column header.
export function HeaderClick<T>(
  key: string, //the column we want to sort
  numeric: boolean, //is the column numeric?
  toSort: T[] | undefined, //the array we want to sort
  setter: (sortThis:T[]) => void, //the setter for the array
  sortedColumn: string | undefined, //what column is it currently sorted on?
  setSortedBy: (sortby:string) => void, //setter for currently sorted column
  sortedDescending: boolean, //is it already sorted descending?
  setDescending: (descend:boolean) => void, //set if we're sorted descending
):void {
  var descending = sortedDescending;
    //if this is the same column we're already sorting by, flip "descending"
    if (key === sortedColumn)
    {
      descending = !descending;
    }
    //otherwise, numbers we want to go largest to smallest, strings we want from A to Z.
    else
    {
      if (numeric)
      {
        descending = true;
      }
      else
      {
        descending = false;
      }
    }
    if (toSort)
    {
      //call CopyAndSort, then use the setter up update the stateful array with the result
      setter(_copyAndSort(toSort, key, descending));
      setSortedBy(key);
      setDescending(descending);
    }
  }

 function _copyAndSort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    return items
      .slice(0)
      .sort((a: T, b: T) =>
        (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
      );
  }

export function LookupPicklistValue(keys: keyPair[], toFind: number) {
  let result = keys.find((i) => i.key === toFind);
  if (result) {
    return result.header;
  }
}

//the default date is something like Dec 31, 1969.
//Just check what millenium the year is, if it's this one then assume the user has given it a value.
export function validDate(date: Date | undefined): boolean {
  if (!date) {
    return false;
  } else {
    if (date.getFullYear() > 2000) return true;
    else return false;
  }
}

//could not be typed
export const onFocusSelectAll = (e:any) => {
  e.currentTarget.select();
}

export const msalConfiguration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority:
      process.env.REACT_APP_CLIENT_AUTHORITY,
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false,
  },
};