import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

export default class Risk {
    riskId!:string;
    projectId!:string;
    name!:string;
    description?:string;
    mitigationStrategy?:string;
    impact?:number;
    probability?:number;
    dueDate!:Date;
    status?:number;
    corporateRiskNum?:string;

    assignedToName?: string;
    assignedToDomainName?: string;
    assignedToSysID?:string;
    assignedToAzureID?: string;

    assignedTo?:MicrosoftGraph.User;
}