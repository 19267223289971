import { Route, Switch } from "react-router-dom";
import { ProjectListNav } from "./ProjectListNav";
import { ProjectDetailsNav } from "./ProjectDetailsNav";
import ProjectDashboardNav from "./ProjectDashboardNav";

export default function LeftNav() {
  return (
    <div className="carnaLeftNav">
      <Switch>
        <Route path="/details">
          <ProjectDetailsNav />
        </Route>
        <Route path="/dashboard">
          <ProjectDashboardNav />
        </Route>
        <Route path="/">
          <ProjectListNav />
        </Route>
      </Switch>
    </div>
  );
}
