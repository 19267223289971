import React, { useEffect, useState } from "react";
import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Project from "../../../objects/models/Project";
import Milestone from "../../../objects/models/Milestone";
import ChangeRequest from "../../../objects/models/ChangeRequest";
import Risk from "../../../objects/models/Risk";
import StatusReport from "../../../objects/models/StatusReport";
import {
  GetSingleStatusReport,
  GetProject,
  GetMilestones,
  GetChangeRequests,
  GetRisks,
  getPicklistValues,
} from "../../../helpers/ProjectService";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import { DocContent } from "./ManagerStatusReportContent";
var dateFormat = require("dateformat");

export interface FullProject extends Project {
  statusReport?: StatusReport;
  milestones?: Milestone[];
  changeRequests?: ChangeRequest[];
  risks?: Risk[];
}

interface StatusReportProps {
  statusReportId: string;
}

async function getProjectData(statusReportId: string) {
  //retrieve the status report based on the ID.
  const thisReport = await GetSingleStatusReport(statusReportId);
  //from the project ID, get the Project data and all relevant entities.
  var project: FullProject = await GetProject(thisReport.project);
  const milestones = await GetMilestones(thisReport.project);
  const changeRequests = await GetChangeRequests(thisReport.project);
  const risks = await GetRisks(thisReport.project);
  project.statusReport = thisReport;
  project.milestones = milestones;
  project.changeRequests = changeRequests;
  project.risks = risks;
  return project;
}

export const StatusReportPDFLink: React.FC<StatusReportProps> = (props: StatusReportProps) => {
  const [projectData, setProjectInfo] = useState<FullProject>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [picklistValues, setPicklistValues] = useState<PicklistValues[]>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);
  const statusReportId = props.statusReportId;

  useEffect(() => {
    (async function loadData() {
      if (!dataLoaded && !dataLoading) {
        setDataLoading(true);
        const response = await getProjectData(statusReportId);
        setProjectInfo(response);
        if (projectData) setDataLoaded(true);
        setDataLoading(false);
      }
    })();
  }, [dataLoading, dataLoaded, projectData]);

  //load the picklist values
  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      (async function () {
        const statusPicklistValues = await getPicklistValues(
          "crd5f_projectstatusreport"
        );
        const milestonePicklistValues = await getPicklistValues(
          "crd5f_projectmilestone"
        );
        const changeRequestPicklistValues = await getPicklistValues(
          "crd5f_projectchangerequest"
        );
        const risksPicklistVlaues = await getPicklistValues(
          "crd5f_projectrisk"
        );
        const allPicklistValues = [
          statusPicklistValues,
          milestonePicklistValues,
          changeRequestPicklistValues,
          risksPicklistVlaues,
        ];
        setPicklistValues(allPicklistValues);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      })();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  if (projectData === undefined || picklistValues === undefined) {
    return <div>Loading document...</div>;
  }

  const projName = projectData.acronym
    ? projectData.acronym
    : projectData.projectName;
  const reportDate = dateFormat(
    projectData.statusReport!.endDate,
    "yyyy-mm-dd"
  );
  const fileName = projName + "-" + reportDate;

  return (
    <div>
      <PDFDownloadLink
        document={
          <DocContent project={projectData} picklists={picklistValues} />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink>
    </div>
  );
};