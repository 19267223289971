import React, { useState, useContext } from "react";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import {
  Button,
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import {
  TextChangeCase16Regular,
  Person16Regular,
  List16Regular,
} from "@fluentui/react-icons";

import {
  SaveRisk,
  VerifyPersonInDataverse,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";

import Risk from "../../../objects/models/Risk";
import "../../../CarnaStyles.css";
import { PeoplePicker } from "@microsoft/mgt-react";
import AttachmentsView from "../Attachments";
import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
} from "../projectUtils";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  risk?: Risk;
  closeAction: () => void;
}

const ProjectRisk: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [risk, setRisk] = useState<Risk>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [personError, setPersonError] = useState<string>();
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && risk) {
      const key = ddl.id;
      const value = ddl.value.key;
      setRisk({ ...risk, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && risk) {
      const key = e.target.id;
      setRisk({ ...risk, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeDate = (d: any, e: any) => {
    if (e.value && risk) {
      const newValue = e.value as Date;
      setRisk({
        ...risk,
        dueDate: newValue,
      });
    }
  };

  //type doesn't work
  const handlePersonChange = async (e: any) => {
    //guard against "undefined" risk. The risk must be loaded.
    if (risk) {
      const target = e.target.id;
      //have we selected a new user?
      if (e.detail.length > 0) {
        //we first need to verify that the person chosen from Graph IS someone in our databse.
        //some selections are not in our systemuser table, so they're invalid.
        const newPerson = e.detail[0] as MicrosoftGraph.User;
        const dataverseId = await VerifyPersonInDataverse(newPerson.id!);
        //If they were found in our database, then they are valid. Update our risk object.
        if (dataverseId) {
          setRisk({
            ...risk,
            assignedTo: newPerson,
            assignedToSysID: dataverseId,
            assignedToName: newPerson.displayName as string,
            assignedToDomainName: undefined,
          });
          setPersonError(undefined);
        } else {
          const cannedMessage =
            " is an external user. Please select someone else.";
          const personError = newPerson.displayName + cannedMessage;
          setRisk({ ...risk, assignedToSysID: undefined });
          setPersonError(personError);
        }
      } else {
        //we removed the person. Request they choose another.
        setRisk({ ...risk, assignedTo: undefined, assignedToSysID: undefined });
        setPersonError("Please assign this risk to someone.");
      }
    }
  };

  //no risk loaded yet. Get it from the props, or create a fresh one.
  if (risk === undefined) {
    //we've been passed a risk! Load it, and let's boogie!
    if (props.risk !== undefined) {
      setRisk(props.risk);
      return <div>Loading form...</div>;
    }
    //nope, no report passed in. This must be a new one.
    else {
      //yes, new form confirmed
      if (newForm) {
        var newRisk = new Risk();
        newRisk.projectId = projectID;
        newRisk.name = "[New Risk]";
        newRisk.dueDate = new Date();
        newRisk.description = "";
        newRisk.mitigationStrategy = "";
        setRisk(newRisk);
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No risk was supplied, but this isn't a new form either... Did we
            forget a prop?
          </div>
        );
      }
    }
  }
  //milestone loaded, open the form
  else {
    const saveAndClose = async () => {
      if (!validRisk(risk)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveRisk(risk, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    return (
      <div className="carnaForm" data-testid="risk-form">
        <Form>
          <h1>
            <TextArea
              id="name"
              key="name"
              value={risk.name}
              onChange={onChangeTextArea}
              className="titleField"
              autoFocus={newForm}
              onFocus={onFocusSelectAll}
              fluid
              rows={1}
              readOnly={!userRole.canEdit || isClosed}
              maxLength={250}
            />
            <CharactersRemaining value={risk.name} maxLength={250} />
          </h1>
          <div className="errorText">
            {showErrors
              ? !risk.name
                ? "Name is required"
                : undefined
              : undefined}
          </div>
          <span onClick={closePopup} className="close-button topright">
            X
          </span>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Description{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="description"
                key="description"
                value={risk.description}
                onChange={onChangeTextArea}
                autoFocus={!newForm}
                required
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining value={risk.description} maxLength={2000} />
              <div className="errorText">
                {showErrors
                  ? !risk.description
                    ? "Description is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Mitigation Strategy
              </div>
              <TextArea
                id="mitigationStrategy"
                key="mitigationStrategy"
                value={risk.mitigationStrategy}
                rows={6}
                onChange={onChangeTextArea}
                fluid
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining value={risk.mitigationStrategy} maxLength={2000} />
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Impact <Required theme={themeCss} />
              </div>
              <Dropdown
                id="impact"
                key="impact"
                items={getPicklistKeys(picklists, "crd5f_impact")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_impact",
                  risk.impact
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                aria-required
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !risk.impact
                    ? "Impact must have a value."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Probability <Required theme={themeCss} />
              </div>
              <Dropdown
                id="probability"
                key="probability"
                items={getPicklistKeys(picklists, "crd5f_probability")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_probability",
                  risk.probability
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                aria-required
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !risk.probability
                    ? "Probability must have a value."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <Person16Regular /> Assigned To <Required theme={themeCss} />
              </div>
              <PeoplePicker
                id="assignedTo"
                key="assignedTo"
                className={themeCss}
                defaultSelectedUserIds={
                  risk.assignedToAzureID ? [risk.assignedToAzureID] : undefined
                }
                selectionMode="single"
                selectionChanged={handlePersonChange}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {personError
                  ? personError
                  : showErrors
                  ? !risk.assignedToAzureID
                    ? "You must select a person"
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Due Date
              </div>
              <Datepicker
                id="dueDate"
                key="dueDate"
                selectedDate={new Date(risk.dueDate)}
                onDateChange={onChangeDate}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Status <Required theme={themeCss} />
              </div>
              <Dropdown
                id="status"
                key="status"
                items={getPicklistKeys(picklists, "crd5f_riskstatus")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_riskstatus",
                  risk.status
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                aria-required
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !risk.status
                    ? "Status must have a value."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Corporate Risk #
              </div>
              <TextArea
                id="corporateRiskNum"
                key="corporateRiskNum"
                value={risk.corporateRiskNum}
                onChange={onChangeTextArea}
                rows={1}
                fluid
                readOnly={!userRole.canEdit || isClosed}
              />
            </div>
            {risk ? (
              <div>
                <AttachmentsView
                  project={projectID}
                  entity="risk"
                  entityId={risk.riskId}
                  disabled={newForm}
                />
              </div>
            ) : undefined}
          </div>
          {userRole.canEdit && !isClosed ? (
            <div style={{float:"left"}}>
              <Button content="Save Risk" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validRisk(risk: Risk): boolean {
  var valid: boolean = false;
  if (
    [
      risk.name,
      risk.description,
      risk.impact,
      risk.probability,
      risk.assignedToSysID,
      risk.status,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectRisk;
