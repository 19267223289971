import React, { useContext, useEffect, useState } from "react";
import {
  listAttachments,
  downloadAttachment,
  deleteAttachment,
  uploadAttachment,
} from "../../helpers/AzureStorageHelper";
import ProjectAttachment from "../../objects/models/ProjectAttachment";
import { Attachment, Dialog } from "@fluentui/react-northstar";
import { CloseIcon, PaperclipIcon } from "@fluentui/react-icons-northstar";
import { RoleContext } from "../../RoleWrapper";
import "../../CarnaStyles.css";

interface Props {
  project: string;
  entity: string;
  entityId: string;
  disabled: boolean;
}

const AttachmentsView: React.FC<Props> = (props: Props) => {
  const [attachments, setAttachments] = useState<ProjectAttachment[]>();
  const [attachmentsLoading, setAttachmentsLoading] = useState<boolean>(false);
  const [attachmentsLoaded, setAttachmentsLoaded] = useState<boolean>(false);

  const [handleFile, setHandleFile] = useState<File | ProjectAttachment>();
  const [dialogMessage, setDialogMessage] = useState<string>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [replaceDialogOpen, setReplaceDialogOpen] = useState<boolean>(false);

  const userRole = useContext(RoleContext);

  const project = props.project;
  const entity = props.entity;
  const entityId = props.entityId;
  const disabled = props.disabled;

  useEffect(() => {
    (async function loadData() {
      if (!attachmentsLoaded && !attachmentsLoading && !disabled) {
        setAttachmentsLoading(true);
        const response = await listAttachments(project, entity, entityId);
        setAttachments(response);
        setAttachmentsLoaded(true);
        setAttachmentsLoading(false);
      }
    })();
  }, [attachments, attachmentsLoaded, attachmentsLoading]);

  function refreshData(): void {
    setAttachmentsLoaded(false);
  }

  const clearDialog = () => {
    setHandleFile(undefined);
    setDeleteDialogOpen(false);
    setReplaceDialogOpen(false);
    setDialogMessage(undefined);
  };

  //download an attachment
  const handleClick = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    x: ProjectAttachment
  ) => {
    e.stopPropagation();
    downloadAttachment(x);
  };

  //user clicked on the "Add attachments" button and chose a file.
  //When the file is put into the file input, this fires.
  const uploadClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //null guard
    if (e.target.files) {
      //grab the file. If it's undefined, don't do anything.
      const theFile = e.target.files[0];
      if (theFile && attachments) {
        //check to see if it already exists.
        var repeat = attachments.find((a) => a.friendlyName == theFile.name);
        if (repeat !== undefined) {
          //if it already exists, bring up a dialog and confirm they wish to overwrite.
          setHandleFile(theFile);
          setDialogMessage(theFile.name + " already exists. Overwrite?");
          setReplaceDialogOpen(true);
        } else {
          //new file, just go upload it
          await uploadAttachment(project, entity, entityId, theFile);
          refreshData();
        }
      }
    }
  };

  //the file already existed, and the user confirmed they wish to overwrite it.
  const uploadOverwrite = async () => {
    if (handleFile instanceof File) {
      await uploadAttachment(project, entity, entityId, handleFile);
      clearDialog();
      refreshData();
    } else {
      console.log(
        "entered uploadAction, but wrong type of file (requires a File object)"
      );
    }
  };

  //click on the X for an attachment in order to delete it.
  const deleteClick = (
    e: React.SyntheticEvent<HTMLElement, Event>,
    x: ProjectAttachment
  ) => {
    e.stopPropagation();
    setHandleFile(x);
    setDialogMessage(x.friendlyName + " will be deleted.");
    setDeleteDialogOpen(true);
  };

  //user confirmed in a dialog, perform the delete
  const deleteAction = async () => {
    if (handleFile instanceof ProjectAttachment) {
      await deleteAttachment(handleFile);
      clearDialog();
      refreshData();
    } else {
      console.log(
        "entered deleteAction, but wrong kind of file (requires a ProjectAttachment object)"
      );
    }
  };

  if (disabled) {
    return (
      <div>
        <h3>Attachments</h3>
        Please save this record before adding attachments.
      </div>
    );
  } else {
    if (!attachments) {
      if (attachmentsLoading) {
        return (
          <div>
            <h3>Attachments</h3>
            Loading attachments...
          </div>
        );
      } else {
        return (
          <div>
            <h3>Attachments</h3>
            there was a problem loading the attachments...
          </div>
        );
      }
    } else {
      if (attachments.length == 0) {
        return (
          <div>
            <div className="attachmentHeader">
              <PaperclipIcon /> Attachments
            </div>
            {userRole.canEdit ? (
              <div className="attachmentSpacer">
                <label htmlFor="attachmentUpload" className="attachmentUpload">
                  Add attachments
                </label>
                <input
                  id="attachmentUpload"
                  type="file"
                  onChange={uploadClick}
                  className="attachmentUploadBox"
                />
              </div>
            ) : undefined}
          </div>
        );
      } else {
        return (
          <div data-testid="attachments">
            <div className="attachmentHeader">
              <PaperclipIcon /> Attachments
            </div>
            {attachments.map((x, i) => {
              return (
                <div className="attachmentRow" key={i}>
                  <Attachment
                    icon={x.icon()}
                    header={x.friendlyName}
                    onClick={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                      handleClick(e, x);
                    }}
                    action={
                      userRole.canEdit
                        ? {
                            icon: <CloseIcon />,
                            onClick: (
                              e: React.SyntheticEvent<HTMLElement, Event>
                            ) => {
                              deleteClick(e, x);
                            },
                            title: "Delete?",
                          }
                        : undefined
                    }
                  />
                </div>
              );
            })}
            {userRole.canEdit ? (
              <div className="attachmentSpacer">
                <label htmlFor="attachmentUpload" className="attachmentUpload">
                  Add attachments
                </label>
                <input
                  id="attachmentUpload"
                  type="file"
                  onChange={uploadClick}
                  className="attachmentUploadBox"
                />
                <Dialog
                  header="Delete file?"
                  content={dialogMessage}
                  cancelButton="Cancel"
                  confirmButton="Confirm Delete"
                  onConfirm={deleteAction}
                  onCancel={clearDialog}
                  open={deleteDialogOpen}
                />
                <Dialog
                  header="Replace file?"
                  content={dialogMessage}
                  cancelButton="Cancel"
                  confirmButton="Overwrite"
                  onConfirm={uploadOverwrite}
                  onCancel={clearDialog}
                  open={replaceDialogOpen}
                />
              </div>
            ) : undefined}
          </div>
        );
      }
    }
  }
};

export default AttachmentsView;
