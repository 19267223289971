import React from "react";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import "./index.css";

interface RiskSCPieChartProps {
  riskCount: {
    [x: string]: number;
  };
}

const RiskSCPieChart = (props: RiskSCPieChartProps) => {
  const data = [
    { name: "High", value: props.riskCount.High },
    { name: "Medium", value: props.riskCount.Medium },
    { name: "Low", value: props.riskCount.Low },
  ];

  const COLORS = ["#FF8042", "#FFBB28", "#00C49F"];

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active) {
      return (
        <div className="custom-tool-tip">
          <p className="title">Risk Impact</p>
          <p
            style={{
              color: COLORS[0],
            }}
          >
            High : {props.riskCount.High}
          </p>
          <p
            style={{
              color: COLORS[1],
            }}
          >
            Medium : {props.riskCount.Medium}
          </p>
          <p
            style={{
              color: COLORS[2],
            }}
          >
            Low : {props.riskCount.Low}
          </p>
        </div>
      );
    }

    return null;
  };

  const animationDuration = 1000;

  return (
    <div className="pie-chart" data-testid="risk-pie-chart">
      <PieChart width={200} height={180}>
        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          animationDuration={animationDuration}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              stroke={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          position={{x:50}}
          labelStyle={{ color: "#000", fontWeight: 600 }}
          content={CustomTooltip}
        />
        <Legend />
      </PieChart>
    </div>
  );
};

export default RiskSCPieChart;
