import React from "react";
import { Bar, BarChart, Tooltip, XAxis, YAxis } from "recharts";
import "./index.css";

export interface TimelineStackedVBarChartProps {
  timeline: {
    estimatedEndDate: Date | undefined;
    estimatedStartDate: Date | undefined;
    projectedEndDate: Date | undefined;
    projectedStartDate: Date | undefined;
  };
}

const TimelineStackedVBarChart = (props: TimelineStackedVBarChartProps) => {
  const timeline = props.timeline;
  let estimatedRange = 0;
  if (timeline.estimatedStartDate && timeline.estimatedEndDate) {
    estimatedRange =
      timeline.estimatedEndDate?.valueOf() -
      timeline.estimatedStartDate?.valueOf();
  }
  let projectedRange = 0;
  if (timeline.projectedStartDate && timeline.projectedEndDate) {
    projectedRange =
      timeline.projectedEndDate?.valueOf() -
      timeline.projectedStartDate?.valueOf();
  }

  const data = [
    {
      name: "Estimated",
      "Estimated Range": estimatedRange,
      estimatedStartDate: timeline.estimatedStartDate?.toDateString(),
      estimatedEndDate: timeline.estimatedEndDate?.toDateString(),
    },
    {
      name: "Progress",
      "Progress": projectedRange,
      projectedStartDate: timeline.projectedStartDate?.toDateString(),
      projectedEndDate: timeline.projectedEndDate?.toDateString(),
    },
  ];

  const getProjectedStatus = () => {
    if (projectedRange <= estimatedRange) {
      return "#00C49F";
    } else {
      const increase =
        ((projectedRange - estimatedRange) / estimatedRange) * 100;
      if (increase <= 10) {
        return "#FFBB28";
      } else {
        return "#FF8042";
      }
    }
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    let startDate = "";
    let endDate = "";
    if (active) {
      if (label === "Estimated") {
        startDate = payload?.[0].payload?.estimatedStartDate;
        endDate = payload?.[0].payload?.estimatedEndDate;
      } else {
        startDate = payload?.[0].payload?.projectedStartDate;
        endDate = payload?.[0].payload?.projectedEndDate;
      }
    }

    const startDateString = startDate?.substr(startDate.indexOf(" ") + 1);
    const endDateString = endDate?.substr(endDate.indexOf(" ") + 1);

    if (active) {
      return (
        <div className="custom-tool-tip">
          <p className="title">{label}</p>
          <p
            style={{
              color: payload?.[0].color,
            }}
          >
            Start Date : {startDateString}
          </p>
          <p
            style={{
              color: payload?.[0].color,
            }}
          >
            End Date : {endDateString}
          </p>
        </div>
      );
    }

    return null;
  };

  const animationDuration = 1000;

  return (
    <BarChart
      width={240}
      height={100}
      data={data}
      layout="vertical"
      margin={{
        top: 20,
        right: 0,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis type="number" hide />
      <YAxis type="category" dataKey="name" hide />
      <Tooltip
        position={{x:50}}
        labelStyle={{ color: "#000", fontWeight: 600 }}
        content={CustomTooltip}
      />
      <Bar
        dataKey="Progress"
        stackId="a"
        fill={getProjectedStatus()}
        animationDuration={animationDuration}
      />
      <Bar
        dataKey="Estimated Range"
        stackId="a"
        fill="#428cff"
        animationDuration={animationDuration}
      />
    </BarChart>
  );
};

export default TimelineStackedVBarChart;
