import React, { createContext } from "react";
import { getMyGroups } from "./helpers/PermissionService";
import UserRole from "./objects/models/UserRole";
import ThemeWrapper from "./ThemeWrapper";

export const RoleContext = createContext(new UserRole());

interface RoleState {
  role: UserRole;
  groups: string[];
}

export default class RoleWrapper extends React.Component<{}, RoleState> {
  constructor(props: any) {
    super(props);
    this.state = { role: new UserRole(), groups: [] };
  }

  async componentDidMount() {
    var role: UserRole = new UserRole();
    var groups = await getMyGroups();

    if (groups.indexOf(process!.env!.REACT_APP_PROJECT_MANAGER_GUID!) > -1) {
      role.canView = true;
      role.canEdit = true;
    }
    if (groups.indexOf(process!.env!.REACT_APP_PROJECT_REVIEWER_GUID!) > -1) {
      role.canView = true;
    }
    if (groups.indexOf(process!.env!.REACT_APP_PROJECT_EXECUTIVE_GUID!) > -1) {
      role.canView = true;
      role.dashboardDefault = true;
    }
    if (groups.indexOf(process!.env!.REACT_APP_PROJECT_PORTFOLIO_GUID!) > -1) {
      role.canView = true;
      role.canEdit = true;
      role.portfolioOwner = true;
    }
    this.setState({ role: role, groups: groups });
  }

  render() {
    if (this.state.role) {
      if (this.state.role.canView) {
        return (
          <RoleContext.Provider value={this.state.role}>
            <ThemeWrapper />
          </RoleContext.Provider>
        );
      } else {
        if (this.state.groups.length === 0) {
          return <div>Loading...</div>;
        }
        return (
          <div>
            You do not have permission to access this resource. Please contact
            your adminsitrator.
          </div>
        );
      }
    }
    return (
      <div>
        There was a problem with your permissions. Please contact your
        administrator.
      </div>
    );
  }
}
