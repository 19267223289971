import React, { useState, useContext } from "react";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";

import {
  Button,
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import {
  TextChangeCase16Regular,
  CalendarLtr16Regular,
  List16Regular,
} from "@fluentui/react-icons";

import { SaveInvoice } from "../../../helpers/ProjectService";

import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
  validDate,
} from "../projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";

import Invoice from "../../../objects/models/Invoice";
import "../../../CarnaStyles.css";
import CurrencyInput from "react-currency-input-field";
import AttachmentsView from "../Attachments";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  invoice?: Invoice;
  closeAction: () => void;
}

const ProjectInvoice: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [invoice, setInvoice] = useState<Invoice>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && invoice) {
      const key = ddl.id;
      const value = ddl.value.key;
      setInvoice({ ...invoice, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && invoice) {
      const key = e.target.id;
      setInvoice({ ...invoice, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeDate = (d: any, e: any) => {
    if (e.value && invoice) {
      const newValue = e.value as Date;
      setInvoice({
        ...invoice,
        invoiceDate: newValue,
      });
    }
  };

  //event: currency field change
  const handleMoneyChange = (id?: string, val?: string) => {
    if (invoice && id) {
      if (val !== undefined) {
        setInvoice({ ...invoice, [id]: val });
      } else {
        setInvoice({ ...invoice, [id]: 0 });
      }
    }
  };

  //no invoice loaded yet. Get it from the props or make a new one.
  if (invoice === undefined) {
    //we have one passed in. Use it!
    if (props.invoice !== undefined) {
      setInvoice(props.invoice);
      return <div>Loading form...</div>;
    }
    //nope, no invoice. Is it new?
    else {
      //new form?
      if (newForm) {
        var newInvoice = new Invoice();
        newInvoice.projectId = projectID;
        newInvoice.invoiceNumber = "[New Vendor]";
        newInvoice.invoiceDate = new Date();
        newInvoice.invoiceAmount = 0;
        newInvoice.accountCode = "";
        newInvoice.otherDetails = "";
        setInvoice(newInvoice);
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No invoice supplied, but this isn't a new form... Did we forget a
            prop?
          </div>
        );
      }
    }
  }
  //invoice loaded, yee-haw
  else {
    const saveAndClose = async () => {
      if (!validInvoice(invoice)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveInvoice(invoice, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    return (
      <div className="carnaForm" data-testid="invoice-form">
        <Form>
          <h1>
            <div className="carnaColSmall">Vendor:</div>
            <div className="carnaColLarge">
              {/* leaving this instead of moving vendor up as invoice number is the primary field on the Project Invoice Entity */}
              <TextArea
                id="invoiceNumber"
                key="invoiceNumber"
                value={invoice.invoiceNumber}
                onChange={onChangeTextArea}
                autoFocus={newForm}
                onFocus={onFocusSelectAll}
                fluid
                rows={1}
                readOnly={!userRole.canEdit || isClosed}
                maxLength={250}
              />
              <CharactersRemaining
                value={invoice.invoiceNumber}
                maxLength={250}
              />
            </div>
          </h1>
          <div className="errorText">
            {showErrors
              ? !invoice.invoiceNumber
                ? "Vendor is required"
                : undefined
              : undefined}
          </div>
          <span onClick={closePopup} className="close-button topright">
            X
          </span>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <CalendarLtr16Regular /> Invoice Date{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="invoiceDate"
                key="invoiceDate"
                selectedDate={new Date(invoice.invoiceDate)}
                onDateChange={onChangeDate}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(invoice.invoiceDate)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Invoice Type <Required theme={themeCss} />
              </div>
              <Dropdown
                id="invoiceType"
                key="invoiceType"
                items={getPicklistKeys(picklists, "crd5f_invoicetype")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_invoicetype",
                  invoice.invoiceType
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !invoice.invoiceType
                    ? "Invoice type is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">$ Invoice Amount</div>
              <CurrencyInput
                id="invoiceAmount"
                defaultValue={invoice.invoiceAmount}
                allowDecimals={false}
                prefix="$"
                className={themeCss + " currency"}
                onValueChange={(val) => handleMoneyChange("invoiceAmount", val)}
                value={invoice.invoiceAmount}
                onFocus={onFocusSelectAll}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Account Code{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="accountCode"
                key="accountCode"
                value={invoice.accountCode}
                onChange={onChangeTextArea}
                rows={1}
                fluid
                readOnly={!userRole.canEdit || isClosed}
                maxLength={100}
              />
              <CharactersRemaining
                value={invoice.accountCode}
                maxLength={100}
              />
              <div className="errorText">
                {showErrors
                  ? !invoice.accountCode
                    ? "Account code is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Other Details
              </div>
              <TextArea
                id="otherDetails"
                key="otherDetails"
                value={invoice.otherDetails}
                onChange={onChangeTextArea}
                fluid
                rows={6}
                autoFocus={!newForm}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={invoice.otherDetails}
                maxLength={2000}
              />
            </div>
          </div>
          {invoice ? (
            <div>
              <AttachmentsView
                project={projectID}
                entity="invoice"
                entityId={invoice.invoiceId}
                disabled={newForm}
              />
            </div>
          ) : undefined}
          {showErrors ? (
            <div className="errorText">
              There were errors on the page. Please check that you've filled out
              all required fields.
            </div>
          ) : undefined}
          {userRole.canEdit && !isClosed ? (
            <div style={{ float: "left" }}>
              <Button content="Save Invoice" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validInvoice(invoice: Invoice): boolean {
  var valid: boolean = false;
  if (
    [
      invoice.invoiceNumber,
      validDate(invoice.invoiceDate),
      invoice.invoiceType,
      invoice.accountCode,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectInvoice;
