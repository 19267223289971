import { useState, useEffect, useContext } from "react";

import {
  Person,
  PersonCardInteraction,
  PersonViewType,
} from "@microsoft/mgt-react";

import Decision from "../../../objects/models/Decision";

import {
  GetDecisions,
  DeleteDecision,
  getPicklistValues,
} from "../../../helpers/ProjectService";

import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";

import "../../../CarnaStyles.css";
import ProjectDecision from "./projectDecisionSingle";
import { Button, Dialog, Table } from "@fluentui/react-northstar";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

var dateFormat = require("dateformat");

const DecisionList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [decisions, setDecisions] = useState<Decision[]>();
  const [decisionsLoaded, setDecisionsLoaded] = useState<boolean>(false);
  const [decisionsLoading, setDecisionsLoading] = useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedDecisions, setSelectedDecisions] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeDecision, setActiveDecision] = useState<Decision>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!decisionsLoaded && !decisionsLoading) {
        setDecisionsLoading(true);
        const response = await GetDecisions(myProject);
        setDecisions(response);
        if (decisions) setDecisionsLoaded(true);
        setDecisionsLoading(false);
      }
    })();
  }, [decisions, decisionsLoaded, decisionsLoading]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues("crd5f_projectdecision");
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "decisionHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      },
      {
        content: (
          <h3>
            Decision
            {sortedColumn === "name" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "decisionName",
        onClick: () =>
          HeaderClick(
            "name",
            false,
            decisions,
            setDecisions,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Assigned To
            {sortedColumn === "assignedToName" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "decisionAssignedToName",
        onClick: () =>
          HeaderClick(
            "assignedToName",
            false,
            decisions,
            setDecisions,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Required By
            {sortedColumn === "requiredBy" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "decisionRequiredBy",
        onClick: () =>
          HeaderClick(
            "requiredBy",
            false,
            decisions,
            setDecisions,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Status
            {sortedColumn === "status" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "decisionStatus",
        onClick: () =>
          HeaderClick(
            "status",
            true,
            decisions,
            setDecisions,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Decision Made By
            {sortedColumn === "decisionMadeByName" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "decisionMadeByName",
        onClick: () =>
          HeaderClick(
            "decisionMadeByName",
            true,
            decisions,
            setDecisions,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  //name, assigned to, required by, status
  const renderGridrow = (item: Decision, key: string) => {
    if (item && picklists) {
      switch (key) {
        case "name":
          return <div>{item.name}</div>;
        case "assignedToName":
          if (item.assignedToName) {
            return (
              <Person
                userId={item.assignedToAzureID}
                view={PersonViewType.oneline}
                fetchImage
                personCardInteraction={PersonCardInteraction.none}
                fallbackDetails={{ displayName: item.assignedToName }}
              />
            );
          } else {
            return <div>N/A</div>;
          }
        case "requiredBy":
          if (item.requiredBy) {
            var dateString = dateFormat(item.requiredBy, "yyyy-mm-dd");
            return <div>{dateString}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "status":
          if (item.status) {
            var keys = getPicklistKeys(picklists, "crd5f_decisionstatus");
            var displayValue = LookupPicklistValue(keys, item.status);
            return <div>{displayValue}</div>;
          } else {
            return <div>N/A</div>;
          }
        case "decisionMadeByName": 
        if (item.decisionMadeByName) {
          return (
            <Person
              userId={item.decisionMadeByAzureID}
              view={PersonViewType.oneline}
              fetchImage
              personCardInteraction={PersonCardInteraction.none}
              fallbackDetails={{ displayName: item.decisionMadeByName }} //in case graph doesn't load
            />
          );
        } else {
          return <div></div>;
        }
      }
    }
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedDecisions];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedDecisions(selected);
  }

  function refreshDecisions(): void {
    setSelectedDecisions([]);
    setDecisionsLoaded(false);
  }

  const onItemInvoked = (item: Decision) => {
    setNewForm(false);
    setActiveDecision(item);
    setIsModalOpen(true);
  };
  const newDecision = () => {
    setNewForm(true);
    setActiveDecision(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshDecisions();
  };

  const deleteAction = async () => {
    for (const decision of selectedDecisions) {
      await DeleteDecision(decision);
    }
    setDeleteDialogOpen(false);
    refreshDecisions();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedDecisions.length > 0) {
      const decision = decisions?.find(
        ({ decisionId }) => decisionId === selectedDecisions[0]
      );
      onItemInvoked(decision!);
    }
  };

  if (decisions === undefined || picklists === undefined) {
    return <div>Loading decisions...</div>;
  } else {
    const rowItems = decisions.map((decision) => {
      return {
        key: decision.decisionId + "-row",
        items: [
          {
            key: decision.decisionId + "-0",
            content: selectedDecisions.includes(decision.decisionId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(decision.decisionId),
            className: "rowSelectColumn",
          },
          {
            key: decision.decisionId + "-1",
            content: renderGridrow(decision, "name"),
            onClick: () => toggleSelected(decision.decisionId),
          },
          {
            key: decision.decisionId + "-2",
            content: renderGridrow(decision, "assignedToName"),
            onClick: () => toggleSelected(decision.decisionId),
          },
          {
            key: decision.decisionId + "-3",
            content: renderGridrow(decision, "requiredBy"),
            onClick: () => toggleSelected(decision.decisionId),
          },
          {
            key: decision.decisionId + "-4",
            content: renderGridrow(decision, "status"),
            onClick: () => toggleSelected(decision.decisionId),
          },
          {
            key: decision.decisionId + "-5",
            content: renderGridrow(decision, "decisionMadeByName"),
            onClick: () => toggleSelected(decision.decisionId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedDecisions.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Decision" onClick={newDecision} />
            </div>
          ) : undefined
        ) : selectedDecisions.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Decision" onClick={editButtonClick} />
              <Button
                content="Delete Decision"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            </div>
          ) : (
            <Button content="View Decision" onClick={editButtonClick} />
          )
        ) : userRole.canEdit && !projectClosed ? (
          <div>
            <Button
              content="Delete Decisions"
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
            />
          </div>
        ) : undefined}
        {/* End top buttons */}
        <br />
        {/* Display either the "no decisions" screen, or the decision list */}
        {decisions.length === 0 ? (
          <div>There are no decisions.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active decision */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectDecision
              project={myProject}
              projectClosed={projectClosed}
              newForm={newForm}
              decision={activeDecision}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected decision(s) */}
        <Dialog
          header={
            selectedDecisions.length > 1
              ? "Delete these decisions?"
              : "Delete this decision?"
          }
          content="The selected decisions will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
      </>
    );
  }
};

export default DecisionList;
