import React, { useContext, useState } from "react";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";

import {
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import { Button } from "@fluentui/react-northstar";
import {
  TextChangeCase16Regular,
  Person16Regular,
  List16Regular,
} from "@fluentui/react-icons";

import {
  VerifyPersonInDataverse,
  SaveChangeRequest,
} from "../../../helpers/ProjectService";

import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
  validDate,
} from "../projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";

import ChangeRequest from "../../../objects/models/ChangeRequest";
import "../../../CarnaStyles.css";
import { PeoplePicker } from "@microsoft/mgt-react";
import CurrencyInput from "react-currency-input-field";
import AttachmentsView from "../Attachments";
import { Required } from "../Required";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  picklists: PicklistValues;
  change?: ChangeRequest;
  closeAction: () => void;
}

const ProjectChangeRequest: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const picklists = props.picklists;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [changeRequest, setChangeRequest] = useState<ChangeRequest>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [requestorError, setRequestorError] = useState<string>();
  const [approverError, setApproverError] = useState<string>();
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && changeRequest) {
      const key = ddl.id;
      const value = ddl.value.key;
      setChangeRequest({ ...changeRequest, [key]: value });
    }
  };

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && changeRequest) {
      const key = e.target.id;
      setChangeRequest({ ...changeRequest, [key]: value });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeRequiredBy = (d: any, e: any) => {
    if (e.value && changeRequest) {
      setChangeRequest({ ...changeRequest, requiredBy: e.value as Date });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeDateRequested = (d: any, e: any) => {
    if (e.value && changeRequest) {
      setChangeRequest({ ...changeRequest, dateRequested: e.value as Date });
    }
  };

  //event: currency field change
  const handleMoneyChange = (id?: string, val?: string) => {
    if (changeRequest && id) {
      if (val !== undefined) {
        setChangeRequest({ ...changeRequest, [id]: val });
      } else {
        setChangeRequest({ ...changeRequest, [id]: 0 });
      }
    }
  };

  //type doesn't work
  const handlePersonChange = async (e: any) => {
    //guard against "undefined" decision. The decision must be loaded.
    if (changeRequest) {
      const target = e.target.id;
      //have we selected a new user?
      if (e.detail.length > 0) {
        //we first need to verify that the person chosen from Graph IS someone in our databse.
        //some selections are not in our systemuser table, so they're invalid.
        const newPerson = e.detail[0] as MicrosoftGraph.User;
        const dataverseId = await VerifyPersonInDataverse(newPerson.id!);
        //If they were found in our database, then they are valid. Update our decision object.
        if (dataverseId) {
          switch (target) {
            case "requestor":
              setChangeRequest({
                ...changeRequest,
                requestorSysID: dataverseId,
                requestorName: newPerson.displayName as string,
                requestorAzureID: newPerson.id,
              });
              setRequestorError(undefined);
              break;
            case "approver":
              setChangeRequest({
                ...changeRequest,
                approverSysID: dataverseId,
                approverName: newPerson.displayName as string,
                approverAzureID: newPerson.id,
              });
              setApproverError(undefined);
          }
        }
        //graph user not found in the DV. Blank the systemuser field and diplay an error.
        else {
          const cannedMessage =
            " is an external user. Please select someone else.";
          const personError = newPerson.displayName + cannedMessage;
          switch (target) {
            case "requestor":
              setChangeRequest({ ...changeRequest, requestorSysID: undefined });
              setRequestorError(personError);
              break;
            case "approver":
              setChangeRequest({ ...changeRequest, approverSysID: undefined });
              setApproverError(personError);
              break;
          }
        }
      } else {
        //we removed a person
        switch (target) {
          case "requestor":
            setChangeRequest({
              ...changeRequest,
              requestorSysID: undefined,
              requestorAzureID: undefined,
              requestorName: undefined,
            });
            setRequestorError(undefined);
            break;
          case "approver":
            setChangeRequest({
              ...changeRequest,
              approverSysID: undefined,
              approverAzureID: undefined,
              approverName: undefined,
            });
            setApproverError(undefined);
        }
      }
    }
  };

  //no change request loaded yet. Let's get it from the props, or create a fresh one.
  if (changeRequest === undefined) {
    //we have a change request, load it!
    if (props.change !== undefined) {
      setChangeRequest(props.change);
      return <div>Loading form...</div>;
    } else {
      if (newForm) {
        var newChange = new ChangeRequest();
        newChange.projectId = projectID;
        newChange.changeRequestName = "[New change request]";
        newChange.dateRequested = new Date();
        newChange.requiredBy = new Date();
        setChangeRequest(newChange);
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No change request supplied, but it's not a new form? Did we forget a
            prop?
          </div>
        );
      }
    }
  } /// we have the cr
  else {
    const saveAndClose = async () => {
      if (!validChangeRequest(changeRequest)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        await SaveChangeRequest(changeRequest, projectID, newForm);
        setNewForm(false);
        props.closeAction();
      }
    };
    const closePopup = () => {
      props.closeAction();
    };

    return (
      <div className="carnaForm" data-testid="change-request-form">
        <Form>
          <h1>
            <TextArea
              id="changeRequestName"
              key="changeRequestName"
              value={changeRequest.changeRequestName}
              onChange={onChangeTextArea}
              className="titleField"
              autoFocus={newForm}
              onFocus={onFocusSelectAll}
              fluid
              rows={1}
              readOnly={!userRole.canEdit || isClosed}
              maxLength={250}
            />
            <CharactersRemaining
              value={changeRequest.changeRequestName}
              maxLength={250}
            />
          </h1>
          <div className="errorText">
            {showErrors
              ? !changeRequest.changeRequestName
                ? "Name is required"
                : undefined
              : undefined}
          </div>
          <span onClick={closePopup} className="close-button topright">
            X
          </span>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> CR # <Required theme={themeCss} />
              </div>
              <TextArea
                id="changeRequestNum"
                key="changeRequestNum"
                value={changeRequest.changeRequestNum}
                onChange={onChangeTextArea}
                fluid
                rows={1}
                readOnly={!userRole.canEdit || isClosed}
                maxLength={100}
              />
              <CharactersRemaining
                value={changeRequest.changeRequestNum}
                maxLength={100}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.changeRequestNum
                    ? "CR # is required"
                    : undefined
                  : undefined}
              </div>
            </div>

            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Date Requested{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="dateRequested"
                key="dateRequested"
                selectedDate={new Date(changeRequest.dateRequested)}
                onDateChange={onChangeDateRequested}
                disabled={!userRole.canEdit || isClosed}
              />
              <div>
                {showErrors
                  ? !validDate(changeRequest.dateRequested)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <Person16Regular /> Requestor <Required theme={themeCss} />
              </div>
              <PeoplePicker
                id="requestor"
                key="requestor"
                aria-required
                className={themeCss}
                defaultSelectedUserIds={
                  changeRequest.requestorAzureID
                    ? [changeRequest.requestorAzureID]
                    : undefined
                }
                selectionMode="single"
                selectionChanged={handlePersonChange}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {requestorError
                  ? requestorError
                  : showErrors
                  ? !changeRequest.requestorAzureID
                    ? "You must select a requestor"
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Change Request Type{" "}
                <Required theme={themeCss} />
              </div>
              <Dropdown
                id="changeRequestType"
                key="changeRequestType"
                items={getPicklistKeys(picklists, "crd5f_crtype")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_crtype",
                  changeRequest.changeRequestType
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                aria-required
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.changeRequestType
                    ? "CR Type is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Description of Change{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="description"
                key="description"
                value={changeRequest.description}
                onChange={onChangeTextArea}
                autoFocus={!newForm}
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={changeRequest.description}
                maxLength={2000}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.description
                    ? "Description is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Options{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="options"
                key="options"
                value={changeRequest.options}
                onChange={onChangeTextArea}
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={changeRequest.options}
                maxLength={2000}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.options
                    ? "Options is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Requested Change{" "}
                <Required theme={themeCss} />
              </div>
              <TextArea
                id="requestedChange"
                key="requestedChange"
                value={changeRequest.requestedChange}
                onChange={onChangeTextArea}
                fluid
                rows={6}
                resize="vertical"
                readOnly={!userRole.canEdit || isClosed}
                maxLength={2000}
              />
              <CharactersRemaining
                value={changeRequest.requestedChange}
                maxLength={2000}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.requestedChange
                    ? "Requested Change is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <TextChangeCase16Regular /> Required By{" "}
                <Required theme={themeCss} />
              </div>
              <Datepicker
                id="requiredBy"
                key="requiredBy"
                selectedDate={
                  changeRequest.requiredBy
                    ? new Date(changeRequest.requiredBy)
                    : undefined
                }
                onDateChange={onChangeRequiredBy}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !validDate(changeRequest.requiredBy)
                    ? "Please enter a valid date."
                    : undefined
                  : undefined}
              </div>
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Status <Required theme={themeCss} />
              </div>
              <Dropdown
                id="status"
                key="status"
                items={getPicklistKeys(picklists, "crd5f_crstatus")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_crstatus",
                  changeRequest.status
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                aria-required
                onChange={onChangeDDL}
                disabled={!userRole.canEdit || isClosed}
              />
              <div className="errorText">
                {showErrors
                  ? !changeRequest.status
                    ? "Status is required"
                    : undefined
                  : undefined}
              </div>
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColFull">
              <div className="fieldLabel">
                <Person16Regular /> Approver
              </div>
              <PeoplePicker
                id="approver"
                key="approver"
                className={themeCss}
                defaultSelectedUserIds={
                  changeRequest.approverAzureID
                    ? [changeRequest.approverAzureID]
                    : undefined
                }
                selectionMode="single"
                selectionChanged={handlePersonChange}
                disabled={!userRole.canEdit || isClosed}
              />
              {approverError ? approverError : undefined}
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">$ Increase In Cost To Project</div>
              <CurrencyInput
                id="increaseInCost"
                defaultValue={changeRequest.increaseInCost}
                allowDecimals={false}
                prefix="$"
                className={themeCss + " currency"}
                onValueChange={(val) =>
                  handleMoneyChange("increaseInCost", val)
                }
                value={changeRequest.increaseInCost}
                onFocus={onFocusSelectAll}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Funding Source
              </div>
              <Dropdown
                id="fundingSource"
                key="fundingSource"
                items={getPicklistKeys(picklists, "crd5f_fundingsource")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_fundingsource",
                  changeRequest.fundingSource
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
          </div>
          <div className="carnaRow">
            <div className="carnaColHalf">
              <div className="fieldLabel">
                $ Amount Of Additional Funds Required
              </div>
              <CurrencyInput
                id="additionalFundsRequired"
                defaultValue={changeRequest.additionalFundsRequired}
                allowDecimals={false}
                prefix="$"
                className={themeCss + " currency"}
                onValueChange={(val) =>
                  handleMoneyChange("additionalFundsRequired", val)
                }
                value={changeRequest.additionalFundsRequired}
                onFocus={onFocusSelectAll}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
            <div className="carnaColHalf">
              <div className="fieldLabel">
                <List16Regular /> Approval Received
              </div>
              <Dropdown
                id="approvalReceived"
                key="approvalReceived"
                items={getPicklistKeys(picklists, "crd5f_approvalreceived")}
                defaultValue={defaultPicklistKey(
                  picklists,
                  "crd5f_approvalreceived",
                  changeRequest.approvalReceived
                )}
                placeholder="Select an option..."
                className="ms-Dropdown-caretDown"
                onChange={onChangeDDL}
                disabled={!userRole.canEdit || isClosed}
              />
            </div>
          </div>
          {changeRequest ? (
            <div>
              <AttachmentsView
                project={projectID}
                entity="changerequest"
                entityId={changeRequest.changeRequestId}
                disabled={newForm}
              />
            </div>
          ) : undefined}

          {userRole.canEdit && !isClosed ? (
            <div style={{ float: "left" }}>
              <Button content="Save Change Request" onClick={saveAndClose} />
              &nbsp;
              <Button content="Cancel" onClick={closePopup} />
            </div>
          ) : (
            <Button content="Close Popup" onClick={closePopup} />
          )}
        </Form>
      </div>
    );
  }
};

function validChangeRequest(change: ChangeRequest): boolean {
  var valid: boolean = false;
  if (
    [
      change.changeRequestName,
      change.changeRequestNum,
      validDate(change.dateRequested),
      change.requestorSysID,
      change.changeRequestType,
      change.description,
      change.options,
      change.requestedChange,
      validDate(change.requiredBy),
      change.status,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectChangeRequest;
