import { useState, useCallback } from "react";
import { Button, Dialog, Dropdown } from "@fluentui/react-northstar";
import { CouncilReportPDFLink } from "../councilReport/CouncilReportPDFLink";
import "./PrintQuarterlyReport.css";

const quarters = [
  {
    name: "Q1",
    startMonth: 10,
    endMonth: 12,
  },
  {
    name: "Q2",
    startMonth: 1,
    endMonth: 3,
  },
  {
    name: "Q3",
    startMonth: 4,
    endMonth: 6,
  },
  {
    name: "Q4",
    startMonth: 7,
    endMonth: 9,
  },
];

export interface IQuarter {
  header: string;
  quarterStartDate: Date;
  quarterEndDate: Date;
  fiscalYearStartDate: Date;
  fiscalYearEndDate: Date;
}

const getFiscalYearText = (fiscalYearEnd: number) => {
  return `${(fiscalYearEnd - 1).toString().substring(2)}/${fiscalYearEnd
    .toString()
    .substring(2)}`;
};

const PrintQuarterlyReport: React.FC<{}> = () => {
  const [isCouncilReportDialogOpen, setIsCouncilReportDialogOpen] =
    useState<boolean>(false);
  const [selectedQuarter, setSelectedQuarter] = useState<any>();
  const now = new Date();
  // js months start at 0
  const currentMonth = now.getMonth() + 1;
  let currentQuarterIndex = quarters.findIndex(
    (x) => x.startMonth <= currentMonth && x.endMonth >= currentMonth
  );

  let currentYear = now.getFullYear();
  let fiscalYearEnd = currentYear;

  const currentQuarter = quarters[currentQuarterIndex];
  if (currentQuarter.name === "Q1") {
    fiscalYearEnd = currentYear + 1;
  }

  let quartersToDisplay: IQuarter[] = [];
  while (quartersToDisplay.length < 4) {
    quartersToDisplay.push({
      header: `${quarters[currentQuarterIndex].name} FY ${getFiscalYearText(
        fiscalYearEnd
      )}`,
      quarterStartDate: new Date(
        quarters[currentQuarterIndex].name === "Q1"
          ? currentYear
          : currentYear + 1,
        quarters[currentQuarterIndex].startMonth - 1,
        1
      ),
      // setting day to zero rolls back a day giving the last day of the month
      quarterEndDate: new Date(
        quarters[currentQuarterIndex].name === "Q1"
          ? currentYear
          : currentYear + 1,
        quarters[currentQuarterIndex].endMonth,
        0
      ),
      fiscalYearStartDate: new Date(fiscalYearEnd - 1, 9, 1),
      fiscalYearEndDate: new Date(fiscalYearEnd, 8, 30),
    });

    if (quarters[currentQuarterIndex].name === "Q1") {
      currentYear -= 1;
      fiscalYearEnd -= 1;
    }

    currentQuarterIndex -= 1;
    if (currentQuarterIndex < 0) {
      currentQuarterIndex = quarters.length - 1;
    }
  }

  const onChangeQuarter = useCallback((e: any, ddl: any) => {
    setSelectedQuarter(ddl.value);
  }, []);

  //populated project list
  return (
    <div className="print-quarterly-report">
      <Dropdown
        id="quarter"
        key="quarter"
        items={quartersToDisplay}
        placeholder="Select Quarter"
        className="ms-Dropdown-caretDown"
        onChange={onChangeQuarter}
      />
      <Button
        content="Print Quarterly Report"
        onClick={() => setIsCouncilReportDialogOpen(true)}
        disabled={selectedQuarter === undefined}
      />
      <Dialog
        header="Print quarterly status report"
        confirmButton="Done"
        content={<CouncilReportPDFLink quarter={selectedQuarter} />}
        onConfirm={() => setIsCouncilReportDialogOpen(false)}
        onCancel={() => setIsCouncilReportDialogOpen(false)}
        open={isCouncilReportDialogOpen}
      />
    </div>
  );
};

export default PrintQuarterlyReport;
