export default class ChangeRequest {
    changeRequestId!: string;
    projectId!: string;
    changeRequestName!: string;
    changeRequestNum?: string;

    requestorName?: string;
    requestorSysID?: string;
    requestorAzureID?: string;

    dateRequested!: Date;
    changeRequestType!: number;
    description?: string;
    options?: string;
    requestedChange?: string;

    approverName?: string;
    approverSysID?: string;
    approverAzureID?: string;

    requiredBy?: Date;
    status?: number;

    increaseInCost?: number;
    fundingSource?: number;
    additionalFundsRequired?: number;
    approvalReceived?: number;

}