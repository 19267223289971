import jwtDecode, { JwtPayload } from "jwt-decode";
import { AccessToken, GetTokenOptions, TokenCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import { Providers } from "@microsoft/mgt-element";
import ProjectAttachment from "../objects/models/ProjectAttachment";
import fileDownload from "js-file-download";

//A function that connects to the Azure Storage container and returns the ContainerClient object.
//This is called by all functions before doing operations.
function connectToAzureStorage() {
  // Implement the TokenCredential interface, which contains a function called getToken()
  const myTokenCredential: TokenCredential = {
    getToken: async (
      scopes: string | string[],
      options?: GetTokenOptions
    ): Promise<AccessToken | null> => {
      // The following code is the same as what we use to retrieve tokens for Graph API, and the Dataverse
      const prov = Providers.globalProvider;
      const msaltoken = await prov.getAccessToken({
        scopes: ["https://storage.azure.com/user_impersonation"],
      });

      // This will allow us to crack open the acquired token, to get the expiry date claim.
      const decoded = jwtDecode<JwtPayload>(msaltoken);

      // Build a token containing the complete token, plus the extracted expiry date claim.
      const token: AccessToken = {
        token: msaltoken,
        expiresOnTimestamp: decoded.exp!,
      };
      return token;
    },
  };

  ///////////////replace InteractiveBrowserCredential with the new technique///////////////////////////////////////////
  const blobStorageClient = new BlobServiceClient(
    "https://" +
      process.env.REACT_APP_AZURE_STORAGE_ENVIRONMENT +
      ".blob.core.windows.net/",
    myTokenCredential
  );

  var containerClient = blobStorageClient.getContainerClient(
    process.env.REACT_APP_AZURE_STORAGE_CONTAINER!
  );
  return containerClient;
}

export async function listAttachments(
  project: string,
  entity: string,
  entityId: string
) {
  const containerClient = connectToAzureStorage();
  var prefix = project + "/" + entity + "/" + entityId + "/";
  let iter = containerClient.listBlobsByHierarchy("/", { prefix: prefix });
  var attachmentList: ProjectAttachment[] = [];

  for await (const blob of iter) {
    var attach: ProjectAttachment = new ProjectAttachment();
    attach.fullName = blob.name;
    attach.friendlyName = getFriendlyName(
      project,
      entity,
      entityId,
      attach.fullName
    );
    attach.url = containerClient.url + blob.name;
    attach.filetype = getFileExtension(blob.name);
    attachmentList.push(attach);
  }

  return attachmentList;
}

export async function downloadAttachment(attachment: ProjectAttachment) {
  const containerClient = connectToAzureStorage();
  const blobClient = containerClient.getBlobClient(attachment.fullName);
  const downloadBlockBlobResponse = await blobClient.download(0);
  const downloaded: Blob = await downloadBlockBlobResponse.blobBody!;
  fileDownload(downloaded, attachment.friendlyName);
  return;
}

export async function deleteAttachment(attachment: ProjectAttachment) {
  const containerClient = connectToAzureStorage();
  await containerClient.deleteBlob(attachment.fullName);
  return;
}

export async function uploadAttachment(
  project: string,
  entity: string,
  entityId: string,
  attachment: File
) {
  const blobName =
    project + "/" + entity + "/" + entityId + "/" + attachment.name;
  const containerClient = connectToAzureStorage();
  const blobClient = containerClient.getBlockBlobClient(blobName);
  await blobClient.upload(attachment, attachment.size);
  return;
}

function getFriendlyName(
  project: string,
  entity: string,
  entityId: string,
  fullName: string
) {
  const fullPath = project + "/" + entity + "/" + entityId + "/";
  const friendlyName = fullName.replace(fullPath, "");
  return friendlyName;
}

function getFileExtension(filename: string) {
  if (filename.indexOf(".") > -1) {
    return filename.split(".").pop();
  } else {
    return undefined;
  }
}
