import {
  WordColorIcon,
  ExcelColorIcon,
  PowerPointColorIcon,
  FilesImageIcon,
  FilesZipIcon,
  EmailIcon,
  FilesGenericColoredIcon,
  FilesPdfIcon,
} from "@fluentui/react-icons-northstar";

export default class ProjectAttachment {
  fullName!: string;
  friendlyName!: string;
  url!: string;
  filetype?: string;

  icon() {
    if (!this.filetype) {
      return undefined;
    } else {
      switch (this.filetype.toLowerCase()) {
        case "pdf":
          return <FilesPdfIcon />
        case "docx":
          return <WordColorIcon />;
        case "doc":
          return <WordColorIcon />;
        case "xlsx":
          return <ExcelColorIcon />;
        case "xls":
          return <ExcelColorIcon />;
        case "pptx":
          return <PowerPointColorIcon />;
        case "ppt":
          return <PowerPointColorIcon />;
        case "zip":
          return <FilesZipIcon />;
        case "7z":
          return <FilesZipIcon />;
        case "eml":
          return <EmailIcon />;
        case "jpg":
          return <FilesImageIcon />;
        case "jpeg":
          return <FilesImageIcon />;
        case "gif":
          return <FilesImageIcon />;
        case "png":
          return <FilesImageIcon />;
        case "bmp":
          return <FilesImageIcon />;
        default:
          return <FilesGenericColoredIcon />;
      }
    }
  }
}
