import {
    Text,
    View,
    StyleSheet,
    Image,
  } from "@react-pdf/renderer";
import logo from "../../resources/logo.png";
import { IQuarter } from "./PrintQuarterlyReport";

export interface ICouncilReportHeaderProps {
  quarter: IQuarter;
}

// Create styles
const styles = StyleSheet.create({
  headerPadding: {
    paddingBottom: 35
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    position: "absolute",
    backgroundColor: "#111921",
    width: "99%",    
    paddingHorizontal: "20px",
    paddingVertical: "20px",
    left: 0,
    top: 0,    
  },
  headerImage: {
    width: "80px",
    height: "20px",
  },
  collegeText: {    
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "6px",
    fontSize: "6px",
  },
  portfolioStatus: {
    position: "absolute",
    top: 10,
    right: 30,
    color: "white",
    fontSize: 16,
  },
  headerBottom: {
    position: "absolute",
    top: 200,
    left: 0,   
  },
  fiscalYearText: {
    fontFamily: "MontserratLight",
    fontSize: 16,
    fontWeight: "normal"
  },
  teal: {
    color: "#1d988c",
  },
  boldThis: {
    fontFamily: "MontserratSemiBold",
  },
});
  
  export const CouncilStatusReportHeader: React.FC<ICouncilReportHeaderProps> = ({ quarter }) => {
    return (
      <>
        <View style={styles.header} fixed>
          <View style={styles.headerImage}>
            <Image source={logo} />
          </View>
          <View style={[styles.collegeText, styles.teal, styles.boldThis]}>
            <Text>College of Registered</Text>
            <Text>Nurses of Alberta</Text>
          </View>          
        </View>  
        <View style={[styles.portfolioStatus, styles.boldThis]}>
          <Text>Portfolio Status</Text>
          <Text style={[styles.fiscalYearText, styles.teal]}>{quarter.header}</Text>
        </View>         
        <View fixed style={styles.headerPadding}></View>
      </>
  );
};
