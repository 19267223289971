import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";

import {
  callDataverse,
  updateDataverse,
  insertToDataverse,
  DataverseApiUri,
  deleteFromDataverse,
} from "./DataverseApiCall";
import { getGraphUserById, getMeFromGraph } from "./MsGraphApiCall";

import { ProjectListDTO, ProjectDetailsDTO } from "../objects/DTOs/ProjectDTOs";

import { crd5f_projectmilestone } from "../objects/entities/crd5f_projectmilestone";
import { crd5f_projectinvoice } from "../objects/entities/crd5f_projectinvoice";
import ProjectChangeDTO from "../objects/DTOs/ProjectChangeDTO";
import ProjectNotesDTO from "../objects/DTOs/ProjectNoteDTO";
import ProjectRiskDTO from "../objects/DTOs/ProjectRiskDTO";
import ProjectBenefitsDTO from "../objects/DTOs/ProjectBenefitDTO";
import ProjectDecisionDTO from "../objects/DTOs/ProjectDecisionDTO";
import ProjectStatusReportDTO from "../objects/DTOs/ProjectStatusReportDTO";

import { PicklistValuesDTO } from "../objects/DTOs/PicklistValueDTO";
import { PicklistValues, Picklist } from "../objects/models/PicklistValues";

import Project from "../objects/models/Project";
import StatusReport from "../objects/models/StatusReport";
import ProjectNote from "../objects/models/ProjectNote";
import Milestone from "../objects/models/Milestone";
import Risk from "../objects/models/Risk";
import Benefit from "../objects/models/Benefit";
import Invoice from "../objects/models/Invoice";
import Decision from "../objects/models/Decision";
import ChangeRequest from "../objects/models/ChangeRequest";

import { crd5f_overallhealthEnum as ProjectHealth, crd5f_projectstatusEnum as ProjectStatus } from "../objects/entities/crd5f_project";
import { crd5f_overallhealthstatusEnum as StatusHealth } from "../objects/entities/crd5f_projectstatusreport";
import { defaultPicklistKey } from "../component/project/projectUtils";
import { crd5f_projectprogram } from "../objects/entities/crd5f_program";
import ProjectProgram from "../objects/models/ProjectProgram";
var dateFormat = require("dateformat");

export async function getPicklistValues(
  entity: string
): Promise<PicklistValues> {
  const queryPath =
    DataverseApiUri +
    "EntityDefinitions(LogicalName='" +
    entity +
    "')/Attributes/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet,GlobalOptionSet";

  const result = await callDataverse(queryPath);
  const pickListResult = result.value as PicklistValuesDTO[];

  const lists: Picklist[] = [];
  var keys: any[];

  pickListResult.forEach((list) => {
    keys = [];
    list.OptionSet.Options.forEach((option) => {
      keys.push({
        key: option.Value,
        text: option.Label.UserLocalizedLabel.Label,
      });
    });
    lists.push({
      LogicalName: list.LogicalName,
      Keys: keys,
    });
  });
  const picklistValues: PicklistValues = {
    Lists: lists,
  };

  return picklistValues;
}

export async function getUserFromGraph(
  domainname: string
): Promise<MicrosoftGraph.User | undefined> {
  const reply = await getGraphUserById(domainname);
  const result = reply.value as MicrosoftGraph.User;
  return result;
}

export async function getCurrentUser(): Promise<
  MicrosoftGraph.User | undefined
> {
  const reply = await getMeFromGraph();
  const result = reply as MicrosoftGraph.User;
  return result;
}

interface SystemUserID {
  systemuserid?: string;
}

export async function VerifyPersonInDataverse(azureADString: string) {
  const queryPath =
    DataverseApiUri +
    "systemusers?" +
    "$select=systemuserid" +
    "&$filter=((azureactivedirectoryobjectid eq " +
    azureADString +
    "))";

  const dvResult = await callDataverse(queryPath);
  const result = dvResult.value as SystemUserID[];
  if (result && result.length > 0) {
    return result[0].systemuserid;
  }
  return undefined;
}

export async function GetProjectList(): Promise<Project[]> {
  //get the projects

  const queryPath =
    DataverseApiUri +
    "crd5f_projects" +
    "?$select=crd5f_projectid,crd5f_projectname,crd5f_acronym,crd5f_startdate,crd5f_enddate,crd5f_overallhealth" +
    "&$expand=owninguser($select=fullname,domainname,azureactivedirectoryobjectid)&$orderby=crd5f_projectname";

  //fetch the data to a result
  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let projectDTO = dvResult.value as ProjectListDTO[];
  //map the result to our Project Object
  let projectList: Project[] =
    projectDTO === undefined || projectDTO === null
      ? []
      : projectDTO.map((x) => {
          return {
            projectId: x.crd5f_projectid,
            projectName: x.crd5f_projectname,
            acronym: x.crd5f_acronym,
            startDate: new Date(x.crd5f_startdate),
            endDate: new Date(x.crd5f_enddate),
            overallHealth: x.crd5f_overallhealth ?? null,
            projectManagerDomainname: x.owninguser?.domainname,
            projectManagerName: x.owninguser?.fullname,
            projectManagerAzureId: x.owninguser?.azureactivedirectoryobjectid,
          };
        });
  return projectList;

  //const unique = projectList.map(item => item.projectManagerDomainname).filter((value, index, self) => self.indexOf(value) === index)
}

export async function GetActiveProjects(startDate: Date, endDate: Date) {
  const formattedStartDate = dateFormat(startDate, "yyyy-mm-dd");
  const formattedEndDate = dateFormat(endDate, "yyyy-mm-dd");

  const queryPath =
  DataverseApiUri +
  "crd5f_projects?" +
  "$select=crd5f_projectid,crd5f_projectname,crd5f_acronym,crd5f_code,crd5f_strategicalignment,crd5f_investmenttype," +
  "crd5f_classification,crd5f_projectstatus,_crd5f_projectprogram_value,crd5f_programnumber,crd5f_valuestatement," +
  "_crd5f_executivesponsor_value,_crd5f_projectsponsor_value,_owninguser_value,_crd5f_changemanager_value,crd5f_startdate,crd5f_enddate," +
  "crd5f_approvedbudget,crd5f_spenttodateopex,crd5f_approvedfiscalbudgetopex,crd5f_spentytdopex," +
  "crd5f_forecastopex,crd5f_forecastnextyearopex,crd5f_forecastyear3opex,crd5f_finalprojectcostopex," +
  "crd5f_approvedbudgetcapex,crd5f_spenttodatecapex,crd5f_approvedfiscalbudgetcapex,crd5f_spentytdcapex," +
  "crd5f_forecastcapex,crd5f_forecastnextyearcapex,crd5f_forecastyear3capex,crd5f_finalprojectcostcapex," +
  "crd5f_adjustedbudgetcapex,crd5f_adjustedbudgetopex,crd5f_adjustedfiscalbudgetcapex,crd5f_adjustedfiscalbudgetopex" +
  "&$expand=crd5f_ExecutiveSponsor($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
  "crd5f_ProjectSponsor($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
  "owninguser($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
  "crd5f_ChangeManager($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)" +  
  `&$filter=(` +
  `(crd5f_startdate ge ${formattedStartDate} and crd5f_startdate le ${formattedEndDate})` +
  ` or ` +
  `(crd5f_enddate ge ${formattedStartDate} and crd5f_enddate le ${formattedEndDate})` +
  ` or ` +
  `(crd5f_startdate le ${formattedStartDate} and crd5f_enddate ge ${formattedEndDate})` +
  `)`;

  //fetch the data to a result
  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let projectDTO = dvResult.value as ProjectDetailsDTO[];
  //map the result to our Project Object
  let projectList: Project[] =
    projectDTO === undefined || projectDTO === null
      ? []
      : projectDTO.map((x) => {
          return {
            projectId: x.crd5f_projectid,
            projectName: x.crd5f_projectname,
            acronym: x.crd5f_acronym,
            projectCode: x.crd5f_code,
            strategicAlignment: x.crd5f_strategicalignment,
            investmentType: x.crd5f_investmenttype,
            classification: x.crd5f_classification,
            projectStatus: x.crd5f_projectstatus,
            program: x._crd5f_projectprogram_value,
            programNumber: x.crd5f_programnumber,
            valueStatement: x.crd5f_valuestatement,
            startDate: new Date(x.crd5f_startdate),
            endDate: new Date(x.crd5f_enddate),

            projectManagerName: x.owninguser?.fullname,
            executiveSponsorName: x.crd5f_ExecutiveSponsor?.fullname,
            projectSponsorName: x.crd5f_ProjectSponsor?.fullname,
            changeManagerName: x.crd5f_ChangeManager?.fullname,
            projectManagerDomainname: x.owninguser?.domainname,
            executiveSponsorDomainname: x.crd5f_ExecutiveSponsor?.domainname,
            projectSponsorDomainname: x.crd5f_ProjectSponsor?.domainname,
            changeManagerDomainname: x.crd5f_ChangeManager?.domainname,
            projectManagerSysId: x.owninguser?.systemuserid,
            executiveSponsorSysId: x.crd5f_ExecutiveSponsor?.systemuserid,
            projectSponsorSysId: x.crd5f_ProjectSponsor?.systemuserid,
            changeManagerSysId: x.crd5f_ChangeManager?.systemuserid,
            projectManagerAzureId: x.owninguser?.azureactivedirectoryobjectid,
            executiveSponsorAzureId:
              x.crd5f_ExecutiveSponsor?.azureactivedirectoryobjectid,
            projectSponsorAzureId:
              x.crd5f_ProjectSponsor?.azureactivedirectoryobjectid,
            changeManagerAzureId:
              x.crd5f_ChangeManager?.azureactivedirectoryobjectid,

            o_ApprovedBudget: x.crd5f_approvedbudget,
            o_spendToDate: x.crd5f_spenttodateopex,
            o_approvedFiscal: x.crd5f_approvedfiscalbudgetopex,
            o_spendYTD: x.crd5f_spentytdopex,
            o_forecastAtCompletion: x.crd5f_forecastopex,
            o_forecastNextYear: x.crd5f_forecastnextyearopex,
            o_forecastYearThree: x.crd5f_forecastyear3opex,
            o_AdjustedBudget: x.crd5f_adjustedbudgetopex,
            o_AdjustedFiscalBudget: x.crd5f_adjustedfiscalbudgetopex,
            o_FinalProjectCost: x.crd5f_finalprojectcostopex,

            c_ApprovedBudget: x.crd5f_approvedbudgetcapex,
            c_spendToDate: x.crd5f_spenttodatecapex,
            c_approvedFiscal: x.crd5f_approvedfiscalbudgetcapex,
            c_spendYTD: x.crd5f_spentytdcapex,
            c_forecastAtCompletion: x.crd5f_forecastcapex,
            c_forecastNextYear: x.crd5f_forecastnextyearcapex,
            c_forecastYearThree: x.crd5f_forecastyear3capex,
            c_AdjustedBudget: x.crd5f_adjustedbudgetcapex,
            c_AdjustedFiscalBudget: x.crd5f_adjustedfiscalbudgetcapex,
            c_FinalProjectCost: x.crd5f_finalprojectcostcapex,
          };
        });
  return projectList;

  //const unique = projectList.map(item => item.projectManagerDomainname).filter((value, index, self) => self.indexOf(value) === index)
}

/* #region Project Details */
export async function GetProject(projectID: string): Promise<Project> {
  const queryPath =
    DataverseApiUri +
    "crd5f_projects?" +
    "$select=crd5f_projectid,crd5f_projectname,crd5f_acronym,crd5f_code,crd5f_strategicalignment,crd5f_investmenttype," +
    "crd5f_classification,crd5f_projectstatus,_crd5f_projectprogram_value,crd5f_programnumber,crd5f_valuestatement," +
    "_crd5f_executivesponsor_value,_crd5f_projectsponsor_value,_owninguser_value,_crd5f_changemanager_value,crd5f_startdate,crd5f_enddate," +
    "crd5f_approvedbudget,crd5f_spenttodateopex,crd5f_approvedfiscalbudgetopex,crd5f_spentytdopex," +
    "crd5f_forecastopex,crd5f_forecastnextyearopex,crd5f_forecastyear3opex,crd5f_finalprojectcostopex," +
    "crd5f_approvedbudgetcapex,crd5f_spenttodatecapex,crd5f_approvedfiscalbudgetcapex,crd5f_spentytdcapex," +
    "crd5f_forecastcapex,crd5f_forecastnextyearcapex,crd5f_forecastyear3capex,crd5f_finalprojectcostcapex," +
    "crd5f_adjustedbudgetcapex,crd5f_adjustedbudgetopex,crd5f_adjustedfiscalbudgetcapex,crd5f_adjustedfiscalbudgetopex" +
    "&$expand=crd5f_ExecutiveSponsor($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
    "crd5f_ProjectSponsor($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
    "owninguser($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)," +
    "crd5f_ChangeManager($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)" +
    "&$filter=(crd5f_projectid eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let projectDTO = dvResult.value[0] as ProjectDetailsDTO;

  //map the result to our Project Object
  let project: Project = {
    projectId: projectDTO.crd5f_projectid,
    projectName: projectDTO.crd5f_projectname,
    acronym: projectDTO.crd5f_acronym,
    projectCode: projectDTO.crd5f_code,
    strategicAlignment: projectDTO.crd5f_strategicalignment,
    investmentType: projectDTO.crd5f_investmenttype,
    classification: projectDTO.crd5f_classification,
    projectStatus: projectDTO.crd5f_projectstatus,
    program: projectDTO._crd5f_projectprogram_value,
    programNumber: projectDTO.crd5f_programnumber,
    valueStatement: projectDTO.crd5f_valuestatement,
    startDate: new Date(projectDTO.crd5f_startdate),
    endDate: new Date(projectDTO.crd5f_enddate),

    projectManagerName: projectDTO.owninguser?.fullname,
    executiveSponsorName: projectDTO.crd5f_ExecutiveSponsor?.fullname,
    projectSponsorName: projectDTO.crd5f_ProjectSponsor?.fullname,
    changeManagerName: projectDTO.crd5f_ChangeManager?.fullname,
    projectManagerDomainname: projectDTO.owninguser?.domainname,
    executiveSponsorDomainname: projectDTO.crd5f_ExecutiveSponsor?.domainname,
    projectSponsorDomainname: projectDTO.crd5f_ProjectSponsor?.domainname,
    changeManagerDomainname: projectDTO.crd5f_ChangeManager?.domainname,
    projectManagerSysId: projectDTO.owninguser?.systemuserid,
    executiveSponsorSysId: projectDTO.crd5f_ExecutiveSponsor?.systemuserid,
    projectSponsorSysId: projectDTO.crd5f_ProjectSponsor?.systemuserid,
    changeManagerSysId: projectDTO.crd5f_ChangeManager?.systemuserid,
    projectManagerAzureId: projectDTO.owninguser?.azureactivedirectoryobjectid,
    executiveSponsorAzureId:
      projectDTO.crd5f_ExecutiveSponsor?.azureactivedirectoryobjectid,
    projectSponsorAzureId:
      projectDTO.crd5f_ProjectSponsor?.azureactivedirectoryobjectid,
    changeManagerAzureId:
      projectDTO.crd5f_ChangeManager?.azureactivedirectoryobjectid,

    o_ApprovedBudget: projectDTO.crd5f_approvedbudget,
    o_spendToDate: projectDTO.crd5f_spenttodateopex,
    o_approvedFiscal: projectDTO.crd5f_approvedfiscalbudgetopex,
    o_spendYTD: projectDTO.crd5f_spentytdopex,
    o_forecastAtCompletion: projectDTO.crd5f_forecastopex,
    o_forecastNextYear: projectDTO.crd5f_forecastnextyearopex,
    o_forecastYearThree: projectDTO.crd5f_forecastyear3opex,
    o_AdjustedBudget: projectDTO.crd5f_adjustedbudgetopex,
    o_AdjustedFiscalBudget: projectDTO.crd5f_adjustedfiscalbudgetopex,
    o_FinalProjectCost: projectDTO.crd5f_finalprojectcostopex,

    c_ApprovedBudget: projectDTO.crd5f_approvedbudgetcapex,
    c_spendToDate: projectDTO.crd5f_spenttodatecapex,
    c_approvedFiscal: projectDTO.crd5f_approvedfiscalbudgetcapex,
    c_spendYTD: projectDTO.crd5f_spentytdcapex,
    c_forecastAtCompletion: projectDTO.crd5f_forecastcapex,
    c_forecastNextYear: projectDTO.crd5f_forecastnextyearcapex,
    c_forecastYearThree: projectDTO.crd5f_forecastyear3capex,
    c_AdjustedBudget: projectDTO.crd5f_adjustedbudgetcapex,
    c_AdjustedFiscalBudget: projectDTO.crd5f_adjustedfiscalbudgetcapex,
    c_FinalProjectCost: projectDTO.crd5f_finalprojectcostcapex,
  };
  return project;
}

export async function SaveProject(project: Project) {
  const queryPath =
    DataverseApiUri + "crd5f_projects(" + project.projectId + ")";

  let bodyObject:any = {
    crd5f_acronym: project.acronym,
    crd5f_code: project.projectCode,
    crd5f_strategicalignment: project.strategicAlignment,
    crd5f_investmenttype: project.investmentType,
    crd5f_classification: project.classification,
    crd5f_projectstatus: project.projectStatus,
    crd5f_programnumber: project.programNumber,
    crd5f_valuestatement: project.valueStatement,
    crd5f_startdate: project.startDate,
    crd5f_enddate: project.endDate,
    crd5f_approvedbudget: project.o_ApprovedBudget
      ? +project.o_ApprovedBudget
      : 0,
    crd5f_spenttodateopex: project.o_spendToDate ? +project.o_spendToDate : 0,
    crd5f_approvedfiscalbudgetopex: project.o_approvedFiscal
      ? +project.o_approvedFiscal
      : 0,
    crd5f_adjustedbudgetopex: project.o_AdjustedBudget
      ? +project.o_AdjustedBudget
      : 0,
    crd5f_adjustedfiscalbudgetopex: project.o_AdjustedFiscalBudget
      ? +project.o_AdjustedFiscalBudget
      : 0,
    crd5f_spentytdopex: project.o_spendYTD ? +project.o_spendYTD : 0,
    crd5f_forecastopex: project.o_forecastAtCompletion
      ? +project.o_forecastAtCompletion
      : 0,
    crd5f_forecastnextyearopex: project.o_forecastNextYear
      ? +project.o_forecastNextYear
      : 0,
    crd5f_forecastyear3opex: project.o_forecastYearThree
      ? +project.o_forecastYearThree
      : 0,
    crd5f_finalprojectcostopex: project.o_FinalProjectCost
      ? +project.o_FinalProjectCost
      : 0,
    crd5f_approvedbudgetcapex: project.c_ApprovedBudget
      ? +project.c_ApprovedBudget
      : 0,
    crd5f_adjustedbudgetcapex: project.c_AdjustedBudget
      ? +project.c_AdjustedBudget
      : 0,
    crd5f_adjustedfiscalbudgetcapex: project.c_AdjustedFiscalBudget
      ? +project.c_AdjustedFiscalBudget
      : 0,
    crd5f_spenttodatecapex: project.c_spendToDate ? +project.c_spendToDate : 0,
    crd5f_approvedfiscalbudgetcapex: project.c_approvedFiscal
      ? +project.c_approvedFiscal
      : 0,
    crd5f_spentytdcapex: project.c_spendYTD ? +project.c_spendYTD : 0,
    crd5f_forecastcapex: project.c_forecastAtCompletion
      ? +project.c_forecastAtCompletion
      : 0,
    crd5f_forecastnextyearcapex: project.c_forecastNextYear
      ? +project.c_forecastNextYear
      : 0,
    crd5f_forecastyear3capex: project.c_forecastYearThree
      ? +project.c_forecastYearThree
      : 0,
    crd5f_finalprojectcostcapex: project.c_FinalProjectCost
      ? +project.c_FinalProjectCost
      : 0,
    "crd5f_ChangeManager@odata.bind": project.changeManagerSysId
      ? "/systemusers(" + project.changeManagerSysId + ")"
      : null,
    "crd5f_ProjectSponsor@odata.bind": project.projectSponsorSysId
      ? "/systemusers(" + project.projectSponsorSysId + ")"
      : null,
    "crd5f_ExecutiveSponsor@odata.bind": project.executiveSponsorSysId
      ? "/systemusers(" + project.executiveSponsorSysId + ")"
      : null,
    "crd5f_ProjectProgram@odata.bind": project.program
      ? "/crd5f_projectprograms(" + project.program + ")"
      : null,
  };

  //override Project Health if the project is closing.
  if (project.projectStatus === ProjectStatus.Closed)
  {
    bodyObject = {...bodyObject, crd5f_overallhealth:ProjectHealth.Closed }
  }

  const body = JSON.stringify(bodyObject);
  await updateDataverse(queryPath, body);
  return;
}

//Takes in the overall health for the most recent status report, and returns
//the matching overall health for the project entity. They are separate choice fields.
async function fetchCorrectProjectHealth(
  statusHealth: number
): Promise<number | undefined> {
  //get the relevant picklists
  const projectPicklists = await getPicklistValues("crd5f_project");
  const statusPicklists = await getPicklistValues("crd5f_projectstatusreport");

  //find the matching status key
  const statusHealthKey = defaultPicklistKey(
    statusPicklists,
    "crd5f_overallhealthstatus",
    statusHealth
  );

  //find the project key based on status text
  const arrayObjects: { key: number; header: string }[] = [];
  const found = projectPicklists.Lists.find(
    (match) => match.LogicalName === "crd5f_overallhealth"
  );
  found?.Keys.forEach((key) => {
    arrayObjects.push({
      key: key.key,
      header: key.text,
    });
  });

  const projectHealthKey = arrayObjects.find(
    (match) => match.header === statusHealthKey?.header
  );
  if (projectHealthKey) return projectHealthKey.key;
}

//When we were saving a status report and it's now the most recent one,
//we need to update the Project's overall health.
async function updateProjectHealth(projectId: string, health: number) {
  const queryPath = DataverseApiUri + "crd5f_projects(" + projectId + ")";
  const bodyObject = {
    crd5f_overallhealth: health,
  };
  const body = JSON.stringify(bodyObject);
  await updateDataverse(queryPath, body);
  return;
}

/* #endregion */

/* #region Status Reports */
export async function GetStatusReports(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectstatusreports?" +
    "$select=crd5f_projectstatusreportid,_crd5f_project_value,crd5f_reportingperiod,crd5f_startdate,crd5f_enddate,crd5f_councilsummary," +
    "crd5f_overallhealthstatus,crd5f_schedulestatus,crd5f_budgetstatus,crd5f_resourcestatus,crd5f_milestonestatus,crd5f_changemanagementstatus," +
    "crd5f_projectstandardsmet,crd5f_approvedcrs,crd5f_riskshigh,crd5f_itemsforawareness,crd5f_changereadinessactivities,crd5f_corporatesummary" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let statusReportDTO = dvResult.value as ProjectStatusReportDTO[];
  //map the result to our Project Object
  let reportsList: StatusReport[] = statusReportDTO.map((x) => {
    return {
      statusReportId: x.crd5f_projectstatusreportid,
      owner: x.ownerid,
      project: x._crd5f_project_value,
      reportingPeriod: x.crd5f_reportingperiod,
      startDate: new Date(x.crd5f_startdate),
      endDate: new Date(x.crd5f_enddate),
      overallHealthStatus: x.crd5f_overallhealthstatus,
      scheduleStatus: x.crd5f_schedulestatus,
      budgetStatus: x.crd5f_budgetstatus,
      resourceStatus: x.crd5f_resourcestatus,
      milestoneStatus: x.crd5f_milestonestatus,
      changeMgmtStatus: x.crd5f_changemanagementstatus,
      projectStandardsMet: x.crd5f_projectstandardsmet,
      approvedCRs: x.crd5f_approvedcrs,
      risksHigh: x.crd5f_riskshigh,
      itemsForAwareness: x.crd5f_itemsforawareness,
      changeReadiness: x.crd5f_changereadinessactivities,
      corporateSummary: x.crd5f_corporatesummary,
      councilSummary: x.crd5f_councilsummary,
    };
  });

  return reportsList;
}

export async function GetSingleStatusReport(statusReportId: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectstatusreports?" +
    "$select=crd5f_projectstatusreportid,_crd5f_project_value,crd5f_reportingperiod,crd5f_startdate,crd5f_enddate,crd5f_councilsummary," +
    "crd5f_overallhealthstatus,crd5f_schedulestatus,crd5f_budgetstatus,crd5f_resourcestatus,crd5f_milestonestatus,crd5f_changemanagementstatus," +
    "crd5f_projectstandardsmet,crd5f_approvedcrs,crd5f_riskshigh,crd5f_itemsforawareness,crd5f_changereadinessactivities,crd5f_corporatesummary" +
    "&$filter=(crd5f_projectstatusreportid eq " +
    statusReportId +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let statusReportDTO = dvResult.value as ProjectStatusReportDTO[];
  //this should only return 1 row but the type is still an array
  let reportList: StatusReport[] = statusReportDTO.map((x) => {
    return {
      statusReportId: x.crd5f_projectstatusreportid,
      owner: x.ownerid,
      project: x._crd5f_project_value,
      reportingPeriod: x.crd5f_reportingperiod,
      startDate: new Date(x.crd5f_startdate),
      endDate: new Date(x.crd5f_enddate),
      overallHealthStatus: x.crd5f_overallhealthstatus,
      scheduleStatus: x.crd5f_schedulestatus,
      budgetStatus: x.crd5f_budgetstatus,
      resourceStatus: x.crd5f_resourcestatus,
      milestoneStatus: x.crd5f_milestonestatus,
      changeMgmtStatus: x.crd5f_changemanagementstatus,
      projectStandardsMet: x.crd5f_projectstandardsmet,
      approvedCRs: x.crd5f_approvedcrs,
      risksHigh: x.crd5f_riskshigh,
      itemsForAwareness: x.crd5f_itemsforawareness,
      changeReadiness: x.crd5f_changereadinessactivities,
      corporateSummary: x.crd5f_corporatesummary,
      councilSummary: x.crd5f_councilsummary,
    };
  });
  return reportList[0];
}

export async function GetStatusReportsInRange(startDate: Date, endDate: Date) {
  const formattedStartDate = dateFormat(startDate, "yyyy-mm-dd");
  const formattedEndDate = dateFormat(endDate, "yyyy-mm-dd");

  const queryPath =
    DataverseApiUri +
    "crd5f_projectstatusreports?" +
    "$select=crd5f_projectstatusreportid,_crd5f_project_value,crd5f_reportingperiod,crd5f_startdate,crd5f_enddate,crd5f_councilsummary," +
    "crd5f_overallhealthstatus,crd5f_schedulestatus,crd5f_budgetstatus,crd5f_resourcestatus,crd5f_milestonestatus,crd5f_changemanagementstatus," +
    "crd5f_projectstandardsmet,crd5f_approvedcrs,crd5f_riskshigh,crd5f_itemsforawareness,crd5f_changereadinessactivities,crd5f_corporatesummary" +
    "&$filter=(" +
    `(crd5f_startdate ge ${formattedStartDate} and crd5f_startdate le ${formattedEndDate})` +
    ` or ` +
    `(crd5f_enddate ge ${formattedStartDate} and crd5f_enddate le ${formattedEndDate})` +
    ` or ` +
    `(crd5f_startdate le ${formattedStartDate} and crd5f_enddate ge ${formattedEndDate})` +
    `)`;
    

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let statusReportDTO = dvResult.value as ProjectStatusReportDTO[];
  //map the result to our Project Object
  let reportsList: StatusReport[] = statusReportDTO.map((x) => {
    return {
      statusReportId: x.crd5f_projectstatusreportid,
      owner: x.ownerid,
      project: x._crd5f_project_value,
      reportingPeriod: x.crd5f_reportingperiod,
      startDate: new Date(x.crd5f_startdate),
      endDate: new Date(x.crd5f_enddate),
      overallHealthStatus: x.crd5f_overallhealthstatus,
      scheduleStatus: x.crd5f_schedulestatus,
      budgetStatus: x.crd5f_budgetstatus,
      resourceStatus: x.crd5f_resourcestatus,
      milestoneStatus: x.crd5f_milestonestatus,
      changeMgmtStatus: x.crd5f_changemanagementstatus,
      projectStandardsMet: x.crd5f_projectstandardsmet,
      approvedCRs: x.crd5f_approvedcrs,
      risksHigh: x.crd5f_riskshigh,
      itemsForAwareness: x.crd5f_itemsforawareness,
      changeReadiness: x.crd5f_changereadinessactivities,
      corporateSummary: x.crd5f_corporatesummary,
      councilSummary: x.crd5f_councilsummary,
    };
  });

  return reportsList;
}

export async function SaveStatusReport(
  report: StatusReport,
  project: string,
  newRecord: boolean
) {
  const startDate = new Date(report.startDate);
  const endDate = new Date(report.endDate);
  var reportingPeriod =
    startDate.toISOString().slice(0, 10) +
    " to " +
    endDate.toISOString().slice(0, 10);

  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    crd5f_reportingperiod: reportingPeriod,
    crd5f_startdate: startDate,
    crd5f_enddate: endDate,
    crd5f_overallhealthstatus: report.overallHealthStatus,
    crd5f_schedulestatus: report.scheduleStatus,
    crd5f_budgetstatus: report.budgetStatus,
    crd5f_resourcestatus: report.resourceStatus,
    crd5f_milestonestatus: report.milestoneStatus,
    crd5f_changemanagementstatus: report.changeMgmtStatus,
    crd5f_itemsforawareness: report.itemsForAwareness,
    crd5f_changereadinessactivities: report.changeReadiness,
    crd5f_corporatesummary: report.corporateSummary,
    crd5f_projectstandardsmet: report.projectStandardsMet,
    crd5f_councilsummary: report.councilSummary,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectstatusreports";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri +
      "crd5f_projectstatusreports" +
      "(" +
      report.statusReportId +
      ")";
    await updateDataverse(queryPath, body);
  }
  //Is this the latest status report? If so, update the Project's overall health.
  const latestStatusReport = await isMostRecentStatusReport(
    report.endDate,
    project
  );
  if (latestStatusReport) {
    const projectHealth = await fetchCorrectProjectHealth(
      report.overallHealthStatus
    );
    if (projectHealth) {
      await updateProjectHealth(project, projectHealth);
    }
  }
}

export async function DeleteStatusReport(reportId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectstatusreports" + "(" + reportId + ")";
  await deleteFromDataverse(queryPath);
}

//Fetches just the end dates for a project's status reports, and orders them descending.
//If our new value is higher (or equal to) the most recent status report, we have a new most recent one
//and will have to update the Project's health elsewhere.
async function isMostRecentStatusReport(
  reportDate: Date,
  projectId: string
): Promise<boolean> {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectstatusreports?$select=crd5f_enddate&$filter=(_crd5f_project_value eq " +
    projectId +
    ")&$orderby=crd5f_enddate desc";
  const dvResult = await callDataverse(queryPath);
  let endDateDTO = dvResult.value as { crd5f_enddate: Date }[];

  if (endDateDTO === undefined || endDateDTO === null) return false;
  else {
    const endDateCompare = new Date(endDateDTO[0].crd5f_enddate);
    //Find out if our new report is the latest one.
    return reportDate >= endDateCompare;
  }
}
/* #endregion */

/* #region Project Notes */
export async function GetProjectNotes(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectnotes" +
    "?$select=crd5f_description,crd5f_name,crd5f_projectnoteid,createdon" +
    "&$expand=createdby($select=domainname,fullname,azureactivedirectoryobjectid)" +
    "&$orderby=crd5f_projectnoteid desc" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let notesDTO = dvResult.value as ProjectNotesDTO[];
  //map the result to our Project Object
  let notesList: ProjectNote[] = notesDTO.map((x) => {
    return {
      noteId: x.crd5f_projectnoteid,
      projectId: projectID,
      createdDate: new Date(x.createdon),
      createdByName: x.createdby.fullname,
      createdByDomainName: x.createdby.domainname,
      createdByAzureID: x.createdby.azureactivedirectoryobjectid,
      noteName: x.crd5f_name,
      description: x.crd5f_description,
    };
  });

  return notesList;
}

export async function SaveProjectNote(
  note: ProjectNote,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    crd5f_name: note.noteName,
    crd5f_description: note.description,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectnotes";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri + "crd5f_projectnotes" + "(" + note.noteId + ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteProjectNote(noteId: string) {
  const queryPath = DataverseApiUri + "crd5f_projectnotes" + "(" + noteId + ")";
  await deleteFromDataverse(queryPath);
}

/* #endregion */

/* #region Project Milestone */
export async function GetMilestones(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectmilestones" +
    "?$select=crd5f_projectmilestoneid,crd5f_deliverabledate,_crd5f_project_value," +
    "crd5f_description,crd5f_name,crd5f_milestonestatus,crd5f_milestonetype" +
    "&$orderby=crd5f_deliverabledate&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let milestoneDTO = dvResult.value as crd5f_projectmilestone[];
  //map the result to our Project Object
  let milestoneList: Milestone[] = milestoneDTO.map((x) => {
    return {
      milestoneId: x.crd5f_projectmilestoneid,
      projectId: x.crd5f_project,
      name: x.crd5f_name,
      type: x.crd5f_milestonetype,
      deliverableDate: new Date(x.crd5f_deliverabledate),
      status: x.crd5f_milestonestatus,
      description: x.crd5f_description,
    };
  });

  return milestoneList;
}

export async function SaveMilestone(
  milestone: Milestone,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    crd5f_name: milestone.name,
    crd5f_milestonetype: milestone.type,
    crd5f_deliverabledate: milestone.deliverableDate,
    crd5f_milestonestatus: milestone.status,
    crd5f_description: milestone.description,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectmilestones";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri +
      "crd5f_projectmilestones" +
      "(" +
      milestone.milestoneId +
      ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteMilestone(milestoneId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectmilestones" + "(" + milestoneId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Project Risk */
export async function GetRisks(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectrisks" +
    "?$select=crd5f_projectriskid,_crd5f_project_value,crd5f_name,crd5f_description," +
    "crd5f_mitigationstrategy,crd5f_impact,crd5f_probability,crd5f_duedate,crd5f_riskstatus,crd5f_corporaterisknumber" +
    "&$expand=crd5f_AssignedTo($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let riskDTO = dvResult.value as ProjectRiskDTO[];
  //map the result to our Project Object
  let riskList: Risk[] = riskDTO.map((x) => {
    return {
      riskId: x.crd5f_projectriskid,
      projectId: x.crd5f_project,
      name: x.crd5f_name,
      description: x.crd5f_description,
      mitigationStrategy: x.crd5f_mitigationstrategy,
      impact: x.crd5f_impact,
      probability: x.crd5f_probability,
      dueDate: new Date(x.crd5f_duedate),
      status: x.crd5f_riskstatus,
      corporateRiskNum: x.crd5f_corporaterisknumber,

      assignedToName: x.crd5f_AssignedTo?.fullname,
      assignedToDomainName: x.crd5f_AssignedTo?.domainname,
      assignedToSysID: x.crd5f_AssignedTo?.systemuserid,
      assignedToAzureID: x.crd5f_AssignedTo?.azureactivedirectoryobjectid,
    };
  });

  return riskList;
}

export async function SaveRisk(
  risk: Risk,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",

    crd5f_name: risk.name,
    crd5f_description: risk.description,
    crd5f_mitigationstrategy: risk.mitigationStrategy,
    crd5f_impact: risk.impact,
    crd5f_probability: risk.probability,
    crd5f_duedate: risk.dueDate,
    crd5f_riskstatus: risk.status,
    crd5f_corporaterisknumber: risk.corporateRiskNum,

    "crd5f_AssignedTo@odata.bind": risk.assignedToSysID
      ? "/systemusers(" + risk.assignedToSysID + ")"
      : null,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectrisks";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri + "crd5f_projectrisks" + "(" + risk.riskId + ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteRisk(riskId: string) {
  const queryPath = DataverseApiUri + "crd5f_projectrisks" + "(" + riskId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Project Benefits */
export async function GetBenefits(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectbenefits" +
    "?$select=crd5f_projectbenefitid,_crd5f_project_value,crd5f_name,crd5f_declaration," +
    "crd5f_performancemeasure,crd5f_currentbaseline,crd5f_initiationdate,crd5f_reviewcycle" +
    "&$expand=crd5f_BusinessOwner($select=fullname,domainname,systemuserid,azureactivedirectoryobjectid)" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let benefitsDTO = dvResult.value as ProjectBenefitsDTO[];
  //map the result to our Project Object
  let benefitsList: Benefit[] = benefitsDTO.map((x) => {
    return {
      benefitId: x.crd5f_projectbenefitid,
      projectId: x.crd5f_project,
      name: x.crd5f_name,
      declaration: x.crd5f_declaration,
      performanceMeasure: x.crd5f_performancemeasure,
      currentBaseline: x.crd5f_currentbaseline,
      initiationDate: new Date(x.crd5f_initiationdate),
      reviewCycle: x.crd5f_reviewcycle,
      businessOwnerName: x.crd5f_BusinessOwner?.fullname,
      businessOwnerDomainName: x.crd5f_BusinessOwner?.domainname,
      businessOwnerSysID: x.crd5f_BusinessOwner?.systemuserid,
      businessOwnerAzureID: x.crd5f_BusinessOwner?.azureactivedirectoryobjectid,
    };
  });

  return benefitsList;
}

export async function SaveBenefit(
  benefit: Benefit,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    "crd5f_BusinessOwner@odata.bind": benefit.businessOwnerSysID
      ? "/systemusers(" + benefit.businessOwnerSysID + ")"
      : null,
    crd5f_name: benefit.name,
    crd5f_declaration: benefit.declaration,
    crd5f_performancemeasure: benefit.performanceMeasure,
    crd5f_currentbaseline: benefit.currentBaseline,
    crd5f_initiationdate: benefit.initiationDate,
    crd5f_reviewcycle: benefit.reviewCycle,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectbenefits";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri + "crd5f_projectbenefits" + "(" + benefit.benefitId + ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteBenefit(benefitId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectbenefits" + "(" + benefitId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Project Invoice */
export async function GetInvoices(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectinvoices" +
    "?$select=crd5f_projectinvoiceid,_crd5f_project_value,crd5f_name,crd5f_invoicedate," +
    "crd5f_invoiceamount,crd5f_invoicetype,crd5f_accountcode,crd5f_otherdetails" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let invoiceDTO = dvResult.value as crd5f_projectinvoice[];
  //map the result to our Project Object
  let invoiceList: Invoice[] = invoiceDTO.map((x) => {
    return {
      invoiceId: x.crd5f_projectinvoiceid,
      projectId: x.crd5f_project,
      invoiceNumber: x.crd5f_name,
      invoiceDate: new Date(x.crd5f_invoicedate),
      invoiceAmount: +x.crd5f_invoiceamount,
      invoiceType: x.crd5f_invoicetype,
      accountCode: x.crd5f_accountcode,
      otherDetails: x.crd5f_otherdetails,
    };
  });

  return invoiceList;
}

export async function SaveInvoice(
  invoice: Invoice,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    crd5f_name: invoice.invoiceNumber,
    crd5f_invoicedate: invoice.invoiceDate,
    crd5f_invoiceamount: invoice.invoiceAmount ? +invoice.invoiceAmount : 0,
    crd5f_invoicetype: invoice.invoiceType,
    crd5f_accountcode: invoice.accountCode,
    crd5f_otherdetails: invoice.otherDetails,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectinvoices";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri + "crd5f_projectinvoices" + "(" + invoice.invoiceId + ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteInvoice(invoiceId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectinvoices" + "(" + invoiceId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Decisions */
export async function GetDecisions(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectdecisions" +
    "?$select=crd5f_projectdecisionid,_crd5f_project_value,crd5f_name,crd5f_description," +
    "crd5f_requiredby,crd5f_decisionmade,crd5f_decisiondate,crd5f_decisionstatus" +
    "&$expand=crd5f_AssignedTo($select=azureactivedirectoryobjectid,systemuserid,domainname,fullname)," +
    "crd5f_DecisionMadeBy($select=azureactivedirectoryobjectid,systemuserid,domainname,fullname)" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let decisionDTO = dvResult.value as ProjectDecisionDTO[];
  //map the result to our Project Object
  let decisionList: Decision[] = decisionDTO.map((x) => {
    return {
      decisionId: x.crd5f_projectdecisionid,
      projectId: x.crd5f_project,
      name: x.crd5f_name,
      description: x.crd5f_description,
      requiredBy: new Date(x.crd5f_requiredby),
      decisionMade: x.crd5f_decisionmade,
      decisionDate: new Date(x.crd5f_decisiondate),
      status: x.crd5f_decisionstatus,

      assignedToName: x.crd5f_AssignedTo?.fullname,
      assignedToDomainName: x.crd5f_AssignedTo?.domainname,
      assignedToSysID: x.crd5f_AssignedTo?.systemuserid,
      assignedToAzureID: x.crd5f_AssignedTo?.azureactivedirectoryobjectid,

      decisionMadeByName: x.crd5f_DecisionMadeBy?.fullname,
      decisionMadeByDomainName: x.crd5f_DecisionMadeBy?.domainname,
      decisionMadeBySysID: x.crd5f_DecisionMadeBy?.systemuserid,
      decisionMadeByAzureID:
        x.crd5f_DecisionMadeBy?.azureactivedirectoryobjectid,
    };
  });

  return decisionList;
}

export async function SaveDecision(
  decision: Decision,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",
    crd5f_name: decision.name,
    crd5f_description: decision.description,
    crd5f_requiredby: decision.requiredBy,
    crd5f_decisionmade: decision.decisionMade,
    crd5f_decisiondate: decision.decisionDate,
    crd5f_decisionstatus: decision.status,
    "crd5f_AssignedTo@odata.bind": decision.assignedToSysID
      ? "/systemusers(" + decision.assignedToSysID + ")"
      : null,
    "crd5f_DecisionMadeBy@odata.bind": decision.decisionMadeBySysID
      ? "/systemusers(" + decision.decisionMadeBySysID + ")"
      : null,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectdecisions";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri +
      "crd5f_projectdecisions" +
      "(" +
      decision.decisionId +
      ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteDecision(decisionId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectdecisions" + "(" + decisionId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Change Request */
export async function GetChangeRequests(projectID: string) {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectchangerequests" +
    "?$select=crd5f_projectchangerequestid,_crd5f_project_value,crd5f_name,crd5f_crnum,crd5f_daterequested," +
    "crd5f_crtype,crd5f_description,crd5f_options,crd5f_requestedchange,crd5f_requiredby,crd5f_crstatus," +
    "crd5f_increaseincost,crd5f_fundingsource,crd5f_additionalfundsrequired,crd5f_approvalreceived" +
    "&$expand=crd5f_Requestor($select=azureactivedirectoryobjectid,systemuserid,fullname)," +
    "crd5f_Approver($select=azureactivedirectoryobjectid,systemuserid,domainname,fullname)" +
    "&$filter=(_crd5f_project_value eq " +
    projectID +
    ")";

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let changeDTO = dvResult.value as ProjectChangeDTO[];
  //map the result to our Project Object
  let crList: ChangeRequest[] = changeDTO.map((x) => {
    return {
      changeRequestId: x.crd5f_projectchangerequestid,
      projectId: x.crd5f_project,
      changeRequestName: x.crd5f_name,
      changeRequestNum: x.crd5f_crnum,

      requestorName: x.crd5f_Requestor?.fullname,
      requestorSysID: x.crd5f_Requestor?.systemuserid,
      requestorAzureID: x.crd5f_Requestor?.azureactivedirectoryobjectid,

      dateRequested: new Date(x.crd5f_daterequested),
      changeRequestType: x.crd5f_crtype,
      description: x.crd5f_description,
      options: x.crd5f_options,
      requestedChange: x.crd5f_requestedchange,

      approverName: x.crd5f_Approver?.fullname,
      approverSysID: x.crd5f_Approver?.systemuserid,
      approverAzureID: x.crd5f_Approver?.azureactivedirectoryobjectid,

      requiredBy: new Date(x.crd5f_requiredby),
      status: x.crd5f_crstatus,

      increaseInCost: +x.crd5f_increaseincost,
      fundingSource: x.crd5f_fundingsource,
      additionalFundsRequired: +x.crd5f_additionalfundsrequired,
      approvalReceived: x.crd5f_approvalreceived,
    };
  });

  return crList;
}

export async function SaveChangeRequest(
  change: ChangeRequest,
  project: string,
  newRecord: boolean
) {
  const bodyObject = {
    "crd5f_Project@odata.bind": "/crd5f_projects(" + project + ")",

    crd5f_name: change.changeRequestName,
    crd5f_crnum: change.changeRequestNum,
    crd5f_daterequested: change.dateRequested,
    crd5f_crtype: change.changeRequestType,
    crd5f_description: change.description,
    crd5f_options: change.options,
    crd5f_requestedchange: change.requestedChange,
    crd5f_increaseincost: change.increaseInCost ? +change.increaseInCost : 0,
    crd5f_fundingsource: change.fundingSource,
    crd5f_additionalfundsrequired: change.additionalFundsRequired
      ? +change.additionalFundsRequired
      : 0,
    crd5f_approvalreceived: change.approvalReceived,
    crd5f_requiredby: change.requiredBy,
    crd5f_crstatus: change.status,

    "crd5f_Requestor@odata.bind": change.requestorSysID
      ? "/systemusers(" + change.requestorSysID + ")"
      : null,
    "crd5f_Approver@odata.bind": change.requestorSysID
      ? "/systemusers(" + change.approverSysID + ")"
      : null,
  };

  const body = JSON.stringify(bodyObject);

  var queryPath: string;
  if (newRecord) {
    queryPath = DataverseApiUri + "crd5f_projectchangerequests";
    await insertToDataverse(queryPath, body);
  } else {
    queryPath =
      DataverseApiUri +
      "crd5f_projectchangerequests" +
      "(" +
      change.changeRequestId +
      ")";
    await updateDataverse(queryPath, body);
  }
}

export async function DeleteChangeRequest(changeId: string) {
  const queryPath =
    DataverseApiUri + "crd5f_projectchangerequests" + "(" + changeId + ")";
  await deleteFromDataverse(queryPath);
}
/* #endregion */

/* #region Project Program (lookup values) */
export async function GetProgramValues() {
  const queryPath =
    DataverseApiUri +
    "crd5f_projectprograms?$select=crd5f_projectprogramid,crd5f_name";
  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let programDTO = dvResult.value as {
    crd5f_projectprogramid: string;
    crd5f_name: string;
  }[];
  //map the result to our picklist keys
  let programs: { key: string; header: string }[] = programDTO.map((x) => {
    return {
      key: x.crd5f_projectprogramid,
      header: x.crd5f_name,
    };
  });
  return programs;
}

export async function GetActiveProjectPrograms(startDate: Date, endDate: Date) {
  const formattedStartDate = dateFormat(startDate, "yyyy-mm-dd");
  const formattedEndDate = dateFormat(endDate, "yyyy-mm-dd");

  const queryPath =
    DataverseApiUri +
    "crd5f_projectprograms?$select=crd5f_projectprogramid,crd5f_name,crd5f_description" +
    `&$filter=(` +
    `(crd5f_startdate ge ${formattedStartDate} and crd5f_startdate le ${formattedEndDate})` +
    ` or ` +
    `(crd5f_enddate ge ${formattedStartDate} and crd5f_enddate le ${formattedEndDate})` +
    ` or ` +
    `(crd5f_startdate le ${formattedStartDate} and crd5f_enddate ge ${formattedEndDate})` +
    `)`;

  const dvResult = await callDataverse(queryPath);
  //cast the result as our DTO
  let programDTO = dvResult.value as crd5f_projectprogram[];
  //map the result to our picklist keys
  let programs: ProjectProgram[] = programDTO.map((x) => {
    return {
      name: x.crd5f_name,
      description: x.crd5f_description,
      programId: x.crd5f_projectprogramid
    };
  });
  return programs;
}
/* #endregion */
