import { useState, useContext } from "react";
import {
  getCurrentUser,
  SaveProjectNote,
} from "../../../helpers/ProjectService";
import ProjectNote from "../../../objects/models/ProjectNote";

import "../../../CarnaStyles.css";
import { Button, Form, TextArea } from "@fluentui/react-northstar";
import { TextChangeCase16Regular } from "@fluentui/react-icons";

import {
  Person,
  PersonCardInteraction,
  PersonViewType,
} from "@microsoft/mgt-react";
import AttachmentsView from "../Attachments";
import { onFocusSelectAll } from "../projectUtils";
import { RoleContext } from "../../../RoleWrapper";
import { CharactersRemaining } from "../CharactersRemaining";

interface Props {
  newForm: boolean;
  project: string;
  projectClosed: boolean;
  note?: ProjectNote;
  closeAction: () => void;
}

const ProjectNoteSingle: React.FC<Props> = (props: Props) => {
  const projectID = props.project;
  const isClosed = props.projectClosed;
  const [newForm, setNewForm] = useState<boolean>(props.newForm);
  const [note, setNote] = useState<ProjectNote>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && note) {
      const key = e.target.id;
      setNote({ ...note, [key]: value });
    }
  };

  //no note loaded on the page yet. Load it from props, or create a new one.
  if (note === undefined) {
    //we were passed a note! Load it in and let's go!
    if (props.note) {
      setNote(props.note);
      return <div>Loading project note...</div>;
    }
    //no note passed in. We must need to create a new one.
    else {
      //yep, create a new one.
      if (newForm) {
        const loadNewForm = async () => {
          var newNote = new ProjectNote();
          newNote.projectId = projectID;
          newNote.noteName = "[New project note]";

          var me = await getCurrentUser();
          if (me) {
            newNote.createdBy = me;
            newNote.createdByAzureID = me.id;
          }
          setNote(newNote);
        };
        loadNewForm();
        return <div>Creating new form...</div>;
      } else {
        return (
          <div>
            No project note was supplied, but this also isn't a new form. Did
            you forget a prop?
          </div>
        );
      }
    }
  } else {
    const saveAndClose = async () => {
      if (!validNote(note)) {
        setShowErrors(true);
      } else {
        setShowErrors(false);
        setNewForm(false);
        await SaveProjectNote(note, projectID, newForm);
        props.closeAction();
      }
    };

    const closePopup = () => {
      props.closeAction();
    };

    var createdById;
    if (note.createdBy) {
      createdById = note.createdBy.id;
    } else if (note.createdByAzureID) {
      createdById = note.createdByAzureID;
    } else {
      createdById = undefined;
    }

    return (
      <>
        <div className="carnaForm" data-testid="note-form">
          <Form>
            <h1>
              <TextArea
                id="noteName"
                key="noteName"
                value={note.noteName}
                onChange={onChangeTextArea}
                autoFocus={newForm}
                className="titleField"
                fluid
                rows={1}
                onFocus={onFocusSelectAll}
                readOnly={!userRole.canEdit || isClosed}
                maxLength={250}
              />
              <CharactersRemaining value={note.noteName} maxLength={250} />
            </h1>
            <div className="errorText">
              {showErrors
                ? !note.noteName
                  ? "Name is required"
                  : undefined
                : undefined}
            </div>
            <span onClick={closePopup} className="close-button topright">
              X
            </span>
            <div className="carnaRow">
              <div className="carnaColFull">
                <div className="fieldLabel">
                  <TextChangeCase16Regular /> Description
                </div>
                <TextArea
                  id="description"
                  key="description"
                  value={note.description}
                  onChange={onChangeTextArea}
                  fluid
                  rows={10}
                  autoFocus={!newForm}
                  resize="vertical"
                  readOnly={!userRole.canEdit || isClosed}
                  maxLength={20000}
                />
                <CharactersRemaining
                  value={note.description}
                  maxLength={20000}
                />
                <div className="errorText">
                  {showErrors
                    ? !note.description
                      ? "Description is required"
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColFull">
                <div className="fieldLabel">Created By:</div>
                <Person
                  userId={createdById}
                  view={PersonViewType.twolines}
                  personCardInteraction={PersonCardInteraction.none}
                  line2Property="jobTitle"
                  fallbackDetails={{ displayName: note.createdByName }}
                />
              </div>
              {note ? (
                <div>
                  <AttachmentsView
                    project={projectID}
                    entity="note"
                    entityId={note.noteId}
                    disabled={newForm}
                  />
                </div>
              ) : undefined}
            </div>
            {userRole.canEdit && !isClosed ? (
              <div style={{ float: "left" }}>
                <Button content="Save Note" onClick={saveAndClose} />
                &nbsp;
                <Button content="Cancel" onClick={closePopup} />
              </div>
            ) : (
              <Button content="Close Popup" onClick={closePopup} />
            )}
          </Form>
        </div>
      </>
    );
  }
};

function validNote(note: ProjectNote): boolean {
  var valid: boolean = false;
  if ([note.noteName, note.description].every(Boolean)) {
    valid = true;
  }
  return valid;
}

export default ProjectNoteSingle;
