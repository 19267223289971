import { useContext, useEffect, useState } from "react";

import StatusReport from "../../../objects/models/StatusReport";
import ProjectStatusReport from "./projectStatusReport";
import {
  getPicklistValues,
  DeleteStatusReport,
  GetStatusReports,
} from "../../../helpers/ProjectService";

import { Dialog, Button, Table } from "@fluentui/react-northstar";

import "../../../CarnaStyles.css";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import {
  getPicklistKeys,
  LookupPicklistValue,
  HeaderClick,
} from "../projectUtils";
import {
  Circle24Regular,
  CheckmarkCircle24Filled,
  ArrowSortDown16Regular,
  ArrowSortUp16Regular,
} from "@fluentui/react-icons";
import { StatusReportPDFLink } from "./ManagerStatusReport";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}

const ProjectStatusReportList: React.FC<Props> = (props: Props) => {
  const myProject = props.projectId;
  const projectClosed = props.projectClosed;
  const [statusReports, setStatusReports] = useState<StatusReport[]>();
  const [statusReportsLoaded, setStatusReportsLoaded] =
    useState<boolean>(false);
  const [statusReportsLoading, setStatusReportsLoading] =
    useState<boolean>(false);
  const [sortedColumn, setSortedColumn] = useState<string>();
  const [sortedDescending, setSortedDescending] = useState<boolean>(false);
  const [selectedReports, setSelectedReports] = useState<string[]>([]);

  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);

  const [activeStatusReport, setActiveStatusReport] = useState<StatusReport>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const [selectedPrint, setSelectedPrint] = useState<string>();
  const [printDialogOpen, setPrintDialogOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  useEffect(() => {
    (async function loadData() {
      if (!statusReportsLoaded && !statusReportsLoading) {
        setStatusReportsLoading(true);
        const response = await GetStatusReports(myProject);
        setStatusReports(
          response.sort((a, b) => (a.endDate < b.endDate ? 1 : -1))
        );
        if (statusReports) setStatusReportsLoaded(true);
        setStatusReportsLoading(false);
      }
    })();
  }, [statusReportsLoading, statusReportsLoaded, statusReports]);

  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickListData = await getPicklistValues(
          "crd5f_projectstatusreport"
        );
        setPicklists(pickListData);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  const headers = {
    key: "statusReportHeader",
    items: [
      {
        key: "rowSelect",
        className: "rowSelectColumn",
      },
      {
        content: (
          <h3>
            Reporting Period
            {sortedColumn === "reportingPeriod" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "reportingPeriod",
        onClick: () =>
          HeaderClick(
            "reportingPeriod",
            false,
            statusReports,
            setStatusReports,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Overall Health Status
            {sortedColumn === "overallHealthStatus" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "overallHealthStatus",
        onClick: () =>
          HeaderClick(
            "overallHealthStatus",
            true,
            statusReports,
            setStatusReports,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Budget Status
            {sortedColumn === "budgetStatus" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "budgetStatus",
        onClick: () =>
          HeaderClick(
            "budgetStatus",
            true,
            statusReports,
            setStatusReports,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
      {
        content: (
          <h3>
            Milestone Status
            {sortedColumn === "milestoneStatus" ? (
              sortedDescending ? (
                <ArrowSortDown16Regular />
              ) : (
                <ArrowSortUp16Regular />
              )
            ) : undefined}
          </h3>
        ),
        key: "milestoneStatus",
        onClick: () =>
          HeaderClick(
            "milestoneStatus",
            true,
            statusReports,
            setStatusReports,
            sortedColumn,
            setSortedColumn,
            sortedDescending,
            setSortedDescending
          ),
      },
    ],
  };

  function toggleSelected(i: string): void {
    var selected = [...selectedReports];
    if (selected.includes(i)) {
      var index = selected.indexOf(i);
      if (index > -1) {
        selected.splice(index, 1);
      }
    } else {
      selected.push(i);
    }
    setSelectedReports(selected);
  }

  function refreshStatusReports(): void {
    setSelectedReports([]);
    setStatusReportsLoaded(false);
  }

  const onItemInvoked = (item: StatusReport) => {
    setNewForm(false);
    setActiveStatusReport(item);
    setIsModalOpen(true);
  };
  const newStatusReport = () => {
    setNewForm(true);
    setActiveStatusReport(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshStatusReports();
  };
  const deleteAction = async () => {
    for (const report of selectedReports) {
      await DeleteStatusReport(report);
    }
    setDeleteDialogOpen(false);
    refreshStatusReports();
  };
  const editButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedReports.length > 0) {
      const report = statusReports?.find(
        ({ statusReportId }) => statusReportId === selectedReports[0]
      );
      onItemInvoked(report!);
    }
  };
  const printButtonClick = () => {
    //guard against none/many selected, just in case something weird happens
    if (selectedReports.length > 0) {
      setSelectedPrint(selectedReports[0]);
      setPrintDialogOpen(true);
    }
  };

  const renderGridrow = (item: StatusReport, key: string) => {
    if (item && picklists) {
      var healthStyle: string = "noHealth";
      var health: string = "N/A";

      switch (key) {
        case "reportingPeriod":
          return <div>{item.reportingPeriod}</div>;
        case "overallHealthStatus":
          var keys = getPicklistKeys(picklists, "crd5f_overallhealthstatus");
          var displayValue = LookupPicklistValue(
            keys,
            item.overallHealthStatus
          );
          if (item.overallHealthStatus) {
            switch (displayValue) {
              case "Red":
                healthStyle = "redHealth";
                health = displayValue;
                break;
              case "Yellow":
                healthStyle = "yellowHealth";
                health = displayValue;
                break;
              case "Green":
                healthStyle = "greenHealth";
                health = displayValue;
                break;
              default:
                healthStyle = "noHealth";
                break;
            }
          }
          return <span className={healthStyle}>{displayValue}</span>;
        case "budgetStatus":
          var keys = getPicklistKeys(picklists, "crd5f_budgetstatus");
          displayValue = LookupPicklistValue(keys, item.budgetStatus);
          if (item.budgetStatus) {
            switch (displayValue) {
              case "Red":
                healthStyle = "redHealth";
                break;
              case "Yellow":
                healthStyle = "yellowHealth";
                break;
              case "Green":
                healthStyle = "greenHealth";
                break;
              default:
                healthStyle = "noHealth";
                break;
            }
          }
          return <span className={healthStyle}>{displayValue}</span>;
        case "milestoneStatus":
          var keys = getPicklistKeys(picklists, "crd5f_milestonestatus");
          displayValue = LookupPicklistValue(keys, item.milestoneStatus);
          if (item.milestoneStatus) {
            switch (displayValue) {
              case "Red":
                healthStyle = "redHealth";
                break;
              case "Yellow":
                healthStyle = "yellowHealth";
                break;
              case "Green":
                healthStyle = "greenHealth";
                break;
              default:
                healthStyle = "noHealth";
                break;
            }
          }
          return <span className={healthStyle}>{displayValue}</span>;
      }
    }
  };

  if (statusReports === undefined || picklists === undefined) {
    if (statusReportsLoading) {
      return <div>Loading status reports...</div>;
    } else {
      return <div>There was an error loading the project status reports.</div>;
    }
  } else {
    const rowItems = statusReports.map((report) => {
      return {
        key: report.statusReportId + "-row",
        items: [
          {
            key: report.statusReportId + "-0",
            content: selectedReports.includes(report.statusReportId) ? (
              <CheckmarkCircle24Filled />
            ) : (
              <Circle24Regular />
            ),
            onClick: () => toggleSelected(report.statusReportId),
            className: "rowSelectColumn",
          },
          {
            key: report.statusReportId + "-1",
            content: renderGridrow(report, "reportingPeriod"),
            onClick: () => toggleSelected(report.statusReportId),
          },
          {
            key: report.statusReportId + "-2",
            content: renderGridrow(report, "overallHealthStatus"),
            onClick: () => toggleSelected(report.statusReportId),
          },
          {
            key: report.statusReportId + "-3",
            content: renderGridrow(report, "budgetStatus"),
            onClick: () => toggleSelected(report.statusReportId),
          },
          {
            key: report.statusReportId + "-4",
            content: renderGridrow(report, "milestoneStatus"),
            onClick: () => toggleSelected(report.statusReportId),
          },
        ],
      };
    });

    return (
      <>
        {/* Top buttons */}
        {selectedReports.length === 0 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="New Status Report" onClick={newStatusReport} />
            </div>
          ) : undefined
        ) : selectedReports.length === 1 ? (
          userRole.canEdit && !projectClosed ? (
            <div>
              <Button content="Edit Status Report" onClick={editButtonClick} />
              <Button
                content="Print Status Report"
                onClick={printButtonClick}
              />
            </div>
          ) : (
            <div>
              <Button content="View Status Report" onClick={editButtonClick} />
              <Button
                content="Print Status Report"
                onClick={printButtonClick}
              />
            </div>
          )
        ) : (
          <div></div>
        )}
        {/* End top buttons */}
        {/* Display either the "no reports" screen, or the status report list */}
        {statusReports.length === 0 ? (
          <div>There are no status reports.</div>
        ) : (
          <Table header={headers} rows={rowItems} />
        )}
        {/* All the dialogs are below. Use the "open" props to toggle. */}
        {/* The active status report */}
        <Dialog
          open={isModalOpen}
          onCancel={onItemDismissed}
          onConfirm={onItemDismissed}
          closeOnOutsideClick={false}
          content={
            <ProjectStatusReport
              newForm={newForm}
              project={myProject}
              projectClosed={projectClosed}
              report={activeStatusReport}
              picklists={picklists}
              closeAction={onItemDismissed}
            />
          }
        />
        {/* Delete the selected status report(s) */}
        <Dialog
          header={
            selectedReports.length > 1
              ? "Delete these status reports?"
              : "Delete this status report?"
          }
          content="The selected status reports will be deleted."
          cancelButton="Cancel"
          confirmButton="Yes, delete"
          onCancel={() => {
            setDeleteDialogOpen(false);
          }}
          onConfirm={deleteAction}
          open={deleteDialogOpen}
        />
        {/* Print this status report */}
        <Dialog
          header="Print this status report"
          confirmButton="Done"
          content={
            selectedPrint ? (
              <StatusReportPDFLink statusReportId={selectedPrint} />
            ) : (
              "Nothing here!"
            )
          }
          onConfirm={() => {
            setPrintDialogOpen(false);
            setSelectedPrint(undefined);
          }}
          onCancel={() => {
            setPrintDialogOpen(false);
            setSelectedPrint(undefined);
          }}
          open={printDialogOpen}
        />
      </>
    );
  }
};

export default ProjectStatusReportList;
