const BigTriangle = () => {
  return (
    <svg
      data-testid="big-triangle"
      id="bigTriangleColor"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="100%"
      height="35"
      viewBox="0 0 100 102"
      preserveAspectRatio="none"
    >
      <path d="M0 0 L50 100 L100 0 Z" />
    </svg>
  );
};

export default BigTriangle;
