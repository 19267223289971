import { useContext, useEffect, useState } from "react";

import { Button, Dialog, Table } from "@fluentui/react-northstar";

import { GetProjectNotes } from "../../../helpers/ProjectService";
import ProjectNote from "../../../objects/models/ProjectNote";

import { GrDocumentText } from "react-icons/gr";

import "../../../CarnaStyles.css";
import ProjectNoteSingle from "./projectNoteSingle";
import { RoleContext } from "../../../RoleWrapper";

interface Props {
  projectId: string;
  projectClosed: boolean;
}
var dateFormat = require("dateformat");

const ProjectNotesList: React.FC<Props> = (props: Props) => {
  const projectId = props.projectId;
  const projectClosed = props.projectClosed;
  const [notes, setNotes] = useState<ProjectNote[]>();
  const [notesLoaded, setNotesLoaded] = useState<boolean>(false);
  const [notesLoading, setNotesLoading] = useState<boolean>(false);

  const [displayComponent, setDisplayComponent] = useState<boolean>();

  //used for the modal
  const [activeNote, setActiveNote] = useState<ProjectNote>();
  const [newForm, setNewForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  function refreshNotes(): void {
    GetProjectNotes(projectId).then((response) => {
      setNotes(response);
      if (response.length > 0) {
        setDisplayComponent(true);
      } else {
        setDisplayComponent(false);
      }
    });
  }

  useEffect(() => {
    (async function loadData() {
      if (!notesLoaded && !notesLoading) {
        setNotesLoading(true);
        const response = await GetProjectNotes(projectId);
        setNotes(response);
        if (response.length > 0) {
          setDisplayComponent(true);
        } else {
          setDisplayComponent(false);
        }
        setNotesLoaded(true);
        setNotesLoading(false);
      }
    })();
  }, [notes, notesLoaded, notesLoading]);

  //behaviors for interacting with Project Note items.
  const onItemInvoked = (item: ProjectNote) => {
    setNewForm(false);
    setActiveNote(item);
    setIsModalOpen(true);
  };
  const newNote = () => {
    setNewForm(true);
    setActiveNote(undefined);
    setIsModalOpen(true);
  };
  const onItemDismissed = () => {
    setIsModalOpen(false);
    setNewForm(false);
    refreshNotes();
  };

  if (notes === undefined) {
    if (notesLoading) {
      return <div>Loading project notes...</div>;
    } else {
      return <div>There was an error loading the project notes.</div>;
    }
  } else {
    const rowItems = notes.map((note, index) => {
      var display: string = "block";
      if (note.description === undefined || note.description === null) {
        display = "none";
      }
      if (note.description?.length === 0) {
        display = "none";
      }
      var dateString = dateFormat(note.createdDate, "mmmm dS, yyyy @ h:MM");

      return {
        key: note.noteId + "-row",
        onClick: () => onItemInvoked(note),
        items: [
          {
            key: note.noteId + "-1",
            content: (
              <div>
                <div className="carnaRow">
                  <div className="carnaColHalf noteTitle">
                    <h3>
                      <GrDocumentText /> <strong>Title: </strong>
                      {note.noteName}
                    </h3>
                  </div>
                  <div className="carnaColHalf noteDate">
                    <h3>
                      <strong>Created: </strong>
                      {dateString} <strong>By: </strong>
                      {note.createdByName}
                    </h3>
                  </div>
                </div>
                <div className="carnaRow" style={{ display: display }}>
                  <div className="carnaColFull blockText">
                    {note.description?.substring(0, 1000)}
                  </div>
                </div>
              </div>
            ),
          },
        ],
      };
    });

    return (
      <>
        {userRole.canEdit && !projectClosed ? (
          <div>
            <Button content="New Note" onClick={newNote} />
          </div>
        ) : undefined}
        <br />
        {displayComponent ? undefined : "There are no notes on this project"}
        <div className={displayComponent ? "showThis notesBox" : "hideThis"}>
          <Table rows={rowItems} />
          <Dialog
            open={isModalOpen}
            onCancel={onItemDismissed}
            onConfirm={onItemDismissed}
            closeOnOutsideClick={false}
            content={
              <ProjectNoteSingle
                project={projectId}
                projectClosed={projectClosed}
                newForm={newForm}
                note={activeNote}
                closeAction={onItemDismissed}
              />
            }
          />
        </div>
      </>
    );
  }
};

export default ProjectNotesList;
