import {
  ArrowLeftIcon,
  ChevronStartIcon,
  MoreIcon,
} from "@fluentui/react-icons-northstar";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import { useState } from "react";
import "./ProjectNavBar.css";

const ProjectNavBar = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  const [theme, setTheme] = useState<string | undefined>("white");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);

  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("#141414");
      } else {
        setTheme("white");
      }
    }
  });

  return (
    <>
      {isMobile ? (
        <div
          className="project-nav-wrapper"
          style={{
            background: theme,
          }}
        >
          <ChevronStartIcon
            size="large"
            className="left-icon"
            onClick={history.goBack}
          />
          <span> Project </span>
          <MoreIcon size="large" className="right-icon" />
        </div>
      ) : (
        <button
          data-testid="project-nav-back"
          onClick={history.goBack}
          className="nav-bar-link"
          style={{ marginLeft: 0 }}
        >
          <ArrowLeftIcon size="small" style={{ marginTop: -2 }} /> Back
        </button>
      )}
    </>
  );
};

export default ProjectNavBar;
