export class crd5f_project {
	versionnumber!: number;
	crd5f_projectprogramname!: string;
	createdon!: Date;
	crd5f_forecastcapex!: number;
	crd5f_investmenttype!: crd5f_investmenttypeEnum;
	owningbusinessunitname!: string;
	crd5f_changemanager!: string;
	statuscodename!: string;
	crd5f_overallhealth!: crd5f_overallhealthEnum;
	crd5f_adjustedfiscalbudgetopex_base!: number;
	modifiedbyyominame!: string;
	owneridtype!: string;
	crd5f_approvedbudgetcapex!: number;
	crd5f_changemanagername!: string;
	crd5f_spentytdcapex_base!: number;
	crd5f_adjustedbudgetcapex_base!: number;
	createdonbehalfby!: string;
	crd5f_adjustedfiscalbudgetcapex!: number;
	modifiedbyname!: string;
	crd5f_adjustedfiscalbudgetopex!: number;
	crd5f_approvedbudget_base!: number;
	owneridname!: string;
	crd5f_forecastcapex_base!: number;
	crd5f_approvedfiscalbudgetopex!: number;
	overriddencreatedon!: Date;
	crd5f_finalprojectcostopex!: number;
	crd5f_strategicalignment!: crd5f_strategicalignmentEnum;
	crd5f_classificationname!: crd5f_strategicalignmentEnum;
	crd5f_acronym!: string;
	crd5f_projectsponsor!: string;
	createdonbehalfbyyominame!: string;
	modifiedonbehalfby!: string;
	exchangerate!: number;
	crd5f_projectprogram!: string;
	crd5f_spentytdcapex!: number;
	crd5f_approvedfiscalbudgetcapex!: number;
	owningteam!: string;
	crd5f_forecastyear3capex!: number;
	crd5f_forecastopex_base!: number;
	statecode!: statecodeEnum;
	crd5f_executivesponsorname!: string;
	crd5f_executivesponsoryominame!: string;
	crd5f_spenttodatecapex_base!: number;
	crd5f_overallhealthname!: number;
	crd5f_approvedfiscalbudgetopex_base!: number;
	crd5f_projectsponsoryominame!: string;
	crd5f_code!: string;
	crd5f_forecastnextyearopex!: number;
	crd5f_executivesponsor!: string;
	crd5f_programname!: string;
	crd5f_projectstatusname!: string;
	crd5f_valuestatement!: string;
	crd5f_classification!: crd5f_classificationEnum;
	createdonbehalfbyname!: string;
	crd5f_program!: crd5f_programEnum;
	crd5f_forecastnextyearcapex!: number;
	crd5f_forecastopex!: number;
	utcconversiontimezonecode!: number;
	crd5f_approvedbudget!: number;
	timezoneruleversionnumber!: number;
	crd5f_visionmissionvalues!: string;
	crd5f_programnumber!: string;
	crd5f_forecastyear3opex_base!: number;
	crd5f_adjustedbudgetopex_base!: number;
	crd5f_forecastyear3opex!: number;
	ownerid!: string;
	modifiedon!: Date;
	importsequencenumber!: number;
	owneridyominame!: string;
	crd5f_finalprojectcostcapex!: number;
	crd5f_projectid!: string;
	crd5f_adjustedbudgetcapex!: number;
	createdbyname!: string;
	modifiedonbehalfbyname!: string;
	crd5f_adjustedbudgetopex!: number;
	crd5f_startdate!: Date;
	crd5f_spenttodateopex_base!: number;
	transactioncurrencyid!: string;
	createdby!: string;
	modifiedby!: string;
	crd5f_projectsponsorname!: string;
	crd5f_changemanageryominame!: string;
	createdbyyominame!: string;
	owninguser!: string;
	transactioncurrencyidname!: string;
	crd5f_enddate!: Date;
	crd5f_finalprojectcostopex_base!: number;
	owningbusinessunit!: string;
	crd5f_strategicalignmentname!: string;
	crd5f_forecastnextyearcapex_base!: number;
	crd5f_approvedbudgetcapex_base!: number;
	crd5f_spenttodateopex!: number;
	crd5f_adjustedfiscalbudgetcapex_base!: number;
	crd5f_approvedfiscalbudgetcapex_base!: number;
	crd5f_investmenttypename!: number;
	statuscode!: statuscodeEnum;
	modifiedonbehalfbyyominame!: string;
	crd5f_forecastnextyearopex_base!: number;
	crd5f_spenttodatecapex!: number;
	crd5f_spentytdopex!: number;
	crd5f_projectstatus!: crd5f_projectstatusEnum;
	crd5f_projectname!: string;
	crd5f_finalprojectcostcapex_base!: number;
	statecodename!: number;
	crd5f_forecastyear3capex_base!: number;
	crd5f_spentytdopex_base!: number;
}
export enum crd5f_investmenttypeEnum {
	Run = 922660000,
	Grow = 922660001,
	Transform = 922660002
}
export enum crd5f_overallhealthEnum {
	NotStarted = 922660000,
	Green = 922660001,
	Yellow = 922660002,
	Red = 922660003,
	Closed = 922660004
}
export enum crd5f_strategicalignmentEnum {
	ProgressiveCulture = 922660000,
	CollaborativePartnerships = 922660001,
	FiscalStewardship = 922660002,
	OrganizationExcellence = 922660003
}
export enum statecodeEnum {
}
export enum crd5f_classificationEnum {
	Non_Discretionary_Regulatory = 922660000,
	Non_Discretionary_RiskTolerance = 922660001,
	Non_Discretionary_Strategic = 922660002,
	Discretionary = 922660003
}
export enum crd5f_programEnum {
	IMRoadmap = 922660000,
	SingleMandate = 922660001,
	RegulatoryExcellence = 922660002,
	Other = 922660003
}
export enum statuscodeEnum {
}
export enum crd5f_projectstatusEnum {
	NotStarted = 922660004,
	Planning = 922660000,
	Delivery = 922660001,
	Closed = 922660002,
	OnHold = 922660003
}
