import { Providers } from "@microsoft/mgt-element";

export async function callDataverse(query: string) {
  return queryDataverse(query, "GET", undefined);
}

export async function updateDataverse(query: string, body: string) {
  return queryDataverse(query, "PATCH", body);
}

export async function insertToDataverse(query: string, body: string) {
  return queryDataverse(query, "POST", body);
}

export async function deleteFromDataverse(query: string) {
  return queryDataverse(query, "DELETE", undefined);
}

export async function whoAmI() {
  const query = DataverseApiUri + "WhoAmI()";
  return queryDataverse(query, "GET", undefined);
}

export const DataverseApiUri =
  process!.env!.REACT_APP_DATAVERSE_URL! +
  process!.env!.REACT_APP_DATAVERSE_API_VERSION_PATH!;

export async function queryDataverse(
  query: string,
  method: string,
  body?: string
) {
  
  const prov = Providers.globalProvider;
  const token = await prov.getAccessToken({
    scopes: [process.env.REACT_APP_DATAVERSE_URL + ".default"],
  });

  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);
  headers.append("Accept", "appliation/json");
  headers.append("Content-Type", "application/json; charset=utf-8");
  headers.append("OData-MaxVersion", "4.0");
  headers.append("OData-Version", "4.0");
  if (method === "PATCH") {
    headers.append("If-Match", "*");
  }

  var options;
  if (method === "GET" || method === "DELETE") {
    options = {
      method: method,
      headers: headers,
    };
  } else {
    options = {
      method: method,
      headers: headers,
      body: body,
    };
  }
  
  return fetch(query, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
