import { useEffect, useState } from "react";
import Project from "../../../objects/models/Project";
import TaskStackedVBarChart from "../taskStackedVBarChart";
import RiskSCPieChart from "../riskSCPieChart";
import BudgetHBarChart from "../budgetHBarChart";
import Cloudes from "../svgDesign/Cloudes";
import Forest from "../svgDesign/forest";
import BigTriangle from "../svgDesign/bigTriangle";
import Waves from "../svgDesign/waves";
import * as Dataverse from "../../../helpers/ProjectService";
import { GetProject } from "../../../helpers/ProjectService";
import ChangeRequest from "../../../objects/models/ChangeRequest";
import Decision from "../../../objects/models/Decision";
import Milestone from "../../../objects/models/Milestone";
import Risk from "../../../objects/models/Risk";
import {
  getPicklistKeys,
  LookupPicklistValue,
} from "../../project/projectUtils";
import { PicklistValues } from "../../../objects/models/PicklistValues";
import StatusReport from "../../../objects/models/StatusReport";
import TimelineStackedVBarChart from "../timelineStackedVBarChart";
import { crd5f_overallhealthEnum as ProjectHealth } from "../../../objects/entities/crd5f_project";
import { NavLink } from "react-router-dom";
import {
  CollapseIcon,
  ExpandIcon,
  OpenOutsideIcon,
} from "@fluentui/react-northstar";
import { useMediaQuery } from "react-responsive";
import Expand from "react-expand-animated";

interface riskProperty {
  projectProperty: "risks";
  targetedProperty: "impact";
}

interface defaultProperty {
  projectProperty: "changeRequests" | "decisions" | "milestones";
  targetedProperty: "status";
}

interface GetCountProps {
  property: defaultProperty | riskProperty;
  targetedPickList:
    | "riskPickList"
    | "decisionPickList"
    | "milestonePickList"
    | "changeRequestPickList";
  pickLiskKey:
    | "crd5f_impact"
    | "crd5f_crstatus"
    | "crd5f_decisionstatus"
    | "crd5f_milestonestatus";
  displayValues: string[];
}

export interface FullProject extends Project {
  decisions?: Decision[];
  milestones?: Milestone[];
  changeRequests?: ChangeRequest[];
  risks?: Risk[];
  statusReports?: StatusReport[];
  riskPickList?: PicklistValues;
  decisionPickList?: PicklistValues;
  milestonePickList?: PicklistValues;
  changeRequestPickList?: PicklistValues;
}

async function getProjectData(projectId: string) {
  //from the project ID, get the Project data and all relevant entities.
  var [
    project,
    decisions,
    milestones,
    changeRequests,
    risks,
    statusReport,
    riskPickList,
    decisionPickList,
    milestonePickList,
    changeRequestPickList,
  ]: [
    FullProject,
    Decision[],
    Milestone[],
    ChangeRequest[],
    Risk[],
    StatusReport[],
    PicklistValues,
    PicklistValues,
    PicklistValues,
    PicklistValues
  ] = await Promise.all([
    GetProject(projectId),
    Dataverse.GetDecisions(projectId),
    Dataverse.GetMilestones(projectId),
    Dataverse.GetChangeRequests(projectId),
    Dataverse.GetRisks(projectId),
    Dataverse.GetStatusReports(projectId),
    Dataverse.getPicklistValues("crd5f_projectrisk"),
    Dataverse.getPicklistValues("crd5f_projectdecision"),
    Dataverse.getPicklistValues("crd5f_projectmilestone"),
    Dataverse.getPicklistValues("crd5f_projectchangerequest"),
  ]);
  project.decisions = decisions;
  project.milestones = milestones;
  project.changeRequests = changeRequests;
  project.risks = risks;
  project.statusReports = statusReport;
  project.riskPickList = riskPickList;
  project.decisionPickList = decisionPickList;
  project.milestonePickList = milestonePickList;
  project.changeRequestPickList = changeRequestPickList;
  return project;
}

interface CardbuilderProps {
  projectID: string;
  index: string;
  projectTitle: string;
  projectHealth: number | undefined;
}

const CardBuilder = (props: CardbuilderProps) => {
  const projectID = props.projectID;
  const index = props.index;
  const projectTitle = props.projectTitle;
  const projectHealth = props.projectHealth;

  const [project, setProject] = useState<FullProject>();

  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const [cardOpen, setCardOpen] = useState<boolean>(false);

  useEffect(() => {
    if (projectID !== undefined) {
      const fetchUsers = async (projectID: string) => {
        if (projectID !== null) {
          const projectObject = await getProjectData(projectID);
          setProject(projectObject);
        }
      };
      fetchUsers(projectID);
    }
  }, [projectID]);

  const getCount = ({
    property,
    targetedPickList,
    displayValues,
    pickLiskKey,
  }: GetCountProps) => {
    const count = {
      [displayValues[0]]: 0,
      [displayValues[1]]: 0,
      [displayValues[2]]: 0,
    };
    if (project !== undefined) {
      const data = project[property.projectProperty];
      if (data !== undefined) {
        for (const index in data) {
          let value: number | undefined = undefined;
          if (property.projectProperty === "risks") {
            const risk: any = data[index];
            value = risk["impact"];
          } else {
            value = data[index][property.targetedProperty];
          }
          if (value) {
            const keys = getPicklistKeys(
              project[targetedPickList],
              pickLiskKey
            );
            const displayValue = LookupPicklistValue(keys, value);
            if (displayValue !== undefined) {
              if (displayValue === displayValues[0]) {
                count[displayValues[0]] += 1;
              }
              if (displayValue === displayValues[1]) {
                count[displayValues[1]] += 1;
              }
              if (displayValue === displayValues[2]) {
                count[displayValues[2]] += 1;
              }
            }
          }
        }
      }
    }
    return count;
  };

  const getMilestoneCount = () => {
    const milestoneProperty: defaultProperty = {
      projectProperty: "milestones",
      targetedProperty: "status",
    };
    const milestoneProps: GetCountProps = {
      property: milestoneProperty,
      targetedPickList: "milestonePickList",
      displayValues: ["Planning", "Delivery", "Completed"],
      pickLiskKey: "crd5f_milestonestatus",
    };
    const milestoneCount = getCount(milestoneProps);
    return milestoneCount;
  };

  const getChangeRequestCount = () => {
    const changeRequestProperty: defaultProperty = {
      projectProperty: "changeRequests",
      targetedProperty: "status",
    };
    const changeRequestProps: GetCountProps = {
      property: changeRequestProperty,
      targetedPickList: "changeRequestPickList",
      displayValues: ["Pending", "Approved", "Rejected"],
      pickLiskKey: "crd5f_crstatus",
    };
    const changeRequestCount = getCount(changeRequestProps);
    return changeRequestCount;
  };

  const getDecisonCount = () => {
    const decisonProperty: defaultProperty = {
      projectProperty: "decisions",
      targetedProperty: "status",
    };
    const decisonsProps: GetCountProps = {
      property: decisonProperty,
      targetedPickList: "decisionPickList",
      displayValues: ["Pending", "Decision Made", "Cancelled"],
      pickLiskKey: "crd5f_decisionstatus",
    };
    const decisonCount = getCount(decisonsProps);
    return decisonCount;
  };

  const getRiskCount = () => {
    const riskProperty: riskProperty = {
      projectProperty: "risks",
      targetedProperty: "impact",
    };
    const riskProps: GetCountProps = {
      property: riskProperty,
      targetedPickList: "riskPickList",
      displayValues: ["High", "Medium", "Low"],
      pickLiskKey: "crd5f_impact",
    };
    return getCount(riskProps);
  };

  const getTimelineRange = () => {
    const sortedStatusRepots = project?.statusReports?.sort(
      (a, b) => b.endDate.valueOf() - a.endDate.valueOf()
    );

    if (sortedStatusRepots !== undefined) {
      const estimatedEndDate = project?.endDate;
      const estimatedStartDate = project?.startDate;
      const projectedEndDate = sortedStatusRepots[0]?.endDate;
      const projectedStartDate =
        sortedStatusRepots[sortedStatusRepots.length - 1]?.startDate;
      const timelineRange = {
        estimatedEndDate: estimatedEndDate,
        estimatedStartDate: estimatedStartDate,
        projectedEndDate: projectedEndDate,
        projectedStartDate: projectedStartDate,
      };
      return timelineRange;
    }
    return {
      estimatedEndDate: undefined,
      estimatedStartDate: undefined,
      projectedEndDate: undefined,
      projectedStartDate: undefined,
    };
  };

  const getProjectHealth = () => {
    const COLORS = ["#891d1d", "#e4893b", "#29AD4C", "#0468A5", "#111921"];
    let healthStyle: string = COLORS[3];

    if (projectHealth !== undefined) {
      switch (ProjectHealth[projectHealth]) {
        case "Red":
          healthStyle = COLORS[0];
          break;
        case "Yellow":
          healthStyle = COLORS[1];
          break;
        case "Green":
          healthStyle = COLORS[2];
          break;
        case "NotStarted":
          healthStyle = COLORS[3];
          break;
        case "Closed":
          healthStyle = COLORS[4];
          break;
        default:
          healthStyle = COLORS[3];
          break;
      }
    }
    return healthStyle;
  };

  const handleCardOpen = () => {
    if (cardOpen) {
      setCardOpen(false);
    } else {
      setCardOpen(true);
    }
  };

  const renderSecondHalf = () => {
    return (
      <>
        <section className="row clouds-wrapper">
          <Cloudes />
        </section>
        <section>
          <div className="column">
            {isMobile && <h2 style={{ paddingTop: 10 }}>Budget</h2>}
            <div className="chart-wrapper">
              <BudgetHBarChart
                Planned={
                  project?.c_ApprovedBudget !== undefined &&
                  project.o_ApprovedBudget !== undefined
                    ? project.c_ApprovedBudget + project.o_ApprovedBudget
                    : undefined
                }
                Actual={
                  project?.c_spendToDate !== undefined &&
                  project.o_spendToDate !== undefined
                    ? project.c_spendToDate + project.o_spendToDate
                    : undefined
                }
                Forecast={
                  project?.c_forecastAtCompletion !== undefined &&
                  project.o_forecastAtCompletion !== undefined
                    ? project.c_forecastAtCompletion +
                      project.o_forecastAtCompletion
                    : undefined
                }
              />
            </div>
          </div>
        </section>
        <div className="row">
          <Forest />
        </div>
        <section className="color" style={{ paddingTop: 10 }}>
          <div className="column">
            {isMobile && (
              <h2 style={{ paddingBottom: 20 }}>Risks and Issues</h2>
            )}
            <div className="chart-wrapper">
              <RiskSCPieChart riskCount={getRiskCount()} />
            </div>
          </div>
        </section>
        <div className="row">
          <BigTriangle />
        </div>
        <section>
          <div className="column">
            {isMobile && (
              <h2 style={{ paddingTop: 20 }}>Tasks and Decisions</h2>
            )}
            <div className="chart-wrapper">
              <TaskStackedVBarChart
                decisonCount={getDecisonCount()}
                milestoneCount={getMilestoneCount()}
                changeRequestCount={getChangeRequestCount()}
              />
            </div>
          </div>
        </section>
        <section className="row waves-wrapper">
          <Waves />
        </section>
        <section className="clouds" style={{ paddingBottom: 20 }}>
          <div className="column">
            {isMobile && <h2 style={{ paddingTop: 20 }}>Timeline</h2>}
            <div className="chart-wrapper">
              <TimelineStackedVBarChart timeline={getTimelineRange()} />
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      {project !== undefined && (
        <div className="card-block-wrapper" data-value={index} data-testid="card-builder">
          <div className="container">
            <section
              className=""
              style={{ padding: 14, backgroundColor: getProjectHealth() }}
            >
              <div className="card-title">
                <NavLink
                  to={"/details/" + projectID}
                  className="card-title-link"
                >
                  {projectTitle}
                  <OpenOutsideIcon size="large" style={{ marginLeft: 5 }} />
                </NavLink>
                {isMobile && (
                  <span className="card-expand-wrapper">
                    {cardOpen ? (
                      <CollapseIcon
                        bordered
                        size="large"
                        onClick={handleCardOpen}
                      />
                    ) : (
                      <ExpandIcon
                        bordered
                        size="large"
                        onClick={handleCardOpen}
                      />
                    )}
                  </span>
                )}
              </div>
            </section>
            <section
              className="ss-style-doublediagonal"
              style={{ padding: 20 }}
            >
              <div className="column">
                <div className="card-summary">{project.valueStatement}</div>
              </div>
            </section>

            {isMobile ? (
              <Expand
                open={cardOpen}
                duration={500}
                transitions={["height", "background"]}
              >
                {cardOpen ? renderSecondHalf() : null}
              </Expand>
            ) : (
              renderSecondHalf()
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CardBuilder;
