import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { getContext as GetTeamsContext } from "@microsoft/teams-js";
import {
  TextChangeCase16Regular,
  CalendarLtr16Regular,
  Person16Regular,
  List16Regular,
} from "@fluentui/react-icons";

import {
  Button,
  Datepicker,
  Dropdown,
  Form,
  TextArea,
} from "@fluentui/react-northstar";
import { PeoplePicker } from "@microsoft/mgt-react";
import CurrencyInput from "react-currency-input-field";

import * as MicrosoftGraph from "@microsoft/microsoft-graph-types";
import * as Dataverse from "../../helpers/ProjectService";

import Project from "../../objects/models/Project";
import { crd5f_projectstatusEnum as ProjectStatus } from "../../objects/entities/crd5f_project";
import { PicklistValues } from "../../objects/models/PicklistValues";
import ProjectStatusReportList from "./statusreport/projectStatusReportList";

import "../../CarnaStyles.css";
import ProjectNotesList from "./notes/projectNotesList";
import MilestoneList from "./milestone/projectMilestoneList";
import RiskList from "./risk/projectRiskList";
import BenefitList from "./benefit/projectBenefitList";
import InvoiceList from "./invoice/projectInvoiceList";
import DecisionList from "./decision/projectDecisionList";
import ChangeRequestList from "./changerequest/projectChangeRequestList";
import {
  defaultPicklistKey,
  getPicklistKeys,
  onFocusSelectAll,
  validDate,
} from "./projectUtils";
import { RoleContext } from "../../RoleWrapper";
import { CharactersRemaining } from "./CharactersRemaining";
import { Required } from "./Required";

const ProjectDetails: React.FC<{}> = () => {
  const [project, setProject] = useState<Project>();
  const [isProjectLoaded, setIsProjectLoaded] = useState<boolean>(false);
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
  const [programs, setPrograms] = useState<{ key: string; header: string }[]>();
  const [areProgramsLoaded, setAreProgramsLoaded] = useState<boolean>(false);
  const [areProgramsLoading, setAreProgramsLoading] = useState<boolean>(false);
  const [picklists, setPicklists] = useState<PicklistValues>();
  const [arePicklistsLoaded, setArePicklistsLoaded] = useState<boolean>(false);
  const [arePicklistsLoading, setArePicklistsLoading] =
    useState<boolean>(false);
  const [pmError, setPmError] = useState<string>();
  const [esError, setEsError] = useState<string>();
  const [psError, setPsError] = useState<string>();
  const [cmError, setCmError] = useState<string>();
  const [error, setError] = useState<string>();
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [themeCss, setTheme] = useState<string | undefined>("hl");
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);
  const userRole = useContext(RoleContext);

  const { projectID }: { projectID: string } = useParams();

  // Alternate setTheme values: "mgt-dark" and "mgt-light". They work in browser, but have issues in Teams.
  GetTeamsContext((context) => {
    if (!themeLoaded) {
      setThemeLoaded(true);
      if (context.theme === "dark") {
        setTheme("nv");
      } else {
        setTheme("hl");
      }
    }
  });

  //load the project
  useEffect(() => {
    if (!isProjectLoaded && !isProjectLoading && projectID !== undefined) {
      setIsProjectLoading(true);
      const fetchUsers = async () => {
        if (projectID !== null) {
          const projectObject = await Dataverse.GetProject(projectID);
          setProject(projectObject);
          setIsProjectLoaded(true);
          setIsProjectLoading(false);
        }
      };
      fetchUsers();
    }
  }, [isProjectLoaded, isProjectLoading]);

  //load the picklists
  useEffect(() => {
    if (!arePicklistsLoaded && !arePicklistsLoading) {
      setArePicklistsLoading(true);
      const fetchPicklists = async () => {
        const pickLists = await Dataverse.getPicklistValues("crd5f_project");
        setPicklists(pickLists);
        setArePicklistsLoaded(true);
        setArePicklistsLoading(false);
      };
      fetchPicklists();
    }
  }, [arePicklistsLoaded, arePicklistsLoading]);

  //need to get Project Program, it's an entity we'll be using as a dropdown
  useEffect(() => {
    if (!areProgramsLoaded && !areProgramsLoading) {
      setAreProgramsLoading(true);
      const fetchPrograms = async () => {
        const programs = await Dataverse.GetProgramValues();
        setPrograms(programs);
        setAreProgramsLoaded(true);
        setAreProgramsLoading(false);
      };
      fetchPrograms();
    }
  }, [areProgramsLoaded, areProgramsLoading]);

  //The component event handler type doesn't work
  const onChangeTextArea = (e: any) => {
    const value = e.target.value;
    if (value !== undefined && project) {
      const key = e.target.id;
      setProject({ ...project, [key]: value });
    }
  };

  //event: dropdown list change
  //e is the mouse/keyboard event, ddl is the data we want. Type doesn't work.
  const onChangeDDL = (e: any, ddl: any) => {
    if (ddl !== undefined && project) {
      const key = ddl.id;
      const value = ddl.value.key;
      setProject({ ...project, [key]: value });
    }
  };

  //event: currency field change
  const handleMoneyChange = (id?: string, val?: string) => {
    if (project && id) {
      if (val !== undefined) {
        setProject({ ...project, [id]: val });
      } else {
        setProject({ ...project, [id]: 0 });
      }
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeStartDate = (d: any, e: any) => {
    if (e.value && project) {
      const newValue = e.value as Date;
      setProject({
        ...project,
        startDate: newValue,
      });
    }
  };

  //e has the Date info, type doesn't work.
  const onChangeEndDate = (d: any, e: any) => {
    if (e.value && project) {
      const newValue = e.value as Date;
      setProject({
        ...project,
        endDate: newValue,
      });
    }
  };

  //event: PeoplePicker change
  //type doesn't work
  const handlePersonChange = async (e: any) => {
    //Guard against "undefined" checks for project. The project is loaded.
    if (project) {
      const target = e.target.id;
      //have we selected a new user?
      if (e.detail.length > 0) {
        const newPerson = e.detail[0] as MicrosoftGraph.User;
        const dataverseId = await Dataverse.VerifyPersonInDataverse(
          newPerson.id!
        );
        //The graph user was found in the dataverse. Update the project.
        if (dataverseId) {
          switch (target) {
            case "projectmanager":
              setProject({
                ...project,
                projectManagerSysId: dataverseId,
                projectManagerName: newPerson.displayName as string,
                projectManagerDomainname: undefined,
              });
              setPmError(undefined);
              break;
            case "changemanager":
              setProject({
                ...project,
                changeManagerSysId: dataverseId,
                changeManagerName: newPerson.displayName as string,
                changeManagerDomainname: undefined,
              });
              setCmError(undefined);
              break;
            case "projectsponsor":
              setProject({
                ...project,
                projectSponsorSysId: dataverseId,
                projectSponsorName: newPerson.displayName as string,
                projectSponsorDomainname: undefined,
              });
              setPsError(undefined);
              break;
            case "executivesponsor":
              setProject({
                ...project,
                executiveSponsorSysId: dataverseId,
                executiveSponsorName: newPerson.displayName as string,
                executiveSponsorDomainname: undefined,
              });
              setEsError(undefined);
              break;
            default:
              console.log(
                "Person Change event - NO MATCH for field name. This should never happen."
              );
              break;
          }
        }
        //The graph user isn't in the DV. Blank the systemuser field, display an error to select someone else.
        else {
          const cannedMessage =
            " is an external user. Please select someone else.";
          const personError = newPerson.displayName + cannedMessage;
          switch (target) {
            case "projectmanager":
              setProject({ ...project, projectManagerSysId: undefined });
              setPmError(personError);
              break;
            case "changemanager":
              setProject({ ...project, changeManagerSysId: undefined });
              setCmError(personError);
              break;
            case "projectsponsor":
              setProject({ ...project, projectSponsorSysId: undefined });
              setPsError(personError);
              break;
            case "executivesponsor":
              setProject({ ...project, executiveSponsorSysId: undefined });
              setEsError(personError);
              break;
            default:
              console.log(
                "Person Change event - NO MATCH for field name. This should never happen."
              );
              break;
          }
        }
        setError(undefined);
      } else {
        switch (target) {
          //We removed someone from the managers. Request they choose another.
          case "projectmanager":
            setProject({ ...project, projectManagerSysId: undefined });
            setPmError(
              "The project manager is mandatory. Please reach out to the administrator to ensure this project has a manager. You should not ever see this message."
            );
            break;
          case "changemanager":
            setProject({ ...project, changeManagerSysId: undefined });
            setCmError(undefined);
            break;
          case "projectsponsor":
            setProject({ ...project, projectSponsorSysId: undefined });
            setPsError("Please choose a Project Sponsor.");
            break;
          case "executivesponsor":
            setProject({ ...project, executiveSponsorSysId: undefined });
            setEsError("Please choose an Executive Sponsor.");
            break;
          default:
            console.log(
              "Person Change event - NO MATCH for field name. This should never happen."
            );
            break;
        }
      }
    }
  };

  //final checks
  if (projectID === undefined || projectID === null) {
    return <div style={{ marginTop: 12 }}>project id undefined</div>;
  } else if (isProjectLoading) {
    return <div style={{ marginTop: 12 }}>Loading project...</div>;
  } else if (project === undefined) {
    return <div style={{ marginTop: 12 }}>Project not loaded...</div>;
  } else if (!picklists || !programs) {
    return <div style={{ marginTop: 12 }}>loading choices...</div>;
  } else {
    
    const isClosed = project.projectStatus === ProjectStatus.Closed;
    //event: Save button pressed
    const saveProject = async () => {
      //check to see that the Person fields are filled out. They are mandatory.
      if (!validProject(project)) {
        setShowErrors(true);
        setError("Please fill out all required fields.");
      } else {
        setShowErrors(false);
        setError(undefined);
        await Dataverse.SaveProject(project);
        setIsProjectLoaded(false);
      }
    };

    return (
      <>
        <a id="top" />
        <div className="ms-Grid" data-testid="project-details-grid">
          <h1>
            <div key="projectName">{project.projectName}</div>
          </h1>
          <Form>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <TextChangeCase16Regular /> Acronym{" "}
                  <Required theme={themeCss} />
                </div>
                <TextArea
                  id="acronym"
                  key="acronym"
                  value={project.acronym}
                  onChange={onChangeTextArea}
                  fluid
                  rows={1}
                  disabled={!userRole.canEdit || isClosed}
                  maxLength={100}
                />
                <CharactersRemaining value={project.acronym} maxLength={100} />
                <div className="errorText">
                  {showErrors
                    ? !project.acronym
                      ? "Project Acronym must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <TextChangeCase16Regular /> Project Code{" "}
                  <Required theme={themeCss} />
                </div>
                <TextArea
                  id="projectCode"
                  key="projectCode"
                  value={project.projectCode}
                  onChange={onChangeTextArea}
                  fluid
                  rows={1}
                  disabled={!userRole.canEdit || isClosed}
                  maxLength={100}
                />
                <CharactersRemaining
                  value={project.projectCode}
                  maxLength={100}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.projectCode
                      ? "Project Code must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <List16Regular /> Strategic Alignment{" "}
                  <Required theme={themeCss} />
                </div>
                <Dropdown
                  id="strategicAlignment"
                  key="strategicAlignment"
                  items={getPicklistKeys(picklists, "crd5f_strategicalignment")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_strategicalignment",
                    project.strategicAlignment
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.strategicAlignment
                      ? "Strategic Alignment must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <List16Regular /> Investment Type{" "}
                  <Required theme={themeCss} />
                </div>
                <Dropdown
                  id="investmentType"
                  key="investmentType"
                  items={getPicklistKeys(picklists, "crd5f_investmenttype")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_investmenttype",
                    project.investmentType
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.investmentType
                      ? "Investment Type must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <List16Regular /> Classification <Required theme={themeCss} />
                </div>
                <Dropdown
                  id="classification"
                  key="classification"
                  items={getPicklistKeys(picklists, "crd5f_classification")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_classification",
                    project.classification
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.classification
                      ? "Classification must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <List16Regular /> Project Status <Required theme={themeCss} />
                </div>
                <Dropdown
                  id="projectStatus"
                  key="projectStatus"
                  items={getPicklistKeys(picklists, "crd5f_projectstatus")}
                  defaultValue={defaultPicklistKey(
                    picklists,
                    "crd5f_projectstatus",
                    project.projectStatus
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  disabled={!userRole.portfolioOwner || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.projectStatus
                      ? "Project Status must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <List16Regular /> Program <Required theme={themeCss} />
                </div>
                <Dropdown
                  id="program"
                  key="program"
                  items={programs}
                  defaultValue={programs.find(
                    (match) => match.key === project.program
                  )}
                  placeholder="Select an option..."
                  className="ms-Dropdown-caretDown"
                  onChange={onChangeDDL}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.program
                      ? "Program must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <TextChangeCase16Regular /> Program Number
                </div>
                <TextArea
                  id="programNumber"
                  key="programNumber"
                  value={project.programNumber}
                  onChange={onChangeTextArea}
                  fluid
                  rows={1}
                  disabled={!userRole.canEdit || isClosed}
                  maxLength={100}
                />
                <CharactersRemaining
                  value={project.programNumber}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColFull">
                <div>
                  <h2>
                    Value Statement <Required theme={themeCss} />
                  </h2>
                </div>
                <TextArea
                  id="valueStatement"
                  key="valueStatement"
                  value={project.valueStatement}
                  onChange={onChangeTextArea}
                  fluid
                  rows={5}
                  readOnly={!userRole.canEdit || isClosed}
                  maxLength={4000}
                />
                <CharactersRemaining
                  value={project.valueStatement}
                  maxLength={4000}
                />
                <div className="errorText">
                  {showErrors
                    ? !project.valueStatement
                      ? "Value Statement must have a value."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <Person16Regular /> Project Manager (read-only){" "}
                  <Required theme={themeCss} />
                </div>
                <PeoplePicker
                  id="projectmanager"
                  key="projectmanager"
                  className={themeCss}
                  defaultSelectedUserIds={
                    project.projectManagerAzureId
                      ? [project.projectManagerAzureId]
                      : undefined
                  }
                  selectionMode="single"
                  selectionChanged={handlePersonChange}
                  disabled
                />
                <div className="errorText">{pmError ? pmError : undefined}</div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <Person16Regular /> Change Manager
                </div>
                <PeoplePicker
                  id="changemanager"
                  key="changemanager"
                  className={themeCss}
                  defaultSelectedUserIds={
                    project.changeManagerAzureId
                      ? [project.changeManagerAzureId]
                      : undefined
                  }
                  selectionMode="single"
                  selectionChanged={handlePersonChange}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">{cmError ? cmError : undefined}</div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <Person16Regular /> Project Sponsor{" "}
                  <Required theme={themeCss} />
                </div>
                <PeoplePicker
                  id="projectsponsor"
                  key="projectsponsor"
                  aria-required
                  className={themeCss}
                  defaultSelectedUserIds={
                    project.projectSponsorAzureId
                      ? [project.projectSponsorAzureId]
                      : undefined
                  }
                  selectionMode="single"
                  selectionChanged={handlePersonChange}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {psError
                    ? psError
                    : showErrors && !project.projectSponsorAzureId
                    ? "Please select a person"
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <Person16Regular /> Executive Sponsor{" "}
                  <Required theme={themeCss} />
                </div>
                <PeoplePicker
                  id="executivesponsor"
                  key="executivesponsor"
                  aria-required
                  className={themeCss}
                  defaultSelectedUserIds={
                    project.executiveSponsorAzureId
                      ? [project.executiveSponsorAzureId]
                      : undefined
                  }
                  selectionMode="single"
                  selectionChanged={handlePersonChange}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {esError
                    ? esError
                    : showErrors && !project.executiveSponsorAzureId
                    ? "Please select a person"
                    : undefined}
                </div>
              </div>
            </div>

            <div className="carnaRow">
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <CalendarLtr16Regular /> Start Date{" "}
                  <Required theme={themeCss} />
                </div>
                <Datepicker
                  id="startDate"
                  key="startDate"
                  selectedDate={new Date(project.startDate)}
                  onDateChange={onChangeStartDate}
                  disabled={!userRole.canEdit || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !validDate(project.endDate)
                      ? "Please enter a valid date."
                      : undefined
                    : undefined}
                </div>
              </div>
              <div className="carnaColHalf">
                <div className="fieldLabel">
                  <CalendarLtr16Regular /> End Date{" "}
                  <Required theme={themeCss} />
                </div>
                <Datepicker
                  id="endDate"
                  key="endDate"
                  selectedDate={new Date(project.endDate)}
                  onDateChange={onChangeEndDate}
                  disabled={!userRole.portfolioOwner || isClosed}
                />
                <div className="errorText">
                  {showErrors
                    ? !validDate(project.endDate)
                      ? "Please enter a valid date."
                      : undefined
                    : undefined}
                </div>
              </div>
            </div>

            <div className="carnaRow">
              <div className="carnaColFull">
                <a id="finances" />
                <div>
                  <h2>Finances</h2>
                </div>

                <div className="carnaProjectFinance">
                  <h3>Operational Expenses</h3>
                  <div className="carnaFinancePane">
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Approved Project Budget</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="o_ApprovedBudget"
                          defaultValue={project.o_ApprovedBudget}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("o_ApprovedBudget", val)
                          }
                          value={project.o_ApprovedBudget}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Spent To Date</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="o_spendToDate"
                          defaultValue={project.o_spendToDate}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("o_spendToDate", val)
                          }
                          value={project.o_spendToDate}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Forecast At Completion</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="o_forecastAtCompletion"
                          defaultValue={project.o_forecastAtCompletion}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("o_forecastAtCompletion", val)
                          }
                          value={project.o_forecastAtCompletion}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Final Project Cost</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="o_FinalProjectCost"
                          defaultValue={project.o_FinalProjectCost}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("o_FinalProjectCost", val)
                          }
                          value={project.o_FinalProjectCost}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="carnaProjectFinance">
                  <h3>Capital Expenses</h3>
                  <div className="carnaFinancePane">
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Approved Project Budget</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="c_ApprovedBudget"
                          defaultValue={project.c_ApprovedBudget}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("c_ApprovedBudget", val)
                          }
                          value={project.c_ApprovedBudget}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Spent To Date</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="c_spendToDate"
                          defaultValue={project.c_spendToDate}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("c_spendToDate", val)
                          }
                          value={project.c_spendToDate}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Forecast At Completion</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="c_forecastAtCompletion"
                          defaultValue={project.c_forecastAtCompletion}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("c_forecastAtCompletion", val)
                          }
                          value={project.c_forecastAtCompletion}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="carnaFinanceRow">
                      <div className="carnaFinanceLeft">
                        <div>Final Project Cost</div>
                      </div>
                      <div className="carnaFinanceRight">
                        <CurrencyInput
                          id="c_FinalProjectCost"
                          defaultValue={project.c_FinalProjectCost}
                          allowDecimals={false}
                          prefix="$"
                          className={themeCss + " currency"}
                          onValueChange={(val) =>
                            handleMoneyChange("c_FinalProjectCost", val)
                          }
                          value={project.c_FinalProjectCost}
                          onFocus={onFocusSelectAll}
                          disabled={!userRole.portfolioOwner || isClosed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carnaRow">
              <div className="carnaColFull">
                <div className="danger">{error ? error : undefined}</div>
              </div>
            </div>
            {userRole.canEdit ? (
              <div className="carnaRow">
                <div className="carnaColFull">
                  <Button content="Save Project" onClick={saveProject} />
                </div>
              </div>
            ) : undefined}
          </Form>
          <a id="projectnotes" />
          <div>
            <h1>Project Notes</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <ProjectNotesList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <a id="invoices" />
          <div>
            <h1>Invoices</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <InvoiceList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="benefits" />
          <div>
            <h1>Benefits</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <BenefitList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="milestones" />
          <div>
            <h1>Milestones</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <MilestoneList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="decisions" />
          <div>
            <h1>Decisions</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <DecisionList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="changerequests" />
          <div>
            <h1>Change Requests</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <ChangeRequestList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="statusreports" />
          <div>
            <h1>Status Reports</h1>
          </div>
          <div className="carnaRow">
            <div className="ms-Grid-col ms-md12 block">
              <ProjectStatusReportList projectId={project.projectId} projectClosed={isClosed} />
            </div>
          </div>
          <hr />
          <a id="risks" />
          <div>
            <h1>Project Risks</h1>
          </div>
          <div className="carnaRow">
            <RiskList projectId={project.projectId} projectClosed={isClosed} />
          </div>
        </div>
      </>
    );
  }
};

function validProject(project: Project): boolean {
  var valid = false;
  if (
    [
      validDate(project.startDate),
      validDate(project.endDate),
      project.projectCode,
      project.strategicAlignment,
      project.investmentType,
      project.classification,
      project.projectStatus,
      project.program,
      project.valueStatement,
      project.projectSponsorSysId,
      project.executiveSponsorSysId,
    ].every(Boolean)
  ) {
    valid = true;
  }
  return valid;
}

export default ProjectDetails;
