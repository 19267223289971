import { Providers } from "@microsoft/mgt-element";

export function getGraphUserById(loginid: string) {
  return callGraphGet(process.env.REACT_APP_GRAPH_URL + "users/" + loginid);
}

export function getUserPresenceById(loginid: string) {
  return callGraphGet(
    process.env.REACT_APP_GRAPH_URL + "users/" + loginid + "/presence/"
  );
}

export function getMeFromGraph() {
  return callGraphGet(process.env.REACT_APP_GRAPH_URL + "me");
}

export function getMyGroupsFromGraph() {
  return callGraphGet(process.env.REACT_APP_GRAPH_URL + "me/memberOf");
}

export async function callGraphGet(query: string) {
  const msalInstance = Providers.globalProvider;

  const token = await msalInstance.getAccessToken();
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };
  const endpoint = query;

  return fetch(endpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
/*
//keeping this around just to show how to deal with a blob

export async function getUserPhotoById(loginid:string):Promise<string | undefined>
{
  const endpoint = "https://graph.microsoft.com/v1.0/users/" + loginid + "/photos/48x48/$value";

  const msalInstance = Providers.globalProvider;
  const bearer = `Bearer ${token}`;
  const request = new Request(endpoint, {
    method: "GET",
    headers: new Headers({
        "Authorization": bearer,
        "responseType": 'blob'
    })
  });

  const graphResponse = await fetch(request);
  const blobby = await graphResponse.blob();
  return URL.createObjectURL(blobby);
}
*/
